import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { isObjectExists } from '../../utils/Helpers';
import { getUrlAndLicence } from '../../utils/VieroUtils';
import './Header.css';
import searchIcon from '../../assets/images/SearchIcon@2x.png';
import backButton from '../../assets/images/BackArrow@2x.png';
import mutedIcon from '../../assets/images/MutedIcon.png';
import unmutedIcon from '../../assets/images/UnmutedIcon.png';
import {ModalView} from '../profile/ModalView';
import defaultProfilePicture from '../../assets/images/background.jpeg';

export class Header extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            showSearch:   false,
            nameToSearch: '',
            showModal:    false
        };
    }

    checkIfReturn = (e) => {
        if (e.keyCode == 13) {
            this.props.search(this.state.nameToSearch);
        }
    }

    setModal = () => {
        this.setState({showModal: !this.state.showModal});
    }

    getPublicProfilePicture = () => {
        if (isObjectExists(this.props, 'capsule.publicUser.image')) {
            return getUrlAndLicence(this.props.capsule.publicUser.image, false, '35x35', 'VieroToolkitUtilsTypeStillSingle').url;
        } else {
            return defaultProfilePicture;
        }
    }

    goToPublicPage = () => {
        window.location.href = process.env.REACT_APP_BASE_URI + '/' + this.props.capsule.publicUser.slug;
    }

    renderHeader = () => {
        let header;

        let isUsers = this.props.isUsers ? this.props.isUsers() : false;
        let groupName = this.props.groupName;
        let editFieldSize = this.props.canSignUp ? '2' : '4';
        if (groupName || this.props.isShare) {
            header =
            <React.Fragment>
                <Col className="hover my-auto p-right-0" lg="8" md="8" sm="8" xs="8" onClick={() => this.props.backToGroups(true)}>
                    <img className="back-button" src={backButton} /> <span className="navigation-button">{this.props.isShare ? 'Back To Home' : groupName}</span>
                </Col>
                {this.props.canSignUp &&
                <React.Fragment>
                    <ModalView isOpen={this.state.showModal}
                        toggle={() => this.setModal()}
                        onContinue={() => this.setModal()}
                        title={this.state.titleOfSelect}
                        modalType="signUp"
                    />
                    <Col lg="2" md="2" sm="2" xs="2" className="my-auto">
                        <span className="navigation-button" onClick={() => this.setModal(true)}>Sign Up</span>
                    </Col>
                </React.Fragment>}
                {-1 !== window.location.href.indexOf('form=') && this.props.capsule.ownerId !== this.props.user.id &&
                <Col lg="4" md="4" sm="4" xs="4" className="my-auto">
                    <span className="navigation-button" onClick={() => this.props.setCapsuleToEdit(this.props.capsule)}>Copy</span>
                </Col>

                }

                <Col lg={editFieldSize} md={editFieldSize} sm={editFieldSize} xs={editFieldSize} className="my-auto right">
                    {this.props.isVideoBackground ?
                        this.props.isMuted ?
                            <img src={mutedIcon} className="mute-icon img-fluid" onClick={() => this.props.changeMute()}/>
                            :
                            <img src={unmutedIcon} className="mute-icon img-fluid"  onClick={() => this.props.changeMute()}/>
                        :
                        null
                    }
                    {this.props.shouldShowPublicProfileButton(this.props.capsule) &&
                    
                        <img className="profile-icon hover" onClick={() => this.goToPublicPage()} src={this.getPublicProfilePicture()} />
        
                    }

                    { isUsers === true &&

                    <span className="navigation-button edit-text" onClick={() => this.props.openEditCard()}>Edit</span>
                    }
                </Col>

            </React.Fragment>;
        } else if (this.props.groupSearch) {
            header = (
                this.state.showSearch === false ?
                    <Col lg="2" md="2" sm="2" xs="2" className="left my-auto text-center">
                        <img onClick={() => this.setState({showSearch: !this.state.showSearch})} className="searchIcon" src={searchIcon} />
                    </Col>
                    :
                    <React.Fragment>
                        <Col lg="1" md="1" sm="1" xs="1"  className="my-auto text-center">
                            <img onClick={() => this.setState({showSearch: !this.state.showSearch})} className="searchIcon" src={searchIcon} />
                        </Col>
                        <Col lg="6" md="6" sm="6" xs="6" className="my-auto text-center search-input">
                            <form onSubmit={() => this.props.search(this.state.nameToSearch)} >
                                <input className="capsule-input " value={this.state.nameToSearch} onChange={(e) => this.setState({nameToSearch: e.target.value})}></input>
                            </form>
                        </Col>
                        <Col lg="2" md="2" sm="2" xs="2" className="hover p-0 my-auto text-center" onClick={() => this.props.search(this.state.nameToSearch)}>Search</Col>
                    </React.Fragment>
            );

        } else {
            header = (
                this.state.showSearch === false ?
                    <React.Fragment>
                        <Col lg="4" md="4" sm="4" xs="4" className="text-center my-auto">

                        </Col>
                        {/*<Col lg="4" md="4" sm="4" xs="4" className="text-center my-auto">
                            <img onClick={() => this.setState({showSearch: !this.state.showSearch})} className="searchIcon" src={searchIcon} />
                            <span className="search-text navigation-button" onClick={() => this.setState({showSearch: !this.state.showSearch})}>Search</span>
                        </Col>*/}
                        {/*<Col lg="4" md="4" sm="4" xs="4" className="right my-auto">
                            {this.props.user.token && localStorage.getItem('MUUserLogin') ?
                                <span className="navigation-button" onClick={() => this.props.logout()}>Logout</span>
                                :
                                <span className="navigation-button" onClick={() => this.props.navigateTo('login')}>Login</span>
                            }
                        </Col>*/}

                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Col lg="1" md="1" sm="1" xs="1"  className="text-center my-auto p-0">
                            <img onClick={() => this.setState({showSearch: !this.state.showSearch})} className="searchIcon" src={searchIcon} />
                        </Col>
                        <Col lg="9" md="9" sm="9" xs="9" className="text-center search-input my-auto">
                            <input onKeyDown={(e) => this.checkIfReturn(e)} className="capsule-input search-input-text" value={this.state.nameToSearch} onChange={(e) => this.setState({nameToSearch: e.target.value})}></input>
                        </Col>
                        <Col lg="2" md="2" sm="2" xs="2" className="hover p-0 text-center my-auto navigation-button" onClick={() => this.props.search(this.state.nameToSearch)}>Search</Col>
                    </React.Fragment>
            );
        }
        return header;
    }

    render() {
        return this.renderHeader();
    }
}

Header.propTypes = {
    capsule:                       PropTypes.object,
    navigateTo:                    PropTypes.func,
    setCapsuleToEdit:              PropTypes.func,
    headerToShow:                  PropTypes.string,
    changeView:                    PropTypes.func,
    search:                        PropTypes.func,
    groupName:                     PropTypes.string,
    backToGroups:                  PropTypes.func,
    groupSearch:                   PropTypes.bool,
    openEditCard:                  PropTypes.func,
    isUsers:                       PropTypes.func,
    setIsExploreGroups:            PropTypes.func,
    user:                          PropTypes.object,
    logout:                        PropTypes.func,
    isShare:                       PropTypes.bool,
    changeMute:                    PropTypes.func,
    isMuted:                       PropTypes.bool,
    isVideoBackground:             PropTypes.bool,
    setFullscreen:                 PropTypes.func,
    websiteUrl:                    PropTypes.string,
    isCapsuleFullscreen:           PropTypes.bool,
    canSignUp:                     PropTypes.bool,
    shouldShowPublicProfileButton: PropTypes.func
};
