import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import { Container } from 'reactstrap';
import {CreateCard} from './CreateCardView';
import './Profile.css';
import {ModalView} from './ModalView';
import { userActions, capsuleActions } from '../redux/actions';
import {Home} from '../home/Home';
import { isObjectExists} from '../../utils/Helpers';

class Profile extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            viewToRender:       this.props.viewToRender,
            gotCapsules:        false,
            isPreview:          false,
            capsuleToEdit:      0,
            startingIndex:      0,
            isEdit:             false,
            createdCapsule:     null,
            groupView:          'main',
            isExploreGroup:     false,
            groupName:          '',
            groupIdToAddTo:     '',
            viewedGroup:        '',
            searchTerm:         '',
            isCreating:         true,
            userChanged:        false,
            loggedOut:          false,
            checkingProgressOf: '',
            isUploading:        false,
            typeOfList:         '',
            groupToEdit:        {},
            isSearchView:       false,
            showSearch:         false,
            groupHistory:       [],
            capsules:           [],
            showSyncedModal:    false
        };
        this.mainGroup = 'Contact Media Capsules';
        this.sharedGroup = 'Shared with Me';

    }

    componentDidMount() {

        if (((this.props.user.withKey && !this.props.user.withToken) || !localStorage.getItem('MUUserLogin')) && !this.props.home) {
            this.props.navigateTo('login');
        }

        if (-1 !== window.location.href.indexOf('code=')) {
            let code = window.location.href.split('code=')[1].split('&scope')[0];
            this.props.dispatch(userActions.syncGoogleContacts(code));
        }

        if (!this.state.gotCapsules && this.props.user.withToken === true) {
            if (this.props.user.publicPage === false) {
                this.props.dispatch(userActions.getCapsules());
               
                //this.props.dispatch(capsuleActions.getSharedCapsules(100));
                this.props.dispatch(capsuleActions.getCapsuleGroups());
                this.props.dispatch(userActions.getUserCapsuleCount());
            }

            this.props.dispatch(capsuleActions.getMainCapsule());
           
            this.setState({gotCapsules: true});
        }

        //let bg = document.getElementsByClassName('background')[0];
        //bg.style.display = 'none';
    }

    componentDidUpdate(prevProps, prevState) {

        if (((this.props.user.withKey && !this.props.user.withToken) || !localStorage.getItem('MUUserLogin')) && this.state.loggedOut === false && !this.props.home) {
            //this.setState({loggedOut: true});

            this.props.navigateTo('login');
        }

        if (prevProps.user.syncingContacts !== 'synced' && (this.props.user.syncingContacts === 'synced' || this.props.user.syncingContacts === false) && !this.state.showSyncedModal) {
            this.setState({showSyncedModal: true});
        }

        if (this.props.user.id !== localStorage.getItem('userId') && this.props.user.id && this.state.userChanged !== true) {
            this.setState({userChanged: true});
        }

        if (prevProps.home && !this.props.home) {
            //this.setState({groupView: 'main'});
            this.props.changeView('main');
        }
        
        if ((!this.state.gotCapsules && this.props.user.withToken === true) || (prevProps.viewToRender !== 'main' && this.props.viewToRender === 'main' && this.state.groupView !== 'capsuleList')
        || prevState.groupView !== 'main' && this.state.groupView === 'main') {

            if (this.props.user.publicPage === false) {
                this.props.dispatch(capsuleActions.clearCapsules());
                //this.props.dispatch(userActions.getCapsules());
                //this.props.dispatch(capsuleActions.getSharedCapsules(100));
                //this.props.dispatch(capsuleActions.getPublicCapsules());
                this.props.dispatch(capsuleActions.getCapsuleGroups());
                this.props.dispatch(userActions.getUserCapsuleCount());
                this.setState({gotCapsules: true});
            }
        }

        if (prevProps.user.createdCapsule !== this.props.user.createdCapsule && typeof(this.props.user.createdCapsule) !== 'undefined') {
            if (this.state.viewedGroup === '') {
                this.props.dispatch(userActions.getCapsules(true));
            }
            this.props.changeView('editCard');
            this.setState({isEdit: true, capsuleToEdit: this.props.user.createdCapsule});
        }

        if (prevProps.capsules.capsuleAddedToGroup === false && this.props.capsules.capsuleAddedToGroup === true) {
            if (this.state.groupIdToAddTo && this.state.groupIdToAddTo !== '') {
                this.props.dispatch(capsuleActions.getCapsulesByGroup(this.state.groupIdToAddTo));
            }
        }

        if (prevProps.capsules.refreshing === true && this.props.capsules.refreshing === false) {
            this.props.changeView('editCard');
            
            this.setState({
                isEdit:        true,
                capsuleToEdit: this.props.capsules.refreshedCapsule,
                isCreating:    false
            });
        }

        if (prevProps.user.userCapsules !== this.props.user.userCapsules && this.props.user.userCapsules && this.props.user.userCapsules.length !== 0 && this.state.isExploreGroup === false) {
            this.props.dispatch(capsuleActions.clearCapsules());
            this.props.dispatch(capsuleActions.userCapsulesToCapsuleList(this.props.user.userCapsules));

            if (this.props.user.capsuleDeleted === true) {
                this.props.dispatch(userActions.setDeleted(false));
                let listToSearch = this.state.searchTerm !== '' ? this.props.capsules.searchResults : this.props.capsules.capsule;
                if (listToSearch.length === 1 || listToSearch === 0) {
                    this.props.changeView('main');
                    this.changeGroupView('main');
                    this.changeGroupName('');
                    this.setViewedGroup('');
                    this.changeSearchTerm('');
                    this.changeTypeOfList('');
                } else {
                    this.changeStartingIndex(this.determineIndexOfCapsuleAfterDelete(listToSearch.length));
                    this.props.changeView('main');
                    this.changeGroupView('capsuleList');

                }

            } else {
                let listToSearch = this.state.searchTerm && this.state.searchTerm !== '' ? this.props.capsules.searchResults : this.props.user.userCapsules;
                if (this.state.viewedGroup !== '') {
                    listToSearch = this.getCapsulesOfGroups(this.state.viewedGroup);
                } 
                this.changeStartingIndex(this.getIndexOfNewCapsule(this.state.capsuleToEdit.id, listToSearch));
            }
        }

        if (prevProps.capsules.capsulesOfGroup !== this.props.capsules.capsulesOfGroup && this.props.capsules.capsulesOfGroup && this.props.capsules.capsulesOfGroup.length !== 0 && !this.state.isExploreGroup) {
            //this.props.dispatch(capsuleActions.clearCapsules());
            //this.props.dispatch(capsuleActions.userCapsulesToCapsuleList(this.props.capsules.capsulesOfGroup));
            if (this.props.user.capsuleDeleted === true) {
                this.props.dispatch(userActions.setDeleted(false));
                this.changeStartingIndex(this.determineIndexOfCapsuleAfterDelete(this.props.capsules.capsulesOfGroup.length));
                this.props.changeView('main');
                this.changeGroupView('capsuleList');
            }
            if (this.props.user.updatedCapsule || this.props.capsules.capsuleAddedToGroup) {
                let listToSearch = this.state.searchTerm && this.state.searchTerm !== '' ? this.props.capsules.searchResults : this.props.capsules.capsule;
                this.setCapsulesToShow(listToSearch);
                this.changeStartingIndex(this.getIndexOfNewCapsule(this.state.capsuleToEdit.id, listToSearch));

            }

        }

        if (prevProps.user.capsuleUpdated === false && this.props.user.capsuleUpdated === true) {
            
            if (this.state.searchTerm !== '') {
                this.props.dispatch(capsuleActions.refreshCapsuleById(this.props.user.updatedCapsule.id));
            } else {
                if (this.state.groupName === this.mainGroup) {
                    this.props.dispatch(capsuleActions.getUserCapsules(this.props.capsules.capsule.length));
                } else if (this.state.groupName === this.sharedGroup) {
                    this.props.dispatch(capsuleActions.getSharedCapsules(this.props.capsules.capsule.length));
                } else if (this.state.isExploreGroup) {
                    if (this.state.groupName === 'Favorites') {
                        this.props.dispatch(capsuleActions.getFavourites());
                        return;
                    }
                    let viewedGroup = this.state.viewedGroup === '' ? 'all' : this.state.viewedGroup;
                    this.props.dispatch(capsuleActions.getCapsulesOfExploreGroup(viewedGroup));
                } else {
                    this.props.dispatch(capsuleActions.getCapsulesByGroup(this.state.viewedGroup));
                }
            }
        }

        if (prevProps.user.capsuleDeleted === false && this.props.user.capsuleDeleted === true && this.state.groupName !== this.mainGroup) {

            let group = this.state.viewedGroup !== '' ? this.state.viewedGroup : 'all';
            if (this.state.isExploreGroup) {
                this.props.dispatch(capsuleActions.getCapsulesOfExploreGroup(group));
            } else {
                this.props.dispatch(capsuleActions.getCapsulesByGroup(this.state.viewedGroup));
            }

            this.props.changeView('main');
            this.changeGroupView('capsuleList');
        }

        if (prevProps.user.capsuleDeleted === false && this.props.user.capsuleDeleted === true) {
            this.props.dispatch(userActions.getUserCapsuleCount());
            if (this.props.user.userCapsules.length === 0) {
                this.props.changeView('main');
                this.changeGroupView('main');
                this.changeGroupName('');
                this.setViewedGroup('');
                this.changeSearchTerm('');
                this.changeTypeOfList('');
            }
        }

        if ((this.state.searchTerm === '') && this.state.isSearchView === true) {
            this.setState({isSearchView: false});
        }

        if ((this.state.searchTerm !== '') && (this.state.groupView === 'main' || this.state.groupView === 'groupCapsuleList' || this.state.groupView === 'userCapsuleList' || this.state.groupView === 'sharedCapsuleList') && this.state.isSearchView === false) {
            this.setState({isSearchView: true});
        }

    }

    componentWillUnmount() {
        let bg = document.getElementsByClassName('background')[0];
        bg.style.display = '';
    }

    getCapsulesOfGroups = (id) => {
        let capsulesOfGroup = this.props.user.userGroupCapsules;

        for (let i in capsulesOfGroup) {
            if (capsulesOfGroup[i].id === id) {
                return capsulesOfGroup[i].content;
            }
        }
    }

    setCheckingProgressOf = (media) => {
        this.setState({checkingProgressOf: media});
    }

    setCapsulesToShow = (capsules) => {
        this.setState({capsules});
    }

    setFromHome = (fromHome) => {
        this.setState({fromHome});
    }

    determineIndexOfCapsuleAfterDelete = (length) => {

        let index = this.state.startingIndex;
        if (index == length-1) {
            index -= 1;
        }

        return index;
    }

    changeShowSearch = (value) => {
        this.setState({showSearch: value});
    }

    changeTypeOfList = (value) => {
        this.setState({typeOfList: value});
    }

    changeSearchTerm = (value) => {
        this.setState({searchTerm: value});
    }

    changeStartingIndex = (i) => {
        this.setState({startingIndex: i});
    }

    changeView = (value) => {
        this.setState({viewToRender: value});
    }

    onDataChange = () => {
        this.props.dispatch(userActions.getCapsules());
    }

    changeIsCreating = (value) => {
        this.setState({isCreating: value});
    }

    changeGroupView = (view) => {
        this.setState({groupView: view});
    }

    changeGroupName = (name) => {
        this.setState({groupName: name});
    }

    deleteCapsule = (id) => {
        this.props.dispatch(userActions.deleteCapsule(id));
    }

    changeIsSearchView = (value) => {
        this.setState({isSearchView: value});
    }

    setGroupIdToAddTo = (id) => {
        this.setState({groupIdToAddTo: id});
    }

    setViewedGroup = (id) => {
        this.setState({viewedGroup: id});
    }

    setGroupToEdit = (group) => {
        this.setState({groupToEdit: group});
    }

    addToGroupHistory = (group) => {
        let groupHistory = this.state.groupHistory;

        groupHistory.push(group);

        this.setState({groupHistory});
    }

    getLatestFromGroupHistory = () => {
        let groupHistory = this.state.groupHistory;
        let latest = groupHistory.pop();

        this.setState({groupHistory});
        return latest;
    }

    resetGroupHistory = () => {
        this.setState({groupHistory: []});
    }

    getIndexOfNewCapsule = (id, capsuleList) => {
        let index = this.state.startingIndex;
        let capsulesToSearch;

        if (capsuleList) {
            capsulesToSearch = capsuleList;
        } else {
            capsulesToSearch = this.props.capsules.capsule;
        }
        for (let i in capsulesToSearch) {
            if (capsulesToSearch[i].id === id) {
                index = i;
                break;
            }
        }

        if (capsulesToSearch[index]) {
            return parseInt(index);
        }

        return capsulesToSearch.length - 1;
    }

    setIsExploreGroup = (value) => {
        this.setState({isExploreGroup: value});
    }

    checkIfUploading = () => {
        let statuses = this.props.user.fireBaseStatus;
        let isUploading = false;

        for (let i in statuses) {
            if (statuses[i] && (statuses[i] === undefined || statuses[i] === 'uploading')) {
                isUploading = true;
                break;
            }
        }

        if (isUploading !== this.state.isUploading) this.setState({isUploading});

    }

    getStatusOfMedia = (id) => {
        let result = 'pending';
        this.checkIfUploading();
        if (isObjectExists(this.props, 'user.fireBaseStatus.' + id) &&
             ((this.props.user.fireBaseStatus[id] !== 'error' && this.props.user.fireBaseStatus[id] !== 'ready')
             || this.props.user.fireBaseStatus[id] === undefined)
        ) {
            result = false;
        } else if (isObjectExists(this.props, 'user.fireBaseStatus.' + id) &&
             (this.props.user.fireBaseStatus[id] === 'error' || this.props.user.fireBaseStatus[id] === 'ready')) {
            result = true;
            this.props.listenStatus(id, true);
        }

        return result;
    }

    renderView = (view) => {
        switch (view) {
            case 'main':
                return <Home changeView={this.props.changeView}
                    userCapsules={this.props.user.userCapsules}
                    viewToRender={this.props.viewToRender}
                    openEditCard={this.openEditCard}
                    user={this.props.user}
                    logout={this.props.logout}
                    navigateTo={this.props.navigateTo}
                    groupView={this.state.groupView}
                    changeGroupView={this.changeGroupView}
                    groupName={this.state.groupName}
                    changeGroupName={this.changeGroupName}
                    deleteCapsule={this.deleteCapsule}
                    changeStartingIndex={this.changeStartingIndex}
                    startingIndex={parseInt(this.state.startingIndex)}
                    getIndexOfNewCapsule={this.getIndexOfNewCapsule}
                    setGroupIdToAddTo={this.setGroupIdToAddTo}
                    setViewedGroup={this.setViewedGroup}
                    viewedGroup={this.state.viewedGroup}
                    changeIsCreating={this.changeIsCreating}
                    isExploreGroup={this.state.isExploreGroup}
                    setIsExploreGroup={this.setIsExploreGroup}
                    setIsExploreGroupView={this.props.setIsExploreGroupView}
                    isExploreGroupView={this.props.isExploreGroupView}
                    onAddGroup={this.props.onAddGroup}
                    onRemoveGroup={this.props.onRemoveGroup}
                    setGroupsToAdd={this.props.setGroupsToAdd}
                    groupsToAdd={this.props.groupsToAdd}
                    home={this.props.home}
                    getThumbnailPicture={this.props.getThumbnailPicture}
                    getImageFromImageAndVideos={this.props.getImageFromImageAndVideos}
                    getStatusOfMedia={this.getStatusOfMedia}
                    setCheckingProgressOf={this.setCheckingProgressOf}
                    checkingProgressOf={this.state.checkingProgressOf}
                    listenStatus={this.props.listenStatus}
                    getImageScalingParameter={this.props.getImageScalingParameter}
                    changeTypeOfList={this.changeTypeOfList}
                    typeOfList={this.state.typeOfList}
                    setGroupToEdit={this.setGroupToEdit}
                    groupToEdit={this.state.groupToEdit}
                    searchTerm={this.state.searchTerm}
                    changeSearchTerm={this.changeSearchTerm}
                    isSearchView={this.state.isSearchView}
                    changeIsSearchView={this.changeIsSearchView}
                    showSearch={this.state.showSearch}
                    changeShowSearch={this.changeShowSearch}
                    groupHistory={this.state.groupHistory}
                    addToGroupHistory={this.addToGroupHistory}
                    getLatestFromGroupHistory={this.getLatestFromGroupHistory}
                    resetGroupHistory={this.resetGroupHistory}
                    setCapsuleToEdit={this.props.setCapsuleToEdit}
                    capsuleList={this.state.capsules}
                    setCapsulesToShow={this.setCapsulesToShow}
                    fromHome={this.state.fromHome}
                    setFromHome={this.setFromHome}

                />;
            case 'createCard':
                return <CreateCard
                    changeView={this.props.changeView}
                    groupView={this.groupView}
                    changeGroupView={this.changeGroupView}
                    onPreviewChange={this.onPreviewChange}
                    dispatch={this.props.dispatch}
                    user={this.props.user}
                    capsuleToEdit={this.props.capsuleToCopy ? this.props.capsuleToCopy : null}
                    onDataChange={this.onDataChange}
                    changeStartingIndex={this.changeStartingIndex}
                    startingIndex={parseInt(this.state.startingIndex)}
                    getIndexOfNewCapsule={this.getIndexOfNewCapsule}
                    groupId={this.state.groupIdToAddTo}
                    setGroupIdToAddTo={this.setGroupIdToAddTo}
                    changeFooterTo={this.props.changeFooterTo}
                    deleteCapsule={this.deleteCapsule}
                    capsules={this.props.capsules}
                    changeIsCreating={this.changeIsCreating}
                    isCreating={this.state.isCreating}
                    getThumbnailPicture={this.props.getThumbnailPicture}
                    getImageFromImageAndVideos={this.props.getImageFromImageAndVideos}
                    getStatusOfMedia={this.getStatusOfMedia}
                    setCheckingProgressOf={this.setCheckingProgressOf}
                    checkingProgressOf={this.state.checkingProgressOf}
                    listenStatus={this.props.listenStatus}
                    isUploading={this.state.isUploading}
                    isExploreGroup={this.state.isExploreGroup}
                    getImageScalingParameter={this.props.getImageScalingParameter}
                    changeTypeOfList={this.changeTypeOfList}
                    typeOfList={this.state.typeOfList}
                    changeGroupName={this.changeGroupName}
                    searchTerm={this.state.searchTerm}
                    capsuleList={this.state.capsules}
                    setCapsulesToShow={this.setCapsulesToShow}
                />;
            case 'editCard':
                return <CreateCard
                    changeView={this.props.changeView}
                    onPreviewChange={this.onPreviewChange}
                    changeGroupView={this.changeGroupView}
                    dispatch={this.props.dispatch}
                    user={this.props.user}
                    capsuleToEdit={typeof(this.state.capsuleToEdit) === 'number' ? ((this.props.user.userCapsules) ? this.props.user.userCapsules[this.state.capsuleToEdit] : this.props.capsules.capsule[0]) : this.state.capsuleToEdit}
                    isEdit={this.state.isEdit}
                    onDataChange={this.onDataChange}
                    groupId={this.state.groupIdToAddTo}
                    getIndexOfNewCapsule={this.getIndexOfNewCapsule}
                    changeStartingIndex={this.changeStartingIndex}
                    changeFooterTo={this.props.changeFooterTo}
                    deleteCapsule={this.deleteCapsule}
                    capsules={this.props.capsules}
                    changeIsCreating={this.changeIsCreating}
                    isCreating={this.state.isCreating}
                    getThumbnailPicture={this.props.getThumbnailPicture}
                    getImageFromImageAndVideos={this.props.getImageFromImageAndVideos}
                    getStatusOfMedia={this.getStatusOfMedia}
                    setCheckingProgressOf={this.setCheckingProgressOf}
                    checkingProgressOf={this.state.checkingProgressOf}
                    listenStatus={this.props.listenStatus}
                    isUploading={this.state.isUploading}
                    isExploreGroup={this.state.isExploreGroup}
                    getImageScalingParameter={this.props.getImageScalingParameter}
                    changeTypeOfList={this.changeTypeOfList}
                    typeOfList={this.state.typeOfList}
                    changeGroupName={this.changeGroupName}
                    searchTerm={this.state.searchTerm}
                    capsuleList={this.state.capsules}
                    setCapsulesToShow={this.setCapsulesToShow}
                />;
        }
    }

    onPreviewChange = (value) => {
        this.setState({isPreview: value});
    }

    openEditCard = (i) => {
        this.props.dispatch(capsuleActions.refreshCapsuleById(i.id));
    }

    getMessage = () => {

        if (this.props.user.syncingContacts === 'synced' ) {
            return 'Your Google Contacts has been successfully synced';
        } else if (isObjectExists(this.props, 'user.error.messages.error.message')) {
            return this.props.user.error.messages.error.message;
        } else {
            return '';
        } 
    }

    render() {

        return (
            <React.Fragment>
                <div className={this.props.viewToRender !== 'main' ? 'container' : ''}>
                    {this.renderView(this.props.viewToRender)}
                </div>
                <ModalView isOpen={this.state.userChanged}
                    toggle={() => this.openModal()}
                    title="Account changed"
                    modalType="account"
                />
                <ModalView isOpen={this.state.loggedOut}
                    toggle={() => this.setState({loggedOut: !this.state.loggedOut})}
                    onContinue={() =>this.props.navigateTo('login')}
                    title="User logged out"
                    modalType="loggedOut"
                />
                <ModalView isOpen={this.state.showSyncedModal}
                    toggle={() => {this.props.navigateTo('explore'); window.location.reload();}}
                    onContinue={() =>{this.props.navigateTo('explore'); window.location.reload();}}
                    title={this.getMessage()}
                    modalType="syncModal"
                />
                
            </React.Fragment>
        );
    }

}

Profile.propTypes = {
    dispatch:                   PropTypes.func,
    capsule:                    PropTypes.string,
    capsules:                   PropTypes.object,
    user:                       PropTypes.object,
    match:                      PropTypes.object,
    history:                    PropTypes.object,
    logout:                     PropTypes.func,
    changeFooterTo:             PropTypes.func,
    navigateTo:                 PropTypes.func,
    home:                       PropTypes.bool,
    viewToRender:               PropTypes.string,
    changeView:                 PropTypes.func,
    setIsExploreGroupView:      PropTypes.func,
    groupsToAdd:                PropTypes.array,
    onRemoveGroup:              PropTypes.func,
    onAddGroup:                 PropTypes.func,
    isExploreGroupView:         PropTypes.bool,
    setGroupsToAdd:             PropTypes.func,
    getThumbnailPicture:        PropTypes.func,
    getImageFromImageAndVideos: PropTypes.func,
    listenStatus:               PropTypes.func,
    getImageScalingParameter:   PropTypes.func,
    capsuleToCopy:              PropTypes.object,
    setCapsuleToEdit:           PropTypes.func
};

function mapStateToProps(state) {
    const { capsules, user } = state;
    return {
        capsules,
        user
    };
}

const connectedProfilePage = connect(mapStateToProps)(Profile);
export { connectedProfilePage as Profile };
