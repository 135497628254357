import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Container, Col } from 'reactstrap';

export class ConnectView extends Component {

    render() {
        return (
            <Container>
                <Row className="group-element">
                    <Col className="navigation-button" onClick={this.props.isEdit === false ? null : () => this.props.onMenuChange('shareView')}>Share</Col>
                </Row>
                <Row className="group-element">
                    <Col className="navigation-button" onClick={this.props.isEdit === false ? null : () => this.props.onMenuChange('shareBackView')}>Share Back</Col>
                </Row>
                {this.props.hasChat &&
                <Row className="group-element">
                    <Col className="navigation-button" onClick={() => this.props.onMenuChange('chatbotView')}>Chat</Col>
                </Row>
                }
            </Container>
        );
            
    }

}

ConnectView.propTypes = {
    isEdit:       PropTypes.func,
    onMenuChange: PropTypes.func,
    hasChat:      PropTypes.bool
};