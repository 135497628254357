import React from 'react';
import MiniCapsule from './MiniCapsule';
import SocialCarousel from './SocialCarousel';
import NavigationCarousel from './NavigationCarousel';
//import ExploreGroups from './ExploreGroups';
import { Container, Row, Col } from 'reactstrap';
import { capsuleActions } from '../redux/actions';
import { userActions } from '../redux/actions';
//import backGround from '../../assets/images/defaultbackground.png';
import { assemblePayload } from '../redux/helpers/payloadAssembler';
import PropTypes from 'prop-types';
import './Explore.css';
import { connect } from 'react-redux';
import {Header} from '../header/Header';
//import { ShareView } from '../capsuleView/ShareView';
//import divider from '../../assets/images/navigation/defaultNavigation/HorizontalDivider@3x.png';
import socialActive from '../../assets/images/navigation/defaultNavigation/SocialIconOutline@3x.png';
import cardActive from '../../assets/images/CenterCardOutline@3x.png';
//import { Helmet } from 'react-helmet';
import { CapsuleContainer } from './CapsuleContainer';
import { isObjectExists, getUserTokenFromStorage } from '../../utils/Helpers';
import ExploreGroups from './FilterGroups';
import { getUrlAndLicence} from '../../utils/VieroUtils';
import mutedIcon from '../../assets/images/MutedIcon.png';
import unmutedIcon from '../../assets/images/UnmutedIcon.png';
import phoneNumberIcon from '../../assets/images/PhoneNumberIcon@3x.png';
import emailIcon from '../../assets/images/EmailIcon@3x.png';
import {ModalView} from '../profile/ModalView';
import backButton from '../../assets/images/Close@2x.png';

class Explore extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            socialSlideIndex:     0,
            navigationSlideIndex: this.props.startingIndex ? this.props.startingIndex : 0,
            navigationSlideCount: 2.25,
            categorySlideIndex:   0,
            socialToShow:         'main',
            gotGroups:            false,
            viewToShow:           '',
            socialOptions:        '',
            showUUID:             false,
            showSearch:           false,
            searchInitiated:      false,
            nameToSearch:         '',
            isVideoBackground:    false,
            firstImportInitiated: false,
            expandedState:        0,
            backgroundLoaded:     false,
            intervalId:           '',
            gotCapsules:          false,
            getMoreCapsules:      true,
            tokenConsuming:       false,
            isExploreGroups:      false,
            isFullscreen:         false,
            isMuted:              this.props.isMuted == undefined ? true : this.props.isMuted,
            iosExited:            false,
            listOfLinks:          [],
            fullscreenCapsule:    false,
            showEditor:           false,
            showNav:              false,
            groupShared:          false
        };

        this.shareErrorYourself = 'You cannot share a capsule with the same account it was created in.';
        this.shareErrorExpired = 'This share link is expired. You can view this Capsule in your account if you previously added it to Shared With Me unless the Capsule was deleted by the owner.';
        this.shareErrorDeleted = 'This Capsule has been removed by the owner';
        this.shareErrorInvalid = 'This Capsule has changed ownership, no longer a valid request';
        this.shareErrorPrivate = 'Capsule is currently private but when the owner turns this capsule public it will be viewable. This share was sent to you by someone other than the owner.';
    }

    componentDidMount() {
        //document.documentElement.style.overflow = 'hidden';

        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

        if (this.props.groupShare) {
            let shareToken = window.location.href.split('share_token=')[1];

            this.props.dispatch(capsuleActions.consumeShareToken(shareToken));
        }

        if (this.props.isPreview) {
            this.setState({gotCapsules: true});
        } else if (!this.props.isFromGroups && this.props.isShare && !this.props.groupShare && !this.props.isPreview && (this.props.user.withKey === true || this.props.user.withToken === true) && this.state.viewToShow !== 'shareView') {

            let inputParams = decodeURI(this.props.history.location.pathname).split('/');
            let capsuleId = inputParams[2] || this.props.match.params.capsuleId;

            if (capsuleId === 'x') {
                capsuleId = this.props.match.params.capsuleId;
                this.props.dispatch(capsuleActions.getExternalCapsule('ElasticSearch', capsuleId));
                this.setState({gotCapsules: true, getMoreCapsules: false, isShare: true});

            } else if (-1 !== window.location.href.indexOf('share_token') && (this.props.user.withKey === true || this.props.user.withToken === true)) {
                let token = window.location.href.split('=')[1].split('&')[0];
                this.props.dispatch(capsuleActions.consumeShareToken(token));
                this.setState({tokenConsuming: true});

            } else if (this.props.capsules.shareTokenConsumed && typeof(capsuleId) != 'undefined') {

                this.props.dispatch(capsuleActions.getCapsuleById(capsuleId));
                window.history.pushState('', '', window.location.href.split('?share_token')[0] + '?' + window.location.href.split('&')[1]);

                if (!this.props.user.userCapsules) {
                    this.props.dispatch(userActions.getCapsules(false, true));
                }
                this.setState({gotCapsules: true, getMoreCapsules: false, tokenConsuming: false});

            } else if (typeof(capsuleId) != 'undefined' && !this.state.tokenConsuming) {
                this.props.dispatch(capsuleActions.getCapsuleById(capsuleId));
                this.setState({gotCapsules: true, getMoreCapsules: false, isShare: true});

            } else if ((localStorage.getItem('MUUser') && this.props.groupsToAdd.length === 0) || localStorage.getItem('MUUserLogin')) {
                this.props.dispatch(capsuleActions.getPublicCapsules(20, 0));
                this.setState({gotCapsules: true, capsuleCount: this.state.capsuleCount + 20});
            }
        }

        let token;

        if (localStorage.getItem('MUUser')) {
            token = localStorage.getItem('MUUser');
        } else if (localStorage.getItem('MUUserLogin')) {
            token = localStorage.getItem('MUUserLogin');
        }

        // Force refresh token
        if (this.props.user.withKey === true || this.props.user.withToken === true) {
            let intervalId = window.setInterval(() => {

                if (localStorage.getItem('MUUser')) {
                    token = localStorage.getItem('MUUser');
                } else if (localStorage.getItem('MUUserLogin')) {
                    token = localStorage.getItem('MUUserLogin');
                }

                //let tokenCreationTime = parseInt(tokenObject.created * 1000);
                //let now = new Date();
                //let difference = parseInt((now - tokenCreationTime) / 1000);

                if (localStorage.getItem('MUUserLogin') && this.props.user.withToken && !this.props.user.publicPage) {
                    this.props.dispatch(userActions.getMe(JSON.parse(localStorage.getItem('MUUserLogin')).token));
                } else {
                    this.props.dispatch(userActions.loginWithKey(JSON.parse(localStorage.getItem('MUUser')).uuid, JSON.parse(localStorage.getItem('MUUser')).loginKey));
                }

            }, (parseInt(JSON.parse(token).tokenObject.idleTimeout)* 1000));

            this.setState({intervalId});
        }
    }

    componentWillUnmount() {
        document.documentElement.style.overflow = 'auto';
        if (this.props.setIsExploreGroupView && this.props.isExploreGroup === true) {
            this.props.setIsExploreGroupView();
        }
        clearInterval(this.state.intervalId);
    }

    componentDidUpdate(prevProps, prevState) {

        //let myVideo = document.getElementsByTagName('video')[0];
        /*if (myVideo && myVideo.muted !== this.state.isMuted) {
            this.setState({isMuted: myVideo.muted});
        }

        if (myVideo && !this.state.listenerSet) {
            myVideo.addEventListener('webkitendfullscreen', () => {
                this.setState({isFullscreen: false, isMuted: true, iosExited: true, listenerSet: true});
                if (myVideo) {
                    myVideo.paused && myVideo.play();
                }
            });
        }*/

        /*if (this.state.viewToShow === '' && myVideo && myVideo.paused) {

            let checkForPlayable = setInterval((myVideo) => {
                if (myVideo && myVideo.readyState == '4') {
                    myVideo.play();
                    clearInterval(checkForPlayable);
                }
            }, 1000, myVideo);

        }*/

        if (!this.state.gotCapsules && this.props.isShare && !this.props.groupShare && (this.props.user.withKey === true || this.props.user.withToken === true) && this.state.viewToShow !== 'shareView' && this.state.getMoreCapsules) {

            let inputParams = this.props.history.location.pathname.split('/');
            let capsuleId = inputParams[2] || this.props.match.params.capsuleId;

            if (capsuleId === 'x') {
                capsuleId = this.props.match.params.capsuleId;
                this.props.dispatch(capsuleActions.getExternalCapsule('ElasticSearch', capsuleId));
                this.setState({gotCapsules: true, getMoreCapsules: false, isShare: true});

            } else if (-1 !== window.location.href.indexOf('share_token') && (this.props.user.withKey === true || this.props.user.withToken === true) &&
            !this.props.capsules.shareTokenConsumed && !this.state.tokenConsuming && !this.props.isFromGroups && !this.props.isPreview && this.state.viewToShow !== 'shareView' && this.state.getMoreCapsules) {

                let token = window.location.href.split('=')[1];
                this.props.dispatch(capsuleActions.consumeShareToken(token));
                this.setState({tokenConsuming: true});

            } else if ((this.props.capsules.shareTokenConsumed && typeof(capsuleId) !== 'undefined') || (typeof(capsuleId) !== 'undefined' && !this.state.tokenConsuming && 0 > window.location.href.indexOf('share_token'))) {

                this.props.dispatch(capsuleActions.getCapsuleById(capsuleId));
                window.history.pushState('', '', window.location.href.split('?share_token')[0] + '?' + window.location.href.split('&')[1]);
                if (!this.props.user.userCapsules) {
                    this.props.dispatch(userActions.getCapsules(false, true));
                }
                this.setState({gotCapsules: true, getMoreCapsules: false, isShare: true});

            } else if (-1 !== window.location.href.toLowerCase().indexOf('/capsules') && -1 === window.location.href.toLowerCase().indexOf('/share_token') && typeof(capsuleId) !== 'undefined' && !this.state.tokenConsuming) {
                this.props.dispatch(capsuleActions.getCapsuleById(capsuleId));
                this.setState({gotCapsules: true, getMoreCapsules: false, isShare: true});

            } else if (typeof(capsuleId) == 'undefined' && (localStorage.getItem('MUUser') && this.props.groupsToAdd.length === 0 && !this.props.isExploreGroup)) {
                this.props.dispatch(capsuleActions.getPublicCapsules(20, 0));
                this.setState({gotCapsules: true, capsuleCount: this.state.capsuleCount + 20});
            }
        }

        if (this.props.groupShare && !prevProps.capsules.shareTokenConsumed && this.props.capsules.shareTokenConsumed) {

            //let groupId = window.location.href.split('group=')[1].split('&')[0];

            //this.props.dispatch(capsuleActions.getCapsulesByGroup(groupId));
            this.setState({groupShared: true});
        }

        if (this.props.searchTerm !== '') {
            if ((this.props.capsules && this.props.capsules.searchResults && 0 < this.props.capsules.searchResults.length && this.props.capsules.loaded && !this.props.isShare
                && !this.props.isPreview && this.state.navigationSlideIndex > this.props.capsules.searchResults.length - 5 && this.state.getMoreCapsules &&
                  !this.props.isFromGroups && !this.state.searchInitiated )) {
                let groupId = this.props.viewedGroup !== '' ? this.props.viewedGroup : 'all';

                switch (this.props.typeOfList) {
                    case 'user':
                        this.props.dispatch(capsuleActions.searchCapsulesByName(this.props.searchTerm, false, 100, this.props.offsetValue+100, 'own', true));
                        break;
                    case 'shared':
                        this.props.dispatch(capsuleActions.searchCapsulesByName(this.props.searchTerm, false, 100, this.props.offsetValue+100, 'shared', true));
                        break;
                    case 'userGroup':
                        break;
                    case 'topLevelSearch':
                        this.props.dispatch(capsuleActions.searchCapsulesByName(this.props.searchTerm, false, 100, this.props.offsetValue+100, 'top', true));
                        break;
                    default:
                        this.props.dispatch(capsuleActions.searchCapsulesByName(this.props.searchTerm, groupId, 100, this.props.offsetValue+100, null, true));
                        break;
                }

                this.props.increaseOffset();
                this.setState({getMoreCapsules: false, capsulesReady: true});
            }
        } else {
            if ((this.props.capsules && this.props.capsules.capsule && 0 < this.props.capsules.capsule.length && this.props.capsules.loaded && !this.props.isShare
                && !this.props.isPreview && this.state.navigationSlideIndex > this.props.capsules.capsule.length - 5 && this.state.getMoreCapsules &&
                  !this.props.isFromGroups && !this.state.searchInitiated )) {
                let groupId = this.props.viewedGroup !== '' ? this.props.viewedGroup : 'all';

                switch (this.props.typeOfList) {
                    case 'user':
                        this.props.dispatch(capsuleActions.getUserCapsules(100, this.props.offsetValue+100, true));
                        break;
                    case 'shared':
                        this.props.dispatch(capsuleActions.getSharedCapsules(100, this.props.offsetValue+100, true));
                        break;
                    default:
                        this.props.dispatch(capsuleActions.getCapsulesOfExploreGroup(groupId, 100, this.props.offsetValue+100, true));
                        break;
                }

                this.props.increaseOffset();
                this.setState({getMoreCapsules: false, capsulesReady: true});
            }
        }

        if (prevProps.user.userConfigured === false && this.props.user.userConfigured === true) {
            this.props.dispatch(userActions.getMe(JSON.parse(localStorage.getItem('MUUserLogin')).token));
            this.setState({gotCapsules: false, isExploreGroups: false});
        }

        if (((prevProps.capsules.capsule && this.props.capsules.capsule) && prevProps.capsules.capsule.length !== this.props.capsules.capsule.length) ||
        (prevProps.capsules.searchResults && this.props.capsules.searchResults && prevProps.capsules.searchResults.length !== this.props.capsules.searchResults.length)) {
            this.setState({capsulesReady: false});
        }

        if (!this.state.isShare && prevState.viewToShow !== 'shareView' && this.state.viewToShow === 'shareView') {
            if (this.props.searchTerm && this.props.searchTerm !== '') {
                if (!this.props.capsules.searchResults[this.state.navigationSlideIndex].id) {
                    window.history.pushState('', '', '/capsules/x/e/' + this.props.capsules.searchResults[this.state.navigationSlideIndex].externalSources.ElasticSearch.id);
                } else {
                    window.history.pushState('', '', '/capsules/' + this.props.capsules.searchResults[this.state.navigationSlideIndex].id);
                }

            } else {
                if (!this.props.capsules.capsule[this.state.navigationSlideIndex].id) {

                    window.history.pushState('', '', '/capsules/x/e/' + this.props.capsules.capsule[this.state.navigationSlideIndex].externalSources.ElasticSearch.id);
                } else {
                    let background = 'https://myuniverse.com/favicon.ico';

                    if (isObjectExists(this.props, 'capsules.capsule[' + this.state.navigationSlideIndex + '].items.contact.image.profile.metadata.storageUUID') && this.props.capsules.capsule[this.state.navigationSlideIndex].items.contact.image.profile.metadata.storageUUID !== '') {
                        background = getUrlAndLicence(this.props.capsules.capsule[this.state.navigationSlideIndex].items.contact.image.profile.metadata.storageUUID, false, '200x200', 'VieroToolkitUtilsTypeStillSingle').url;
                    }

                    let ogParams = '?title=' + this.props.capsules.capsule[this.state.navigationSlideIndex].name +
                                    '&description=MyUniverse capsule' +
                                    '&type=website' +
                                    '&url=' + process.env.REACT_APP_BASE_URI +
                                    '&image=' + background;
                    window.history.pushState('', '', '/capsules/' + this.props.capsules.capsule[this.state.navigationSlideIndex].id + ogParams);
                }
            }

        }

        if (prevState.viewToShow === 'shareView' && this.state.viewToShow !== 'shareView' && !this.state.isShare && !this.props.isFromGroups) {
            window.history.pushState('', '', '/');
        }

        if (this.state.socialToShow === 'main' && this.state.socialSlideIndex !== 0) {
            this.setState({socialSlideIndex: 0});
        }

        if (prevState.navigationSlideIndex !== this.state.navigationSlideIndex) {
            this.setState({isVideoBackground: false, backgroundLoaded: false, viewToShow: '', websiteUrl: null, socialToShow: 'main', isMuted: true, isFullscreen: false});
        }

        if (this.props.capsules && (!this.props.searchTerm || this.props.searchTerm === '')
        && this.props.capsules.capsule
        && 0 < this.props.capsules.capsule.length
        && this.props.capsules.capsule[this.state.navigationSlideIndex].items.contact
        && this.props.capsules.capsule[this.state.navigationSlideIndex].items.contact.socialProfiles
        && !this.state.socialSet) {
            this.setSocialProfilesToState();
        }

        if (this.props.capsules && this.props.searchTerm && this.props.searchTerm !== ''
            && this.props.capsules.searchResults
            && 0 < this.props.capsules.searchResults.length
            && this.props.capsules.searchResults[this.state.navigationSlideIndex].items.contact
            && this.props.capsules.searchResults[this.state.navigationSlideIndex].items.contact.socialProfiles
            && !this.state.socialSet) {
            this.setSocialProfilesToState();
        }

        if (!this.props.isShare && isObjectExists(this.props, 'capsules.capsule') && this.props.capsules.capsule.length !== prevProps.capsules.capsule.length && !this.state.getMoreCapsules) {
            this.setState({getMoreCapsules: true});
        }

        if (!this.props.isShare && this.props.searchTerm !== '' && isObjectExists(this.props, 'capsules.searchResults') && this.props.capsules.searchResults.length !== prevProps.capsules.searchResults.length && !this.state.getMoreCapsules) {
            this.setState({getMoreCapsules: true});
        }

        if ((this.props.user.withKey === true || this.props.user.withToken === true) && this.state.intervalId === '') {
            let token;

            if (localStorage.getItem('MUUser')) {
                token = localStorage.getItem('MUUser');
            } else if (localStorage.getItem('MUUserLogin')) {
                token = localStorage.getItem('MUUserLogin');
            }

            let intervalId = window.setInterval(() => {

                if (localStorage.getItem('MUUser')) {
                    token = localStorage.getItem('MUUser');
                } else if (localStorage.getItem('MUUserLogin')) {
                    token = localStorage.getItem('MUUserLogin');
                }

                //let tokenCreationTime = parseInt(tokenObject.created * 1000);
                //let now = new Date();
                //let difference = parseInt((now - tokenCreationTime) / 1000);

                if (localStorage.getItem('MUUserLogin') && this.props.user.withToken) {
                    this.props.dispatch(userActions.getMe(JSON.parse(localStorage.getItem('MUUserLogin')).token));
                } else {
                    this.props.dispatch(userActions.loginWithKey(JSON.parse(localStorage.getItem('MUUser')).uuid, JSON.parse(localStorage.getItem('MUUser')).loginKey));
                }

            }, (parseInt(JSON.parse(token).tokenObject.idleTimeout)* 1000));
            this.setState({intervalId});
        }
    }

    updateParentStateFunc = (state) => {
        this.setState(state);
        // eslint-disable-next-line no-console
        console.log('Parent state updater called from video with' + JSON.stringify(state) + ' :: ' + typeof(this.setState));
    }

    reloadImages = () => {
        if (this.state.backgroundLoaded) {
            this.setState({backgroundLoaded: false});
        }
    }

    setSocialProfilesToState = (socials) => {
        let socialProfiles;
        if (socials) {
            socialProfiles = socials;
        } else {
            socialProfiles = this.props.searchTerm && this.props.searchTerm !== '' ?
                [...this.props.capsules.searchResults[this.state.navigationSlideIndex > this.props.capsules.capsule.length - 1 ? this.props.capsules.capsule.length - 1 : this.state.navigationSlideIndex].items.contact.socialProfiles] :
                [...this.props.capsules.capsule[this.state.navigationSlideIndex > this.props.capsules.capsule.length - 1 ? this.props.capsules.capsule.length - 1 : this.state.navigationSlideIndex].items.contact.socialProfiles];
        }

        let mappedSocialProfiles = [];
        for (var i in socialProfiles) {
            if (socialProfiles[i].value.toolbar === true) {
                mappedSocialProfiles.push(socialProfiles[i]);
            }
        }

        this.setState({socialOptions: mappedSocialProfiles, socialSet: true});
    }

    setIsExploreGroups = (isAnonymous) => {
        if (isAnonymous && this.props.user.withKey === true) {
            this.props.setIsExploreGroupView();
            this.setState({getMoreCapsules: false});
        } else if (isAnonymous === false && this.props.user.withKey === true) {
            this.props.setIsExploreGroupView();
            this.setState({gotCapsules: false, getMoreCapsules: true});
        } else {
            this.props.setIsExploreGroupView();
        }

    }

    /*
    * Search for capsules
    */

    search = (value) => {
        if (value !== '' && typeof(value) != 'undefined') {
            this.props.dispatch(capsuleActions.clearCapsules());
            this.props.dispatch(capsuleActions.searchCapsulesByName(value));
            this.setState({searchInitiated: true, firstImportInitiated: true, socialSet: false, viewToShow: '', websiteUrl: null, socialToShow: 'main', navigationSlideIndex: 0});
        }
    }

    /*
    * Set states after changing social options, need to subtract slideIndex by 1 because different indexing on the carousel (0 is always the capsule)
    * and the social data provided by backend
    */

    afterSocialSlide = (slideIndex) => {
        let socialToShow = 'main';

        if (slideIndex !== 0) {
            socialToShow = this.state.socialOptions[slideIndex-1];
        }

        this.setState({
            socialSlideIndex: slideIndex,
            socialToShow:     socialToShow
        });
    }

    afterNavigationSlide = (slideIndex) => {

        if (this.props.changeStartingIndex) {
            this.props.changeStartingIndex(slideIndex);
        }
        this.setState({ navigationSlideIndex: slideIndex, socialSet: false});
    }

    /*
    * Render the user's groups
    */

    renderGroups = (groups) => {
        let view;
        if (groups.length > 0) {
            view = (
                <React.Fragment>
                    <div className="hover">Public</div>
                    {Object.keys(groups).map((i) => (
                        <div key={i} className="hover">{groups[i].name}</div>
                    ))}
                </React.Fragment>
            );

        }
        return view;
    }

    changeExpandState = () => {
        let state = this.state.expandedState;

        if (state === 0) {
            state = 2;
        } else {
            state = 0;
        }

        /* if (isDown) {
            if (state !== 2) {
                state++;
            } else {
                state = 0;
            }
        } else {
            if (state !== 0) {
                state--;
            } else {
                state = 2;
            }
        }*/

        this.setState({expandedState: state});
    }

    changeMenuToShow = (menu) => {
        if (this.state.viewToShow === menu) {
            this.setState({viewToShow: ''});
        } else {
            this.setState({viewToShow: menu});
        }

    }

    setGotGroups = (value) => {
        this.setState({gotGroups: value});
    }

    getClosestSizedImage = (images, size) => {
        let closestImage = {url: ''};
        let closestAbsoluteDistance;
        let isFirst = true;
        let i = 0;
        if (undefined !== size) {
            for (i in images) {
                if (isFirst && images[i].metadata && images[i].metadata.width) {
                    closestImage = images[i];
                    closestAbsoluteDistance = Math.abs(size - images[i].metadata.width);
                    isFirst = false;
                } else if (images[i].metadata && images[i].metadata.width) {
                    if (Math.abs(size - images[i].metadata.width) < closestAbsoluteDistance) {
                        closestImage = images[i];
                        closestAbsoluteDistance = Math.abs(size - images[i].metadata.width);
                    }
                }
            }
        }
        if (closestImage.url === '') {
            closestImage = images[0];

        }
        return closestImage.url;
    }

    getImageResizerParameters = (isCapsuleBackground, isViero) => {
        let element = isCapsuleBackground ? document.getElementById('capsule-view') : document.body ;
        let parameter = '';
        let vieroParameter = '';

        if (element) {
            if (isCapsuleBackground) {
                parameter = 'w' + element.offsetWidth + '-h' + element.offsetHeight;
            } else {
                if (element.clientWidth != 0 && element.clientHeight != 0) {
                    parameter = 'w' + element.clientWidth + '-h' + element.clientHeight;
                    vieroParameter = element.offsetWidth + 'x' + element.offsetHeight;
                }
            }

        }

        if (isViero) {
            return vieroParameter;
        } else {
            return parameter + '-cfill';
        }

    }

    getHyperLink = (type, currentCapsule) => {
        let links = [];

        if (type === 'phone' && currentCapsule.phoneNumbers && currentCapsule.phoneNumbers.length > 0 && currentCapsule.phoneNumbers[0].value !== '') {
            for (let i in currentCapsule.phoneNumbers) {
                links.push({link: 'tel:' + currentCapsule.phoneNumbers[i].value, value: currentCapsule.phoneNumbers[i].value});
            }
        } else if (type === 'sms' && currentCapsule.phoneNumbers && currentCapsule.phoneNumbers.length > 0 && currentCapsule.phoneNumbers[0].value !== '') {
            for (let i in currentCapsule.phoneNumbers) {
                if (navigator.userAgent.match(/iPhone/i)) {
                    links.push({link: 'sms://' + currentCapsule.phoneNumbers[i].value + '/&', value: currentCapsule.phoneNumbers[i].value});
                } else {
                    links.push({link: 'sms://' + currentCapsule.phoneNumbers[i].value + '/?', value: currentCapsule.phoneNumbers[i].value});
                }
            }
        } else if (type === 'email' && currentCapsule.emailAddresses && currentCapsule.emailAddresses.length > 0 && currentCapsule.emailAddresses[0].value !== '' ) {
            for (let i in currentCapsule.emailAddresses) {
                links.push({link: 'mailto:' + currentCapsule.emailAddresses[i].value, value: currentCapsule.emailAddresses[i].value});
            }
        }

        return links;
    }

    openSelectionModal = (links, title) => {
        this.setState({selectModalOpen: true, listOfLinks: links, titleOfSelect: title});
    }

    getCurrentCapsule = (capsules) => {
        let capsuleList = this.props.searchTerm && this.props.searchTerm !== '' ? capsules.searchResults : capsules.capsule;

        if (capsuleList[this.state.navigationSlideIndex]) {
            return capsuleList[this.state.navigationSlideIndex].items.contact;
        } else {
            return capsuleList[capsuleList.length - 1];
        }
    }

    /*
    * Renders the social and navigation carousels at the bottom
    */

    renderBottomCarousels = (capsules) => {

        let currentCapsule = this.getCurrentCapsule(capsules);
        let phoneLinks = this.getHyperLink('phone', currentCapsule);
        let smsLinks = this.getHyperLink('sms', currentCapsule);
        let emailLinks = this.getHyperLink('email', currentCapsule);

        return (

            <React.Fragment>
                <ModalView isOpen={this.state.selectModalOpen}
                    toggle={() => this.setState({selectModalOpen: !this.state.selectModalOpen})}
                    onContinue={() => {this.setState({selectModalOpen: !this.state.selectModalOpen});}}
                    title={this.state.titleOfSelect}
                    modalType="select"
                    listOfLinks={this.state.listOfLinks}
                />
                <Container className="container-width">
                    <Row className={'carousel-container social'}>
                        <img height="35px" className="active-option social" src={socialActive} />
                        <Col xs="5" sm="5" md="5" lg="5" xl="5" className="text-center" >
                            {this.state.viewToShow === 'vCard' && this.state.socialSlideIndex === 0 &&
                    <React.Fragment>
                        {phoneLinks.length < 2 ?
                            <a href={phoneLinks.length > 0 ? phoneLinks[0].link : null}><img className={phoneLinks.length > 0 ? 'option external first' : 'option external first inactive'} src={phoneNumberIcon} /></a>
                            :
                            <img onClick={() => this.openSelectionModal(phoneLinks, 'Select Phone Number')} className={'option external first'} src={phoneNumberIcon} />
                        }
                        {smsLinks.length < 2 ?
                            <a href={smsLinks.length > 0 ? smsLinks[0].link : null}><img className={smsLinks.length > 0 ? 'option external' : 'option external inactive'} src={phoneNumberIcon} /></a>
                            :
                            <img onClick={() => this.openSelectionModal(smsLinks, 'Select Phone Number')} className={'option external'} src={phoneNumberIcon} />
                        }
                        {emailLinks.length < 2 ?
                            <a href={emailLinks.length > 0 ? emailLinks[0].link : null}><img className={emailLinks.length > 0 ? 'option external last' : 'option external last inactive'} src={emailIcon} /></a>
                            :
                            <img onClick={() => this.openSelectionModal(emailLinks, 'Select Email Address')} className={'option external last'} src={emailIcon} />
                        }
                    </React.Fragment>
                            }
                        </Col>
                        {this.state.socialSet === true &&
                    <Col xs="7" sm="7" md="7" lg="7" xl="7" className="options-container">
                        <SocialCarousel
                            index={this.state.socialSlideIndex}
                            socialProfiles={this.state.socialOptions}
                            parentStateUpdater={this.afterSocialSlide}/>
                    </Col>
                        }
                    </Row>
                </Container>
                <div className="text-center">
                    { this.state.showNav ?
                        <img onClick={() => this.setState({showNav: !this.state.showNav})} src={backButton} className="footer-arrow opened white-arrow hover text-center nav-button" />
                        :
                        <img onClick={() => this.setState({showNav: !this.state.showNav})} src={backButton} className="footer-arrow closed white-arrow hover text-center nav-button" />
                    }
                </div>
                <Container className={'container-width' }>
                    <Row className={'carousel-container navigation gradient-background'}>
                        <img id="navigation-active-option" className="active-option navigation " src={cardActive} />
                        <Col >
                            <NavigationCarousel
                                index={this.state.navigationSlideIndex}
                                startingIndex={this.props.startingIndex}
                                slidesToShow={this.state.navigationSlideCount}
                                capsules={this.props.searchTerm && this.props.searchTerm !== '' ? capsules.searchResults : capsules.capsule}
                                user={this.props.user}
                                parentStateUpdater={this.afterNavigationSlide}
                                getImageFromImageAndVideos={this.props.getImageFromImageAndVideos}
                                getTransformationClassBasedOnOrientation={this.getTransformationClassBasedOnOrientation}
                                isPreview={this.props.isPreview}
                            />
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }

    getTransformationClassBasedOnOrientation = (image) => {
        var cssClass = '';

        if (isObjectExists(image, 'metadata.orientation')) {
            switch (image.metadata.orientation) {
                case 2:
                    cssClass = 'trans-mirror';
                    break;
                case 3:
                    cssClass = 'trans-rotate-180';
                    break;
                case 4:
                    cssClass = 'trans-mirror-rotate-180';
                    break;
                case 5:
                    cssClass = 'trans-mirror-rotate-90';
                    break;
                case 6:
                    cssClass = 'trans-rotate-90';
                    break;
                case 7:
                    cssClass = 'trans-mirror-rotate-270';
                    break;
                case 8:
                    cssClass = 'trans-rotate-270';
                    break;
            }
        }

        return cssClass;
    }

    getVideoBackgroundFromPhotos = (items) => {
        let images = isObjectExists(items, 'image_and_video.images') && 0 < items.image_and_video.images.length ? items.image_and_video.images : '';
        let id = '';
        if (images !== '') {
            for (let i in images) {
                if (images[i].metadata && images[i].metadata.mimeType && -1 < images[i].metadata.mimeType.indexOf('image/')) {
                    id = images[i].fileId;
                    break;
                }
            }
        }
        return id;
    }

    getVideoPosterUrl = (profile) => {
        let videoUrl;
        if (isObjectExists(profile, 'metadata.storageType') && profile.metadata.storageType === 'viero') {
            videoUrl = getUrlAndLicence(profile.metadata.storageUUID, true, '', 'VieroToolkitUtilsTypeMotion').url;
        } else {
            videoUrl = process.env.REACT_APP_API_BASE_URI + '/cdn/files/' + profile.fileId + '/preview' + '?token=' + getUserTokenFromStorage();
        }

        return videoUrl;
    }

    getPageBackground = (capsules) => {
        let background = '';
        let capsule;

        if (this.props.searchTerm && this.props.searchTerm !== '') {
            if (capsules && capsules.searchResults && 0 < capsules.searchResults.length && capsules.searchResults[this.state.navigationSlideIndex]) {
                capsule = capsules.searchResults[this.state.navigationSlideIndex];

            }
        } else {
            if (capsules && capsules.capsule && 0 < capsules.capsule.length && capsules.capsule[this.state.navigationSlideIndex]) {
                capsule = capsules.capsule[this.state.navigationSlideIndex];
            }
        }

        if (capsule && isObjectExists(capsule, 'items.contact.image.profile') && capsule.items.contact.image.profile !== null &&
        (capsule.items.contact.image.profile.fileId || capsule.items.contact.image.profile.url)) {
            let profileObject = capsule.items.contact.image.profile;
            if (profileObject.metadata && profileObject.metadata.mimeType && -1 !== profileObject.metadata.mimeType.indexOf('video/')) {
                let videoPosterUrl = this.getVideoPosterUrl(profileObject);
                background = 'body .background { ' +
                    'background-image: url(' + videoPosterUrl + ');' +
                    'background-repeat: no-repeat;' +
                    'background-size: 100% 100%; ' +
                    'background-position: center top;' +
                    'filter: blur(20px) grayscale(10%);' +
                    'position: absolute;' +
                    'z-index: -1;' +
                    'height: 100%;' +
                    'width: 100%; } ';

            } else {
                let imageUrl = '';

                if (isObjectExists(profileObject, 'metadata.storageType') && profileObject.metadata.storageType === 'viero') {
                    imageUrl = getUrlAndLicence(profileObject.metadata.storageUUID, false, this.props.getImageScalingParameter(), 'VieroToolkitUtilsTypeStillSingle').url;
                } else {
                    if (profileObject.fileId !== '') {
                        imageUrl = process.env.REACT_APP_API_BASE_URI + '/cdn/files/' +
                        profileObject.fileId + '/' + this.getImageResizerParameters() + '?token=' + getUserTokenFromStorage();
                    } else {
                        imageUrl = profileObject.url;
                    }
                }

                var image = new Image();
                image.src = imageUrl;
                background = 'body .background { ' +
                'background-image: ' +
                'url(' + imageUrl + ');' +
                'background-repeat: no-repeat;' +
                'background-size: 100% 100%;' +
                'background-position: center top;' +
                'filter: blur(20px) grayscale(10%);' +
                'position: absolute;' +
                'z-index: -1;' +
                'height: 100%;' +
                'width: 100%; } ';
            }

        } else if (capsule &&
            capsule.items &&
            capsule.items.image_and_video &&
            capsule.items.image_and_video.images &&
            0 < capsule.items.image_and_video.images.length) {
            let imageUrl = this.props.getImageFromImageAndVideos(capsule, document.body.clientWidth);
            // eslint-disable-next-line no-redeclare
            image = new Image();
            image.src = imageUrl;
            //let imgUrlProcessed = imageUrl.split('/');
            //let imgFileName = imgUrlProcessed.pop();
            // 'url(' + imgUrlProcessed.join('/') + '/' + escape(imgFileName) +');'

            background = 'body .background { '+
            'background: ' +
                'url(' + imageUrl +');'+
                'background-repeat: no-repeat;' +
                'background-size: 100% 100%;' +
                'background-position: center top;' +
                'filter: blur(15px) grayscale(10%);' +
                'position: fixed;' +
                'z-index: -1;' +
                'height: 100%;' +
                'width: 100%; }' ;
        } else {
            background = 'body { ' +
                'background-image: black;' +
                'background-repeat: no-repeat;' +
                'background-position: center top;' +
           'background-size: 100% 100%;}' +
           'body .background { display: none; }';
        }

        return background;
    }

    getMenuBackground = (capsule) => {
        let background;
        let image;

        if ((this.state.socialToShow !== 'main') && !this.props.isFromGroups || (this.props.isFromGroups || this.props.isPreview) && this.state.socialToShow !== 'main') {
            background = {
                background: 'rgba(0,0,0,0.8)',
            };
        } else {
            if (isObjectExists(capsule, 'items.contact.image.profile.fileId')) {
                let imageUrl = '';
                if (capsule.items.contact.image.profile.fileId !== '') {
                    if (isObjectExists(capsule, 'items.contact.image.profile.metadata.storageType') && capsule.items.contact.image.profile.metadata.storageType === 'viero') {
                        if (isObjectExists(capsule, 'items.contact.image.profile.metadata.mimeType') && -1 !== capsule.items.contact.image.profile.metadata.mimeType.indexOf('video/')) {
                            //imageUrl = getVideoThumbnail(capsule.items.contact.image.profile.metadata.storageUUID, 0, this.getImageResizerParameters(true, true));
                        } else {
                            imageUrl = getUrlAndLicence(capsule.items.contact.image.profile.metadata.storageUUID, false, this.props.getImageScalingParameter(), 'VieroToolkitUtilsTypeStillSingle').url;
                        }
                    } else {
                        imageUrl = process.env.REACT_APP_API_BASE_URI + '/cdn/files/' + capsule.items.contact.image.profile.fileId + '/' + this.getImageResizerParameters(true) +  '?token=' + getUserTokenFromStorage();
                    }

                } else if (isObjectExists(capsule, 'items.contact.image.profile.url') && capsule.items.contact.image.profile.url !== '') {
                    if (isObjectExists(capsule, 'items.contact.image.profile.url') && isObjectExists(capsule, 'items.contact.image.profile.metadata.mimeType') && capsule.items.contact.image.profile.metadata.mimeType === 'text/html' && capsule.items.contact.image.profile.url !== '') {
                        if (this.state.websiteUrl !== capsule.items.contact.image.profile.url) {
                            this.setState({websiteUrl: capsule.items.contact.image.profile.url});
                        }

                        imageUrl =  'https://www.google.com/s2/favicons?domain=' + capsule.items.contact.image.profile.url;
                    } else {
                        imageUrl =  capsule.items.contact.image.profile.url;
                    }

                }
                if (isObjectExists(capsule, 'items.contact.image.profile.metadata.mimeType') && -1 !== capsule.items.contact.image.profile.metadata.mimeType.indexOf('video/') && !this.state.isVideoBackground) {
                    this.setState({isVideoBackground: true, backgroundLoaded: true});

                } else {
                    if (!this.state.backgroundLoaded) {
                        image = new Image();
                        image.src = imageUrl;
                        image.onload = () => this.setState({backgroundLoaded: true});
                    } else {
                        background = {
                            backgroundImage:    'url(' + imageUrl + ')',
                            backgroundRepeat:   'no-repeat',
                            backgroundSize:     'cover',
                            backgroundPosition: 'center'
                        };

                    }
                }
            } else if (isObjectExists(capsule, 'items.image_and_video.images') && 0 < capsule.items.image_and_video.images.length) {
                let imageUrl = this.props.getImageFromImageAndVideos(capsule, document.getElementById('capsule-view'));

                //let imgUrlProcessed = imageUrl.split('/');
                //let imgFileName = imgUrlProcessed.pop();
                //'url(' + imgUrlProcessed.join('/') + '/' + escape(imgFileName) +')'
                if (!this.state.backgroundLoaded) {
                    image = new Image();
                    image.src = imageUrl;

                    image.onload = () => this.setState({backgroundLoaded: true});
                } else {
                    background = {
                        backgroundImage:    'url(' + imageUrl +')',
                        backgroundRepeat:   'no-repeat',
                        backgroundSize:     'cover',
                        backgroundPosition: 'center top',
                    };
                }
            } else {
                if (!this.state.backgroundLoaded) {
                    this.setState({backgroundLoaded: true});
                }
            }
        }

        return background;
    }

    goFullScreen = () => {
        let myVideo = document.getElementsByTagName('video')[0];
        let capsuleToCheck;
        let iframe = document.getElementsByTagName('iframe')[0];
        if (this.props.searchTerm && this.props.searchTerm !== '') {
            capsuleToCheck = this.props.capsules.searchResults[this.state.navigationSlideIndex];
        } else {
            capsuleToCheck = this.props.capsules.capsule[this.state.navigationSlideIndex];
        }

        if (capsuleToCheck.items.contact.image.profile.url === 'https://staging.myuniverse.com/wrap') {
            iframe.contentWindow.postMessage('toggleFullscreen', '*');
        } else if (0 < navigator.userAgent.indexOf('Safari')) {
            myVideo.webkitEnterFullscreen();
        } else {
            myVideo.requestFullscreen().catch(err => {
                // eslint-disable-next-line no-console
                console.log(err);
            });
        }
        this.setState({isFullscreen: !this.state.isFullscreen});

    }

    isUsersCapsule = () => {
        let result = false;

        if (this.props.searchTerm !== '') {
            if (isObjectExists(this.props, 'capsules.searchResults') && isObjectExists(this.props, 'user.id') && this.props.capsules.searchResults[this.state.navigationSlideIndex] && this.props.user.id === this.props.capsules.searchResults[this.state.navigationSlideIndex].ownerId) {
                result = true;
            }
        } else {
            if (isObjectExists(this.props, 'capsules.capsule') && isObjectExists(this.props, 'user.id') && this.props.capsules.capsule[this.state.navigationSlideIndex] && this.props.user.id === this.props.capsules.capsule[this.state.navigationSlideIndex].ownerId) {
                result = true;
            }
        }

        return result;
    }

    changeMute = () => {

        this.setState({isMuted: !this.state.isMuted});

    }

    toggleCapsuleFullscreen = () => {
        if (this.state.fullscreenCapsule) {
            document.documentElement.style.overflowY = 'auto';
        } else {
            document.documentElement.style.overflowY = 'hidden';
        }

        this.setState({fullscreenCapsule: !this.state.fullscreenCapsule});
    }

    renderHeader = () => {
        let header = '';
        let muteButton = '';
        let capsuleToOpen;

        if (this.props.searchTerm && this.props.searchTerm !== '') {
            capsuleToOpen = this.props.capsules.searchResults[this.state.navigationSlideIndex];
        } else {
            capsuleToOpen = this.props.capsules.capsule[this.state.navigationSlideIndex];
        }

        if (!this.props.isPreview) {
            header =
        <Header
            capsule={this.props.capsules.capsule[this.state.navigationSlideIndex]}
            groupName={this.props.groupName}
            navigateTo={this.props.navigateTo}
            setCapsuleToEdit={this.props.setCapsuleToEdit}
            user={this.props.user}
            backToGroups={this.props.backToGroups}
            openEditCard={() => this.props.openEditCard(capsuleToOpen)}
            isUsers={this.isUsersCapsule}
            logout={this.props.logout}
            isShare={this.state.isShare}
            changeMute={this.changeMute}
            isMuted={this.state.isMuted}
            websiteUrl={this.state.websiteUrl}
            isVideoBackground={this.state.isVideoBackground}
            canSignUp={capsuleToOpen ? capsuleToOpen.id === '1f332adc-27ae-4da5-bfa3-1480feab2f26' : false}
            shouldShowPublicProfileButton={this.props.shouldShowPublicProfileButton}
        />;

        } else {
            if (this.state.isVideoBackground) {
                muteButton = this.state.isMuted ?
                    <img src={mutedIcon} className="mute-icon img-fluid right" onClick={() => this.changeMute()}/>
                    :
                    <img src={unmutedIcon} className="mute-icon img-fluid right" onClick={() => this.changeMute()}/>;
            }
        }

        return header !== '' ?
            <Container className="">

                <Row className="header d-flex align-items-end">{header}</Row>
            </Container>
            :
            <Col className="hover my-auto right mute">{muteButton}</Col>;
    }

    getShareError = () => {
        let result = false;
        let errorMessage = '';
        let statusCode = '';

        if (this.props.isShare && isObjectExists(this.props, 'capsules.error.messages.error')) {
            statusCode = this.props.capsules.error.messages.error.statusCode;
            if (statusCode === 403) {
                errorMessage = this.props.capsules.error.messages.error.message;
                switch (errorMessage) {
                    case 'Can\'t share with yourself.':
                        result = true;
                        break;
                    case 'Share token invalid':
                    case 'Expired share token':
                        result = this.shareErrorExpired;
                        break;
                    case 'Owner not found':
                    case 'Capsule not found':
                        result = this.shareErrorDeleted;
                        break;
                    case 'Invalid share token':
                        result = this.shareErrorInvalid;
                        break;
                    default:
                        if (-1 < errorMessage.indexOf('has no permission to read capsule')) {
                            result = this.shareErrorPrivate;
                        }
                }
            } else if (statusCode === 404) {
                result = this.shareErrorDeleted;
            }
        }

        return result;
    }

    renderExplore = (capsules) => {
        let capsule;

        if (this.props.searchTerm && this.props.searchTerm !== '') {
            capsule = capsules.searchResults[this.state.navigationSlideIndex];
        } else if (capsules && capsules.capsule && capsules.capsule.length > 0) {
            capsule = capsules.capsule[this.state.navigationSlideIndex];
        }
        return (
            <React.Fragment>
                { ((capsules && capsules.capsule && capsules.capsule.length > 0)
                || (this.props.searchTerm && this.props.searchTerm !== '' && capsules && capsules.searchResults && capsules.searchResults.length > 0))
                && (this.props.user.withKey === true || this.props.user.withToken === true) &&
                    <div id='explore-container' className={this.state.fullscreenCapsule ? 'full-capsule transition' : 'container transition'}>
                        {this.renderHeader()}

                        <React.Fragment>
                            <CapsuleContainer
                                capsule={capsule}
                                capsules={capsules}
                                dispatch={this.props.dispatch}
                                user={this.props.user}
                                changeMenuToShow={this.changeMenuToShow}
                                isPreview={this.props.isPreview}
                                isEdit={this.props.isEdit}
                                isVideoBackground={this.state.isVideoBackground}
                                searchInitiated={this.state.searchInitiated}
                                socialType={this.state.socialToShow}
                                navigationSlideIndex={this.state.navigationSlideIndex}
                                setGotGroups={this.setGotGroups}
                                viewToShow={this.state.viewToShow}
                                getMenuBackground={this.getMenuBackground}
                                getImageFromImageAndVideos={this.props.getImageFromImageAndVideos}
                                getTransformationClassBasedOnOrientation={this.getTransformationClassBasedOnOrientation}
                                getImageResizerParameters={this.getImageResizerParameters}
                                expandedState={this.state.expandedState}
                                getThumbnailPicture={this.props.getThumbnailPicture}
                                backgroundLoaded={this.state.backgroundLoaded}
                                isFromGroups={this.props.isFromGroups}
                                openEditCard={this.props.openEditCard}
                                deleteCapsule={this.props.deleteCapsule}
                                isCreating={this.props.isCreating}
                                websiteUrl={this.state.websiteUrl}
                                listenStatus={this.props.listenStatus}
                                config={this.props.config}
                                getStatusOfMedia={this.props.getStatusOfMedia}
                                isFullscreen={this.state.isFullscreen}
                                isMuted={this.state.isMuted}
                                updateParentStateFunc={this.updateParentStateFunc}
                                getImageScalingParameter={this.props.getImageScalingParameter}
                                fullscreenCapsule={this.state.fullscreenCapsule}
                                isShare={this.props.isShare}
                                showNav={this.state.showNav}
                                setFullscreen={() => this.toggleCapsuleFullscreen()}
                                isCapsuleFullscreen={this.state.fullscreenCapsule}
                            />
                            <Row className="divider explore" >
                                {
                                    (this.state.isVideoBackground === true &&
                         this.state.socialSlideIndex == 0 &&
                         this.state.viewToShow === '' ) &&
                        <Col lg="3" md="3" sm="3" xs="3" xl="3" className="my-auto fullscreen-btn-container">
                            <button onClick={() => this.goFullScreen()} className="fullscreen-btn hover">Fullscreen</button>
                        </Col>
                                }
                                {
                                    /*this.state.socialSlideIndex == 0 &&
                         this.state.viewToShow === 'vCard' &&  */
                                }
                            </Row>
                            {!this.state.fullscreenCapsule && this.renderBottomContent(capsules)}

                        </React.Fragment>

                    </div>
                }
                <ModalView isOpen={this.state.groupShared}
                    toggle={() => this.setState({groupShared: !this.state.groupShared})}
                    onContinue={() => {location.href = process.env.REACT_APP_BASE_URI;}}
                    title={'Group share successful'}
                    modalType="groupShared"
                    listOfLinks={this.state.listOfLinks}
                />

            </React.Fragment>

        );
    }

    render() {
        let capsules = [];
        let shareError = this.getShareError();
        let url;

        if (this.props.isPreview && this.props.capsuleToPreview) {
            capsules = {...this.props.capsules, capsule: [JSON.parse(assemblePayload(this.props.capsuleToPreview))]};
            capsules.capsule[0].id = this.props.capsuleToPreview.capsule.id;

            if (!this.state.socialSet) {
                this.setSocialProfilesToState(capsules.capsule[0].items.contact.socialProfiles);
            }

        } else {
            capsules = this.props.capsules;
        }

        switch (shareError) {
            case false:
                if (this.props.isExploreGroup) {
                    return <ExploreGroups capsules={capsules}
                        dispatch={this.props.dispatch}
                        user={this.props.user}
                        setIsExploreGroups={this.setIsExploreGroups}
                        afterNavigationSlide={this.afterNavigationSlide}
                        groupsToAdd={this.props.groupsToAdd}
                        onRemoveGroup={this.props.onRemoveGroup}
                        onAddGroup={this.props.onAddGroup}
                        setGroupsToAdd={this.props.setGroupsToAdd}
                    />;
                } else if (((!capsules.capsule || capsules.capsule.length === 0) && (!capsules.searchResults || capsules.searchResults.length === 0)) && capsules.loaded) {
                    return (
                        <Container>
                            <Row>
                                <Col className="text-center">
                                There are no capsules to show.
                                </Col>
                            </Row>
                            <Row>
                                {this.state.searchInitiated ?
                                    null
                                    :
                                    <Col className="text-center"><button type="button" className="btn btn-light create-button set-group" onClick={() => location.reload()}>Back to Home</button></Col>
                                    //<Col className="text-center"><button type="button" className="btn btn-light create-button set-group" onClick={() => this.props.backToGroups()}>Back to Home</button></Col>
                                }

                            </Row>
                        </Container>);

                } else {
                    return this.renderExplore(capsules);
                }

            case true:
                url = this.props.history.location.pathname.split('?')[0];
                window.location.href = url;
                break;
            default:
                return (
                    <Container>
                        <Row>
                            <Col className="text-center">{shareError}</Col>
                        </Row>
                    </Container>
                );
        }
    }

    renderBottomContent = (capsules) => {
        if (this.state.viewToShow === 'media' || this.state.viewToShow === 'addCard' || this.state.viewToShow === 'chatbotView') {
            return null;
        } else if (this.state.expandedState === 1) {
            return <MiniCapsule getThumbnailPicture={this.props.getThumbnailPicture} capsuleToShow={this.props.capsules.capsule[this.state.navigationSlideIndex]}/>;
        } else {
            return this.renderBottomCarousels(capsules);
        }
    }
}

Explore.propTypes = {
    dispatch:                      PropTypes.func,
    capsule:                       PropTypes.string,
    capsules:                      PropTypes.object,
    user:                          PropTypes.object,
    config:                        PropTypes.object,
    match:                         PropTypes.object,
    history:                       PropTypes.object,
    navigateTo:                    PropTypes.func,
    isPreview:                     PropTypes.bool,
    capsuleToPreview:              PropTypes.object,
    logout:                        PropTypes.func,
    isEdit:                        PropTypes.bool,
    isFromGroups:                  PropTypes.bool,
    groupName:                     PropTypes.string,
    backToGroups:                  PropTypes.func,
    openEditCard:                  PropTypes.func,
    deleteCapsule:                 PropTypes.func,
    startingIndex:                 PropTypes.number,
    changeStartingIndex:           PropTypes.func,
    isCreating:                    PropTypes.bool,
    setIsExploreGroupView:         PropTypes.func,
    isExploreGroup:                PropTypes.bool,
    groupsToAdd:                   PropTypes.array,
    onRemoveGroup:                 PropTypes.func,
    onAddGroup:                    PropTypes.func,
    setGroupsToAdd:                PropTypes.func,
    getThumbnailPicture:           PropTypes.func,
    getImageFromImageAndVideos:    PropTypes.func,
    listenStatus:                  PropTypes.func,
    getStatusOfMedia:              PropTypes.func,
    isMuted:                       PropTypes.bool,
    changeMute:                    PropTypes.func,
    getImageScalingParameter:      PropTypes.func,
    isShare:                       PropTypes.bool,
    typeOfList:                    PropTypes.string,
    viewedGroup:                   PropTypes.string,
    searchTerm:                    PropTypes.string,
    offsetValue:                   PropTypes.number,
    increaseOffset:                PropTypes.func,
    setCapsuleToEdit:              PropTypes.func,
    shouldShowPublicProfileButton: PropTypes.func,
    groupShare:                    PropTypes.bool

};

function mapStateToProps(state) {
    const { user, config, capsules} = state;
    return {
        user,
        config,
        capsules
    };
}

const connectedExplorePage = connect(mapStateToProps)(Explore);
export { connectedExplorePage as Explore };
