/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { isObjectExists, getObjectValue, getCapsuleName } from '../../utils/Helpers';
import { getUrlAndLicence, getVideoThumbnail} from '../../utils/VieroUtils';
//import xIcon from '../../assets/images/x.png';

class FeaturedComponent extends React.Component {
    static propTypes = {
        index:                                    PropTypes.number,
        socialProfiles:                           PropTypes.arrayOf(PropTypes.object),
        parentStateUpdater:                       PropTypes.func,
        slidesToShow:                             PropTypes.number,
        capsules:                                 PropTypes.array,
        user:                                     PropTypes.object,
        getTransformationClassBasedOnOrientation: PropTypes.func,
        isPreview:                                PropTypes.bool,
        startingIndex:                            PropTypes.number,
        getImageFromImageAndVideos:               PropTypes.func,
        onSelect:                                 PropTypes.func,
        group:                                    PropTypes.object,
        isGroup:                                  PropTypes.bool,
        groupName:                                PropTypes.string,
        isFeatured:                               PropTypes.bool,
        dispatch:                                 PropTypes.func,
        getFavoriteButton:                        PropTypes.func,
        openFavorites:                            PropTypes.func,
        publicPage:                               PropTypes.bool
    }

    static defaultProps = {
        index:          '',
        socialProfiles: [{}],
        user:           {}
    }

    constructor(props) {
        super(props);

        this.state = {
            index: this.props.isPreview ? 0 : props.index,
            nav1:  null,
            nav2:  null
        };

        this.thumbnailParam = '125x175';

    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    isOneItemCapsule = () => {
        let res = false;

        if (1 === this.props.capsules.length || this.props.isPreview === true) {
            res = true;
        }
        return res;
    }

    afterNavigationSlide = (index) => {
        this.setState({index: index});
        this.props.parentStateUpdater(index);
    }

    getClosestSizedImage = (images, size) => {
        let closestImage = {url: ''};
        let closestAbsoluteDistance;
        let isFirst = true;
        let i = 0;
        if (undefined !== size) {
            for (i in images) {
                if (isFirst && images[i].metadata && images[i].metadata.width) {
                    closestImage = images[i];
                    closestAbsoluteDistance = Math.abs(size - images[i].metadata.width);
                    isFirst = false;
                } else if (images[i].metadata && images[i].metadata.width) {
                    if (Math.abs(size - images[i].metadata.width) < closestAbsoluteDistance) {
                        closestImage = images[i];
                        closestAbsoluteDistance = Math.abs(size - images[i].metadata.width);
                    }
                }
            }
        }
        if (closestImage.url === '') {
            closestImage = images[0];

        }
        return closestImage.url;
    }

    getImageUrl = (imageObject) => {
        let imageUrl;

        if (isObjectExists(imageObject, 'metadata.storageType') && imageObject.metadata.storageType === 'viero') {
            if (imageObject.metadata.mimeType.indexOf('video/') === -1) {
                imageUrl = getUrlAndLicence(imageObject.metadata.storageUUID, false, this.thumbnailParam, 'VieroToolkitUtilsTypeStillSingle').url;
            } else {
                imageUrl = getVideoThumbnail(imageObject.metadata.storageUUID, 0, this.thumbnailParam);
            }

        } else {

            if (isObjectExists(imageObject, 'fileId') && imageObject.fileId !== '') {
                imageUrl = process.env.REACT_APP_API_BASE_URI + '/cdn/files/' +
                    imageObject.fileId + '/w100-h100-cfill' + '?token=' + this.props.user.token;
            } else {
                imageUrl = imageObject.url;
            }

        }
        return imageUrl;
    }

    getCarouselImage = (capsule, isFeatured) => {
        let background = '';
        let className = isFeatured ? 'text-center featured-img hover capsule-name' : 'text-center capsule-name featured-sm-img mx-auto hover';

        if ((isObjectExists(capsule, 'items.contact.image.profile.fileId') || isObjectExists(capsule, 'items.contact.image.profile.url')) && capsule.items.contact.image.profile.metadata.mimeType !== 'text/html') {
            let imageUrl = this.getImageUrl(capsule.items.contact.image.profile);

            background = {
                backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0) 38%,rgba(0,0,0,0.9) 100%), url("' + imageUrl + '")',

                backgroundRepeat:   'no-repeat',
                backgroundSize:     'cover',
                backgroundPosition: 'center',
            };

            return (

                <div className="home-navigation">
                    <div className={className} style={background} >
                        {!isFeatured && this.props.getFavoriteButton(capsule, this.props.isFeatured)}
                    </div>
                    <div className="text-center capsule-name"><span>{getCapsuleName(capsule)}</span></div>

                </div>

            );
        } else if (isObjectExists(capsule, 'items.contact.image.thumbnail.fileId') || isObjectExists(capsule, 'items.contact.image.thumbnail.url')) {
            let imageUrl = this.getImageUrl(capsule.items.contact.image.thumbnail);

            background = {
                backgroundImage: 'url("' + imageUrl + '")',

                backgroundRepeat:   'no-repeat',
                backgroundSize:     'cover',
                backgroundPosition: 'center',
            };

            return (

                <div className="home-navigation">
                    <div className={className} style={background} >
                        {!isFeatured && this.props.getFavoriteButton(capsule, this.props.isFeatured)}
                    </div>
                    <div className="text-center capsule-name"><span>{getCapsuleName(capsule)}</span></div>
                </div>

            );

        } else if (isObjectExists(capsule, 'items.image_and_video.images') && getObjectValue(capsule, 'items.image_and_video.images').length > 0) {
            let imageUrl = this.props.getImageFromImageAndVideos(capsule, document.getElementById('navigation-active-option'));
            background = {
                backgroundImage: 'url("' + imageUrl + '")',

                backgroundRepeat:   'no-repeat',
                backgroundSize:     'auto 100%',
                backgroundPosition: 'center',
            };

            return (
                <div className="home-navigation">
                    {/*<img id="" className="x-icon" src={xIcon} />*/}
                    <div className={className} style={background} >
                        {!isFeatured && this.props.getFavoriteButton(capsule, this.props.isFeatured)}
                    </div>
                    <div className="text-center capsule-name"><span>{getCapsuleName(capsule)}</span></div>
                </div>
            );
        } else {
            return (
                <div className="home-navigation">
                    {/*<img id="" className="x-icon" src={xIcon} />*/}
                    <div className={className + ' no-pic'}>
                        {!isFeatured && this.props.getFavoriteButton(capsule, this.props.isFeatured)}
                    </div>
                    <div className="text-center capsule-name"><span>{getCapsuleName(capsule)}</span></div>
                </div>);
        }
    }

    addPlaceHolderSlide = () => {
        if (this.props.isPreview) {
            return <div></div>;
        } else {
            return null;
        }
    }

    getInitialSlide = () => {
        let slide;
        if (this.isOneItemCapsule()) {
            slide = 1;
        } else if (this.props.startingIndex) {
            slide = this.props.startingIndex;
        } else {
            slide = this.props.index;
        }

        return slide;
    }

    onClick = (i) => {
        if (this.props.group) {
            this.props.onSelect(i, this.props.capsules, this.props.groupName, this.props.group.id);
        } else {
            this.props.onSelect(i, this.props.capsules, this.props.groupName);
        }

    }

    shouldShow = (capsule) => {
        if (!this.props.user.publicPage || (this.props.user.publicPage && capsule.public === true && capsule.ownerId === this.props.user.publicUserId)) {
            return true;
        } else {
            return false;
        }
    }

    getCapsules = () => {
        let capsules = this.props.capsules;
        let filteredCapsules = [];

        for (let i in capsules) {
            if (this.shouldShow(capsules[i])) {
                filteredCapsules.push(capsules[i]);
            }
        }

        return filteredCapsules;
    }

    render() {
        let settings = {
            arrows:         true,
            autoplay:       false,
            dots:           false,
            infinite:       false,
            //lazyLoad:      'onDemand',
            initialSlide:   this.props.index,
            slidesToShow:   1,
            slidesToScroll: 1,
            centerPadding:  0

        };

        let settingsForSliderTwo = {
            arrows:         true,
            autoplay:       false,
            dots:           false,
            infinite:       false,
            //lazyLoad:      'onDemand',
            initialSlide:   this.props.index,
            slidesToShow:   7,
            slidesToScroll: 1,
            swipeToSlide:   true,
            focusOnSelect:  true,
            responsive:     [
                {
                    breakpoint: 1024,
                    settings:   {
                        slidesToShow:   4,
                        slidesToScroll: 1,

                    }
                },
                {
                    breakpoint: 600,
                    settings:   {
                        slidesToShow:   3,
                        slidesToScroll: 1,
                        initialSlide:   1
                    }
                },
                {
                    breakpoint: 480,
                    settings:   {
                        slidesToShow:   3.5,
                        slidesToScroll: 1
                    }
                }
            ]

        };

        let capsules = this.getCapsules();
        return (
            <React.Fragment>
                <div>
                    <Slider {...settings}
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}>
                        {   //Map the navigation options in the order provided by backend

                            Object.keys(capsules).map((i) => (
                                <React.Fragment key={i}>
                                   
                                    <div onClick={() => this.onClick(i)}>
                                        {this.getCarouselImage(capsules[i], true)
                                        }
                                    </div>
                                    
                                </React.Fragment>
                            ))

                        }
                    </Slider>
                </div>
                <div className="title-line my-3">
                    <span>{this.props.groupName}</span>
                    {!this.props.isFeatured && !this.props.publicPage && <span className="float-right" onClick={() => this.props.openFavorites()}>Manage Favorites</span>}</div>
                <div className="home-navigation-wrapper">
                    <Slider {...settingsForSliderTwo}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}>
                        {   //Map the navigation options in the order provided by backend

                            Object.keys(capsules).map((i) => (
                                
                                <React.Fragment key={i}>
                                   
                                    <div onClick={() => this.onClick(i)}>
                                        {this.getCarouselImage(capsules[i])}
                                    </div>
                 
                                </React.Fragment>
                            ))

                        }
                    </Slider>
                </div>
            </React.Fragment>
        );
    }
}

export default FeaturedComponent;
