import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, ModalHeader, Container, FormGroup, Input, Label, Collapse } from 'reactstrap';
import linkIcon from '../../assets/images/Link.png';
import uploadIcon from '../../assets/images/Files.png';
import backButton from '../../assets/images/Close@2x.png';
import Iframe from 'react-iframe';
import { ShareBackView } from './modals/ShareBackView';
import { ShareBackCapsuleView } from './modals/ShareBackCapsuleView';
import { SendGridView } from './modals/SendGridView';
//import { sortAlphabetically } from '../../utils/Helpers';

export class ModalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url:                 this.props.defaultValue,
            urlValid:            true,
            isOptions:           true,
            groupName:           '',
            subgroupStates:      {},
            exploreGroupsToShow: [this.props.exploreGroups],
            path:                ['...']

        };

        this.deleteText = 'Delete';
        this.cancelText = 'Cancel';
        this.uploadText = 'Upload';
        this.urlText = 'URL';
        this.setUrlText = 'Set URL';
        this.allYourUnsavedText = 'All your unsaved changes will be lost.';
        this.exitText = 'Exit';
        this.saveText = 'Save';
        this.yesText = 'Yes';
        this.selectText = 'Select';
        this.noText = 'No';
        this.publicText = 'Public';
        this.privateText = 'Private';
        this.removeText = 'Remove';
        this.publicDirectoryText = 'Public Directory';
        this.continueText = 'Continue';
        this.privateToPublicText = 'This capsule is currently set to Private and will need to be changed to Public in order to have it displayed in the Public Explore Group you selected. Do you want to save this capsule as Public?';
        this.privateInPublicText = 'You changed the capsule setting to Private but still have at least one Public Explore group selected. Do you want to continue saving the capsule as Private?';
        this.noExploreGroups = 'You have removed all Public Explore groups from the capsule but it will remain in Most Recent and will be publicly searchable unless you choose to change the capsule to Private. Would you like the capsule to be Public or Private?';
        this.searchableOrPrivateText = 'The Capsules you want to remove from the group are public. Do you want to keep them searchable or make them private?';
        this.removeCapsuleFromGroupText = 'Are you sure you want to remove the Capsule from this group?';
        this.changedAccountText = 'Looks like you are logged in to another account.';
        this.loggedOutText = 'Please login since this account was logged out';
        this.waitForUploadText = 'Please wait while we finish uploading your files...';
        this.okText = 'OK';
        this.createPrivateText = 'Create Private Group';
        this.youCanSavePartOneText = 'Uploading has finished.';
        this.youCanSavePartTwoText = 'Capsule can be saved without waiting for processing to complete.';
        this.dontShowAgainText = 'Don\'t show this message again';
        this.waitForProcessText = 'The uploaded image needs some time to be shown.';
        this.emailSentText = 'We have sent a verification email to your new address. To complete the change of your email address please check your inbox.';
        this.verifyPinText = 'Please enter the PIN we have sent you';
        this.verifyText = 'Verify PIN';
        this.pinVerifiedText = 'Your new phone number has been successfully verified';
        this.copiedToClipboard = 'Link Copied';
        this.contactWithMyu = 'Contact us at support@myuniverse.com to get permission.';
        this.shareIsPublicPage = 'You can view this capsules public page, or the capsule.';
        this.goToPublic = 'View public page';
        this.viewCapsule = 'View capsule';
        this.groupShared = 'Group has been successfully shared with you!';

    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.setState({url: this.props.defaultValue});
        }
    }

    toggle = () => {
        this.props.toggle();
        this.setState({isOptions: true});
    }

    renderDelete = () => {
        return (
            <Row className="text-center button-row text-secondary">
                <Col>
                    <button type="button" onClick={() => {this.props.onContinue(); this.toggle();}} className="btn btn-light create-button btn-sm">{this.deleteText}</button>
                </Col>
                <Col >
                    <button type="button" onClick={() => this.toggle()} className="btn btn-light create-button btn-sm">{this.cancelText}</button>
                </Col>
            </Row>
        );
    }

    renderOptions = () => {
        return (
            <Row className="text-center button-row text-secondary">
                <Col className="url-options">
                    <span onClick={() => this.props.onUpload()} className="hover">
                        <img src={uploadIcon} className="option-icon"/>
                        <div className="navigation-button">{this.uploadText}</div>
                    </span>
                </Col>
                {!this.props.isProfile &&
                <Col>
                    <span onClick={() => this.setState({isOptions: false})} className="hover">
                        <img src={linkIcon} className="option-icon" />
                        <div className="navigation-button">{this.urlText}</div>
                    </span>
                </Col>
                }
            </Row>
        );
    }

    renderUrlPicker = () => {
        return (

            <Container>
                <Row className="align-items-center">
                    <Col lg="2" md="2" sm="2" xs="2">
                        <img className="link-icon" src={linkIcon} />
                    </Col>
                    <Col lg="10" md="10" sm="10" xs="10">
                        <input
                            value={this.state.url}
                            onChange={(e) => this.setState({url: e.target.value})}
                            name="url"
                            id="url"
                            placeholder="URL"
                            className="capsule-input"
                        />
                    </Col>
                </Row>
                <Row className="text-center button-row">
                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            onClick={this.props.type ? () =>
                            {this.props.onContinue(this.state.url, this.props.type); this.setState({isOptions: true, url: ''}); this.toggle();}
                                :
                                () => {this.props.onContinue(this.state.url); this.setState({isOptions: true, url: ''});}}>{this.setUrlText}</button>
                    </Col>
                </Row>
            </Container>

        );
    }

    renderBack = () => {
        return (

            <Container>
                <Row className="align-items-center text-secondary">
                    <Col className="text-center">
                        {this.allYourUnsavedText}
                    </Col>
                </Row>
                <Row className="text-center button-row text-secondary">
                    <Col>
                        <button type="button" onClick={() => this.props.onContinue()} className="btn btn-light create-button btn-sm">{this.exitText}</button>
                    </Col>
                    <Col >
                        <button type="button" onClick={() => this.props.onCancel()} className="btn btn-light create-button btn-sm">{this.saveText}</button>
                    </Col>
                </Row>
            </Container>

        );
    }

    renderSetPublic = () => {
        return (

            <Container>
                <Row className="align-items-center text-secondary">
                    <Col className="text-center">
                        {this.privateToPublicText}
                    </Col>
                </Row>
                <Row className="text-center button-row text-secondary">
                    <Col>
                        <button type="button" onClick={() => {this.props.onContinue(true); this.props.toggle();}} className="btn btn-light create-button btn-sm">{this.yesText}</button>
                    </Col>
                    <Col >
                        <button type="button" onClick={() => {this.props.onContinue(false); this.props.toggle();}} className="btn btn-light create-button btn-sm">{this.noText}</button>
                    </Col>
                </Row>
            </Container>

        );
    }

    renderPublicToPrivate = () => {
        return (

            <Container>
                <Row className="align-items-center text-secondary">
                    <Col className="text-center">
                        {this.privateInPublicText}
                    </Col>
                </Row>
                <Row className="text-center button-row text-secondary">
                    <Col>
                        <button type="button" onClick={() => {this.props.onContinue(false); this.props.toggle();}} className="btn btn-light create-button btn-sm">{this.yesText}</button>
                    </Col>
                    <Col >
                        <button type="button" onClick={() => {this.props.onContinue(true); this.props.toggle();}} className="btn btn-light create-button btn-sm">{this.noText}</button>
                    </Col>
                </Row>
            </Container>

        );
    }

    renderNoExploreGroups = () => {
        return (

            <Container>
                <Row className="align-items-center text-secondary">
                    <Col className="text-center">
                        {this.noExploreGroups}
                    </Col>
                </Row>
                <Row className="text-center button-row text-secondary">
                    <Col>
                        <button type="button" onClick={() => {this.props.onContinue(true); this.props.toggle();}} className="btn btn-light create-button btn-sm">{this.publicText}</button>
                    </Col>
                    <Col >
                        <button type="button" onClick={() => {this.props.onContinue(false); this.props.toggle();}} className="btn btn-light create-button btn-sm">{this.privateText}</button>
                    </Col>
                </Row>
            </Container>

        );
    }

    renderPrivateCapsules = () => {
        return (

            <Container>
                <Row className="align-items-center text-secondary">
                    <Col className="text-center">
                    There are some private capsules on your list. Private capsules cannot be added to Public Directories. Do you want to set these capsules to public?
                    </Col>
                </Row>
                <Row className="text-center button-row text-secondary">
                    <Col>
                        <button type="button" onClick={() => {this.props.onContinue(); this.props.toggle();}} className="btn btn-light create-button btn-sm">{this.yesText}</button>
                    </Col>
                    <Col >
                        <button type="button" onClick={() => {this.props.onCancel(); this.props.toggle();}} className="btn btn-light create-button btn-sm">{this.noText}</button>
                    </Col>
                </Row>
            </Container>

        );
    }

    renderPublicCapsules = () => {
        return (

            <Container>
                <Row className="align-items-center text-secondary">
                    <Col className="text-center">
                        {this.searchableOrPrivateText}
                    </Col>
                </Row>
                <Row className="text-center button-row text-secondary">
                    <Col>
                        <button type="button" onClick={() => {this.props.onContinue(true); this.props.toggle();}} className="btn btn-light create-button btn-sm">{this.publicText}</button>
                    </Col>
                    <Col >
                        <button type="button" onClick={() => {this.props.onContinue(false); this.props.toggle();}} className="btn btn-light create-button btn-sm">{this.privateText}</button>
                    </Col>
                </Row>
            </Container>

        );
    }

    renderRemoveFromGroup = () => {
        return (
            <Container>
                <Row className="align-items-center text-secondary">
                    <Col className="text-center">
                        {this.removeCapsuleFromGroupText}
                    </Col>
                </Row>
                <Row className="text-center button-row text-secondary">
                    <Col>
                        <button type="button" onClick={() => {this.props.onContinue(); this.props.toggle();}} className="btn btn-light create-button btn-sm">{this.removeText}</button>
                    </Col>
                    <Col >
                        <button type="button" onClick={() => {this.props.onCancel(); this.props.toggle();}} className="btn btn-light create-button btn-sm">{this.cancelText}</button>
                    </Col>
                </Row>
            </Container>
        );
    }

    renderUserChanged = () => {
        return (

            <Container>
                <Row className="align-items-center text-secondary">
                    <Col className="text-center">
                        {this.changedAccountText}
                    </Col>
                </Row>
                <Row className="text-center button-row text-secondary">
                    <Col>
                        <button type="button" onClick={() => location.reload()} className="btn btn-light create-button btn-sm">{this.continueText}</button>
                    </Col>
                </Row>
            </Container>

        );
    }

    getSubgroupsOfGroup = (group, groupsOfCapsule) => {
        let subgroups = group.child || [];
        let groupsToShow = null;

        if (subgroups.length > 0) {
            groupsToShow = Object.keys(subgroups).map((i) =>
                <Row key={i} className="group-element text-secondary">

                    <Col xs="8" sm="8" md="8" lg="8" className="sub-groups">
                        {subgroups[i].name}
                    </Col>
                    <Col xs="2" sm="2" md="2" lg="2">
                    </Col>
                    <Col xs="2" sm="2" md="2" lg="2">
                        {
                            groupsOfCapsule && groupsOfCapsule.indexOf(subgroups[i].id) > -1 ?
                                <span onClick={() => this.props.onRemoveGroup(subgroups[i].id, true)} className="pl-2 circle-filled right group-circle subgroup-circle" />
                                :
                                <span onClick={() => this.props.onAddGroup(subgroups[i].id, true)} className="pl-2 circle right group-circle subgroup-circle" />
                        }
                    </Col>

                </Row>
            );
        }
        return groupsToShow;

    }

    toggleSubgroup = (i) => {
        let subgroupStates = {...this.state.subgroupStates};

        subgroupStates[i] = subgroupStates[i] ? !subgroupStates[i] : true;

        this.setState({subgroupStates});
    }

    renderUserGroups = (groups, groupsOfCapsule) => {
        return (
            <React.Fragment>
                <Row className="group-element">
                    <Col className="group-list-divider letter-line"><span>{this.privateText}</span> </Col>
                </Row>

                {Object.keys(groups).map((i) =>
                    <Row key={i} className="group-element text-secondary">

                        <Col>
                            {groups[i].name}
                        </Col>

                        <Col className="right">
                            {
                                groupsOfCapsule && groupsOfCapsule.indexOf(groups[i].id) > -1 ?
                                    <span onClick={() => this.props.onRemoveGroup(groups[i].id)} className="circle-filled right group-circle" />
                                    :
                                    <span onClick={() => this.props.onAddGroup(groups[i].id)} className="circle right group-circle" />
                            }
                        </Col>

                    </Row>

                )}
            </React.Fragment>
        );
    }

    setViewedSubgroup = (subgroups, parentName) => {
        let path = this.state.path;
        let exploreGroupsToShow = [...this.state.exploreGroupsToShow];
        exploreGroupsToShow.push(subgroups);
        path.push(parentName);
        this.setState({exploreGroupsToShow, path});
    }

    renderExploreGroups = (exploreGroups, exploreGroupsOfCapsule) => {
        return (
            <React.Fragment>
                <Row className="group-element">
                    <Col className="group-list-divider letter-line"><span>{this.publicDirectoryText}</span></Col>
                </Row>
                { Object.keys(exploreGroups).map((i) =>
                    <React.Fragment  key={i} >
                        <Row className="group-element text-secondary">

                            <Col xs="8" sm="8" md="8" lg="8">
                                {exploreGroups[i].name}
                            </Col>

                            <Col xs="2" sm="2" md="2" lg="2">
                                {exploreGroups[i].child && exploreGroups[i].child.length > 0 &&
                                    <img src={backButton} onClick={() => this.setViewedSubgroup(exploreGroups[i].child, exploreGroups[i].name)} className={this.state.subgroupStates[i] ? 'hover opened subgroup-button' : 'hover subgroup-button'}/>
                                }
                            </Col>

                            <Col xs="2" sm="2" md="2" lg="2" className="right">
                                {
                                    exploreGroupsOfCapsule && exploreGroupsOfCapsule.indexOf(exploreGroups[i].id) > -1 ?
                                        <span onClick={() => this.props.onRemoveGroup(exploreGroups[i].id, true)} className="pl-2 circle-filled right group-circle" />
                                        :
                                        <span onClick={() => this.props.onAddGroup(exploreGroups[i].id, true)} className="pl-2 circle right group-circle" />
                                }
                            </Col>

                        </Row>
                        <Collapse isOpen={this.state.subgroupStates[i]}>
                            {this.getSubgroupsOfGroup(exploreGroups[i], exploreGroupsOfCapsule)}
                        </Collapse>
                    </React.Fragment>

                )}
            </React.Fragment>
        );
    }

    setPublic = (value) => {
        if (value === false) {
            this.navigateBack(0);
        }

        this.setState({isPublic: value});
    }

    navigateBack = (i) => {
        let exploreGroupsToShow = [...this.state.exploreGroupsToShow];
        let path = [...this.state.path];

        path = path.splice(0, parseInt(i)+1);
        exploreGroupsToShow = exploreGroupsToShow.splice(0, parseInt(i)+1);

        this.setState({exploreGroupsToShow, path});
    }

    getPath = () => {
        let path = this.state.path;
        let pathToShow = '';
        if (path.length <= 1) {
            pathToShow = null;
        } else {
            pathToShow = (
                <React.Fragment>
                    <Row className="align-items-center">
                        <Col className="text-grey">
                            {Object.keys(path).map((i) => (
                                <React.Fragment key={i}>
                                    {i == 0 ? <span><img className="back-to-top-button" onClick={() => this.navigateBack(i)} src={backButton}/></span>
                                        :
                                        <React.Fragment>
                                            {i > 1 && <img className="path-arrow" src={backButton}/>}
                                            <span onClick={() => this.navigateBack(i)} className="navigation-button">{path[i]}</span>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            ))
                            }
                        </Col>
                    </Row>
                </React.Fragment>
            );
        }

        return (<span>{pathToShow}</span>);

    }

    renderGroups = () => {
        let groups = this.props.groups;
        let groupsOfCapsule = this.props.groupsOfCapsule;
        let exploreGroups = this.state.exploreGroupsToShow ? this.state.exploreGroupsToShow[this.state.exploreGroupsToShow.length-1] : [];
        let exploreGroupsOfCapsule = this.props.exploreGroupsOfCapsule;
        if (groups && groups.length > 0) {
            return (
                <Container className="group-list">
                    <div>{this.getPath()}</div>
                    <div className="group-edit-container">
                        {!this.state.isPublic ? this.renderUserGroups(groups, groupsOfCapsule) : this.renderExploreGroups(exploreGroups, exploreGroupsOfCapsule)}

                    </div>
                    <Row className="text-center button-row text-secondary">
                        <Col>
                            <button type="button" onClick={() => {this.props.onContinue(this.props.isExploreGroups); this.setPublic(false);}} className="btn btn-light create-button btn-sm">{this.selectText}</button>
                        </Col>
                        <Col >
                            <button type="button" onClick={() => {this.props.onCancel(this.props.isExploreGroups); this.setPublic(false);}} className="btn btn-light create-button btn-sm">{this.cancelText}</button>
                        </Col>
                    </Row>

                </Container>
            );
        } else {
            return null;
        }
    }

    renderLoggedOut = () => {
        return (

            <Container>
                <Row className="align-items-center text-secondary">
                    <Col className="text-center">
                        {this.loggedOutText}
                    </Col>
                </Row>
                <Row className="text-center button-row text-secondary">
                    <Col>
                        <button type="button" onClick={() => this.props.onContinue()} className="btn btn-light create-button btn-sm">{this.okText}</button>
                    </Col>
                </Row>
            </Container>

        );
    }

    renderGoogleSync = () => {
        return (

            <Container>
                <Row className="align-items-center text-secondary">
                    <Col className="text-center">
                        {this.props.title}
                    </Col>
                </Row>
                <Row className="text-center button-row text-secondary">
                    <Col>
                        <button type="button" onClick={() => this.props.onContinue()} className="btn btn-light create-button btn-sm">{this.okText}</button>
                    </Col>
                </Row>
            </Container>

        );
    }

    renderCreateGroup = () => {
        return (
            <Container>
                <Row className="align-items-center">
                    <Col>
                        <input
                            value={this.state.groupName}
                            onChange={(e) => this.setState({groupName: e.target.value})}
                            name="groupName"
                            id="groupName"
                            placeholder="Group Name"
                            className="capsule-input"
                        />
                    </Col>
                </Row>
                <Row className="text-center button-row">
                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            onClick={() => {this.props.onCreate(this.state.groupName); this.setState({isCreate: false, groupName: ''}); }}>{this.createPrivateText}</button>
                    </Col>
                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            onClick={() => {this.setState({isCreate: false});}}>{this.cancelText}</button>
                    </Col>
                </Row>
            </Container>
        );
    }

    renderUploadInProgress = () => {
        return (
            <Container>
                <Row className="align-items-center">
                    <Col className="text-secondary">
                        {this.waitForUploadText}
                    </Col>
                </Row>
                <Row className="text-center button-row">
                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            onClick={() => this.props.toggle()}>{this.okText}</button>
                    </Col>
                </Row>
            </Container>);
    }

    renderImageProcessing = () => {
        return (
            <Container>
                <Row className="align-items-center">
                    <Col className="text-secondary">
                        {this.waitForProcessText}
                    </Col>
                </Row>
                <Row className="text-center button-row">
                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            onClick={() => this.props.toggle()}>{this.okText}</button>
                    </Col>
                </Row>
            </Container>);
    }

    renderGroupShared = () => {
        return (
            <Container>
                <Row className="align-items-center">
                    <Col className="text-secondary">
                        {this.groupShared}
                    </Col>
                </Row>
                <Row className="text-center button-row">
                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            onClick={() => this.props.onContinue()}>{this.okText}</button>
                    </Col>
                </Row>
            </Container>);
    }

    renderNoPublicModal = () => {
        return (
            <Container>
                <Row className="align-items-center">
                    <Col className="text-secondary text-center">
                        {this.contactWithMyu}
                    </Col>
                </Row>
                <Row className="text-center button-row">
                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            onClick={() => this.props.toggle()}>{this.okText}</button>
                    </Col>
                </Row>
            </Container>);
    }

    renderCopiedToClipboard = () => {
        return (
            <div className="text-secondary text-center">
                {this.copiedToClipboard}
            </div>);
    }

    renderSelectModal = () => {
        return (
            <Container>
                <Row className="align-items-center">
                    <Col className="text-secondary text-center">
                        {
                            Object.keys(this.props.listOfLinks).map((i) => (
                                <div key={i}><a href={this.props.listOfLinks[i].link}>{this.props.listOfLinks[i].value}</a></div>
                            ))
                        }
                    </Col>
                </Row>
                <Row className="text-center button-row">
                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            onClick={() => this.props.toggle()}>{this.cancelText}</button>
                    </Col>
                </Row>
            </Container>);
    }

    renderNewEmail = () => {
        return (
            <Container>
                <Row className="align-items-center">
                    <Col className="text-secondary">
                        {this.emailSentText}
                    </Col>
                </Row>
                <Row className="text-center button-row">
                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            onClick={() => this.props.toggle()}>{this.okText}</button>
                    </Col>
                </Row>
            </Container>);
    }

    renderSaveNote = () => {
        return (
            <Container>
                <Row className="align-items-center">
                    <Col className="text-secondary mb-10 text-center">
                        <div>{this.youCanSavePartOneText}</div>
                        <div>{this.youCanSavePartTwoText}</div>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col>
                        <FormGroup check>
                            <Input type="checkbox" value={this.state.showAgain} onChange={() => this.setState({showAgain: !this.state.showAgain})} name="check" id="exampleCheck"/>
                            <Label for="exampleCheck" className="text-secondary" check>{this.dontShowAgainText}</Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="text-center button-row">
                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            onClick={() => this.props.onContinue(this.state.showAgain)}>{this.okText}</button>
                    </Col>
                </Row>
            </Container>);
    }

    renderNewPhone = () => {
        return (
            <React.Fragment>
                {!this.props.verified ?
                    <Container>
                        <Row className="align-items-center">
                            <Col>{this.verifyPinText}</Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col>
                                <input
                                    value={this.props.defaultValue}
                                    onChange={(e) => this.props.onChange(e)}
                                    name="pin"
                                    id="pin"
                                    placeholder="PIN"
                                    className="capsule-input"
                                />
                                {this.props.isInvalid && <span className="error-msg">{this.invalidPin}</span>}
                            </Col>
                        </Row>
                        <Row className="text-center button-row">
                            <Col>
                                <button type="button" className="btn btn-light create-button btn-sm"
                                    onClick={() => this.props.onContinue()}>{this.verifyText}</button>
                            </Col>
                            <Col>
                                <button type="button" className="btn btn-light create-button btn-sm"
                                    onClick={() => this.props.toggle()}>{this.cancelText}</button>
                            </Col>
                        </Row>
                    </Container>
                    :
                    <Container>
                        <Row className="align-items-center">
                            <Col className="text-secondary">{this.pinVerifiedText}</Col>
                        </Row>
                        <Row className="text-center button-row">
                            <Col>
                                <button type="button" className="btn btn-light create-button btn-sm"
                                    onClick={() => this.props.toggle()}>{this.okText}</button>
                            </Col>
                        </Row>
                    </Container>
                }
            </React.Fragment>

        );
    }

    renderPublicPageModal = () => {
        return (
            <Container>
                <Row className="align-items-center">
                    <Col className="text-secondary text-center">
                        {this.shareIsPublicPage}
                    </Col>
                </Row>
                <Row className="text-center button-row">
                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            onClick={() => this.props.onContinue()}>{this.goToPublic}</button>
                    </Col>
                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            onClick={() => this.props.toggle()}>{this.viewCapsule}</button>
                    </Col>
                </Row>
            </Container>);
    }

    renderShareBack = () => {
        return (
            <ShareBackView
                user={this.props.user}
                capsules={this.props.capsules}
                dispatch={this.props.dispatch}
                toggle={this.props.toggle}
            />
        );
    }

    renderShareBackCapsule = () => {
        return (
            <ShareBackCapsuleView
                user={this.props.user}
                capsules={this.props.capsules}
                dispatch={this.props.dispatch}
                toggle={this.props.toggle}
            />
        )
        ;}

    renderSendGridForm = () => {
        return (
            <SendGridView
                toggle={this.props.toggle}
                groups={this.props.groups}
                dispatch={this.props.dispatch}
                user={this.props.user}
                capsules={this.props.capsules}
            />
        );
    }

    renderSendGrind = () => {
        return (
            <Container className="h-100">
                {this.getIframe('https://cdn.forms-content.sg-form.com/0aaadf90-b28d-11e9-b0b5-8a26fb499b92')}
            </Container>);
    }

    getIframe = (url) => {

        if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)) {
            return <iframe id="iframe"
                src={url}
                frameBorder="0"
                height={'100vh'}
                sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-top-navigation"
                allow="encrypted-media *"
                className="ios-frame"

            />;
        } else {
            return <Iframe id="iframe"
                url={url}
                styles="height: 100%"
                display="initial"
                allow="encrypted-media *"
                sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-top-navigation"
                frameBorder="0"
            />;
        }
    }

    render() {
        let content = null;
        let header = null;
        switch (this.props.modalType) {
            case 'delete':
                content = this.renderDelete();
                break;
            case 'back':
                content = this.renderBack();
                break;
            case 'media':
                if (this.state.isOptions === true) {
                    content = this.renderOptions();
                } else {
                    content = this.renderUrlPicker();
                }
                break;
            case 'groups':
                if (this.state.isCreate) {
                    content = this.renderCreateGroup();
                } else {
                    content = this.renderGroups();
                }
                break;
            case 'account':
                content = this.renderUserChanged();
                break;
            case 'exploreGroup':
                content = this.renderSetPublic();
                break;
            case 'loggedOut':
                content = this.renderLoggedOut();
                break;
            case 'privateCapsules':
                content = this.renderPrivateCapsules();
                break;
            case 'publicCapsules':
                content = this.renderPublicCapsules();
                break;
            case 'removeFromGroup':
                content = this.renderRemoveFromGroup();
                break;
            case 'uploadInProgress':
                content = this.renderUploadInProgress();
                break;
            case 'saveNote':
                content = this.renderSaveNote();
                break;
            case 'imageProcessing':
                content = this.renderImageProcessing();
                break;
            case 'publicToPrivate':
                content = this.renderPublicToPrivate();
                break;
            case 'noExploreGroups':
                content = this.renderNoExploreGroups();
                break;
            case 'newEmail':
                content = this.renderNewEmail();
                break;
            case 'newPhone':
                content = this.renderNewPhone();
                break;
            case 'copy':
                content = this.renderCopiedToClipboard();
                break;
            case 'select':
                content = this.renderSelectModal();
                break;
            case 'noPublic':
                content = this.renderNoPublicModal();
                break;
            case 'signUp':
                content = this.renderSendGrind();
                break;
            case 'share-back':
                content = this.renderShareBack();
                break;
            case 'shareBackCapsule':
                content = this.renderShareBackCapsule();
                break;
            case 'sendGrid':
                content = this.renderSendGridForm();
                break;
            case 'publicPage':
                content = this.renderPublicPageModal();
                break;
            case 'syncModal':
                content = this.renderGoogleSync();
                break;
            case 'groupShared':
                content = this.renderGroupShared();

        }
        if (this.props.modalType === 'groups' && !this.state.isCreate) {
            header = (
                <Row className={'modal-headers pl-5 pt-3 pb-3'}>
                    <Col xs="3" sm="3" md="3" lg="3"
                        onClick={() => this.setPublic(false)}
                        className={!this.state.isPublic ? 'group-active group-switcher private' : 'group-switcher private'}>
                        {this.privateText}
                    </Col>
                    <Col xs="3" sm="3" md="3" lg="3"
                        onClick={() => this.setPublic(true)}
                        className={this.state.isPublic ? 'group-active group-switcher' : 'group-switcher'}>
                        {this.publicText}
                    </Col>

                    <Col xs="6" sm="6" md="6" lg="6" className="col-auto text-center">
                        {!this.state.isPublic && <span className="navigation-button" onClick={() => this.setState({isCreate: true})}>Create Group</span>}
                    </Col>

                </Row>
            );
        } else if (this.props.modalType === 'copy') {
            header = null;
        } else {
            header = (
                <ModalHeader
                    className={this.props.modalType === 'media' ? 'modal-headers' : 'modal-headers text-center'}
                    toggle={this.props.modalType === 'media' ? this.toggle : null}>

                    {this.props.title}

                </ModalHeader>
            );
        }
        let className = '';
        let size = '';
        if (this.props.modalType === 'copy') {
            className += 'modal-copy ';
            size = 'sm';
        }

        if (this.props.modalType === 'signUp') {
            className += 'full-height-modal';
        }

        if (this.props.modalType === 'signUp') {
            size = 'lg';
        }

        return (
            <div>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.toggle}
                    size={size}
                    className={className}
                    scrollable={true}
                    dialogClassName="full-height-modal"
                    backdrop={this.props.modalType === 'groups' ? 'static' : true}>
                    {header}
                    {content}
                </Modal>
            </div>

        );
    }

}
ModalView.propTypes = {
    isOpen:                 PropTypes.bool,
    onContinue:             PropTypes.func,
    isRemove:               PropTypes.bool,
    url:                    PropTypes.string,
    onChange:               PropTypes.func,
    onCancel:               PropTypes.func,
    toggle:                 PropTypes.func,
    onUpload:               PropTypes.func,
    defaultValue:           PropTypes.string,
    type:                   PropTypes.string,
    title:                  PropTypes.string,
    isDelete:               PropTypes.bool,
    modalType:              PropTypes.string,
    groups:                 PropTypes.array,
    groupsOfCapsule:        PropTypes.array,
    onAddGroup:             PropTypes.func,
    onRemoveGroup:          PropTypes.func,
    onCreate:               PropTypes.func,
    exploreGroupsOfCapsule: PropTypes.array,
    exploreGroups:          PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    isExploreGroups:        PropTypes.bool,
    isProfile:              PropTypes.bool,
    verified:               PropTypes.bool,
    isInvalid:              PropTypes.bool,
    typeOfSelect:           PropTypes.string,
    listOfLinks:            PropTypes.array,
    dispatch:               PropTypes.func,
    user:                   PropTypes.object,
    capsules:               PropTypes.object

};
