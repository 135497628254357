import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import './CapsuleView.css';
import { MediaView } from './MediaView';
//import verticalDivider from '../../assets/images/VerticalDivider@3x.png';
import mediaIcon from '../../assets/images/MediaIcon@3x.png';
import connectIcon from '../../assets/images/ConnectIcon.png';
import vCardIcon from '../../assets/images/vCardIcon.png';
//import {Wrap} from '../wrap/Wrap';
import backButton from '../../assets/images/Close@2x.png';
import {AddCardView} from './AddCardView';
import { ShareView } from './ShareView';
import { ChatbotView } from './ChatbotView';
import { getUrlAndLicence, getVideoThumbnail } from '../../utils/VieroUtils';
import VideoPlayer from '../video/VideoPlayer';
import {VcardView} from './VcardView';
import {ConnectView} from './ConnectView';
import { isObjectExists, isWebsiteSocial, getUserTokenFromStorage } from '../../utils/Helpers';
import SocialView from '../socialView/SocialView';
import {ModalView} from '../profile/ModalView';

export class CapsuleView extends Component {
    static defaultProps = {
        updateParentStateFunc: function() {}
    };

    constructor (props) {
        super(props);
        this.state = {
            cardAdded:        false,
            listenerSet:      false,
            isScrollable:     false,
            isConnect:        this.getIfConnect(),
            footerVisible:    true,
            shareBackModal:   false,
            capsuleListModal: false
        };
        this.videoPlayer;
    }

    componentDidMount() {
        document.documentElement.style.setProperty('position', 'fixed');
        // Works for everything except Safari. Safari won't allow going to full screen without user interaction
        let video = document.getElementById('video-background');
        window.addEventListener('orientationchange', () => {
            if (video) {
                if (window.orientation == 90 || window.orientation == -90) {
                    // We are in portrait, request full screen
                    video.requestFullscreen().catch(() => {
                        // Ignore for now.
                    });
                }
            }
        });
        window.addEventListener('message', (e) => {
            if (e.data === 'up' && this.state.footerVisible === false) {
                this.setState({footerVisible: true});
            }

            if (e.data === 'down' && this.state.footerVisible === true) {
                this.setState({footerVisible: false});
            }
        });
        this.checkIfScrollable();
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.footerVisible === true && this.props.websiteUrl && isWebsiteSocial(this.props.websiteUrl) === false && this.props.viewToShow === '' && this.props.capsuleToShow) {
            /*this.typingTimer = setTimeout(() => {
                if (this.props.websiteUrl && this.props.viewToShow === '') {
                    this.setState({footerVisible: false});
                }
            }, 5000);*/
        }

        if (prevProps.capsuleToShow && prevProps.capsuleToShow.id !== this.props.capsuleToShow.id) {
            this.setState({isConnect: this.getIfConnect()});
            clearTimeout(this.typingTimer);
        }

        if (this.props.viewToShow !== 'chatbotView' && localStorage.getItem('fanbotWebChatClientId')) {
            this.clearBot();
        }

        /*if (prevProps.fullscreenCapsule === true && this.props.fullscreenCapsule === false) {
            clearTimeout(this.typingTimer);
            this.setState({footerVisible: true});

        }*/

        if (prevState.footerVisible === false && this.state.footerVisible === true) {
            this.expandSection(document.getElementById('capsule-options'));
        }

        if (prevState.footerVisible === true && this.state.footerVisible === false) {
            this.collapseSection(document.getElementById('capsule-options'));
        }

        if (document.getElementById('capsule-header') && document.getElementById('capsule-options') && document.getElementById('capsule-view') && document.getElementById('content-container') && this.props.websiteUrl && this.props.viewToShow === '') {
            let contentHeight =  document.getElementById('capsule-view').clientHeight - (document.getElementById('capsule-header').clientHeight + document.getElementById('capsule-options').clientHeight);

            document.getElementById('content-container').style.height= contentHeight + 'px';

        }

        if (!document.getElementById('capsule-header') && document.getElementById('capsule-options') && document.getElementById('capsule-view') && document.getElementById('content-container')) {
            let contentHeight =  document.getElementById('capsule-view').clientHeight -  document.getElementById('capsule-options').clientHeight;

            document.getElementById('content-container').style.height= contentHeight + 'px';

        }

        if (document.getElementById('capsule-header') && document.getElementById('capsule-view') && document.getElementById('chat-bot-content-container')) {
            let chatbotHeight =  document.getElementById('capsule-view').clientHeight - document.getElementById('capsule-header').clientHeight;
            if (document.getElementById('multichat-chat-box')) {
                document.getElementById('multichat-chat-box').style.height= chatbotHeight + 'px';
                document.getElementById('multichat-chat-box').style.width= document.getElementById('capsule-width').clientWidth + 'px';
            }
        }
    }

    goFullScreen = () => {
        //document.getElementById('video').controls = true;
        //document.getElementById('video').load();

    }

    getIfConnect = () => {
        let result = false;
        let chatbotCapsules = this.props.config.chatBotConfig;
        if (isObjectExists(this.props, 'capsuleToShow.id')) {
            for (let i in chatbotCapsules) {
                if (this.props.capsuleToShow.id === chatbotCapsules[i].capsuleId) {
                    result = true;
                }
            }
        }
        return result;
    }

    checkIfScrollable = () => {
        if (document.getElementById('capsule-view')) {
            if (document.getElementById('capsule-view').scrollHeight > document.getElementById('capsule-view').clientHeight && !this.state.isScrollable) {
                this.setState({isScrollable: true});
            }

            if (document.getElementById('capsule-view').scrollHeight === document.getElementById('capsule-view').clientHeight && this.state.isScrollable) {
                this.setState({isScrollable: false});
            }
        }
    }

    getMuteState = () => {
        return (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) ? 'muted' : '';
    }

    isUsersCapsule = () => {
        let result = false;

        if (this.props.user.id === this.props.capsuleToShow.ownerId && this.props.isFromGroups) {
            result = true;
        }

        return result;
    }

    // This is the important part!

collapseSection = (element) => {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;

    // temporarily disable all css transitions
    var elementTransition = element.style.transition;
    element.style.transition = '';

    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we
    // aren't transitioning out of 'auto'
    requestAnimationFrame(function() {
        element.style.height = sectionHeight + 'px';
        element.style.transition = elementTransition;

        // on the next frame (as soon as the previous style change has taken effect),
        // have the element transition to height: 0
        requestAnimationFrame(function() {
            element.style.height = 0 + 'px';
        });
    });

}

  expandSection = (element) => {
      // get the height of the element's inner content, regardless of its actual size
      var sectionHeight = element.scrollHeight;

      // have the element transition to the height of its inner content
      element.style.height = sectionHeight + 'px';

      // when the next css transition finishes (which should be the one we just triggered)
      element.addEventListener('transitionend', function remove() {
      // remove this event listener so it only gets triggered once
          element.removeEventListener('transitionend', remove);

          // remove "height" from the element's inline styles, so it can return to its initial value
          //element.style.height = null;
      });

  }

  openForm = () => {
      this.setState({shareBackModal: !this.state.shareBackModal});
  }

  openCapsuleList = () => {
      this.setState({capsuleListModal: !this.state.capsuleListModal});
  }

    renderCapsuleFooter = (isWebsite) => {

        let hideArrow = (

            <React.Fragment>
                <Row className="text-center">
                    <Col>
                        {this.state.footerVisible ?

                            <img onClick={() => this.setState({footerVisible: !this.state.footerVisible})} src={backButton} className="footer-arrow opened black-arrow hover" />
                            :
                            <img onClick={() => this.setState({footerVisible: !this.state.footerVisible})} src={backButton} className="footer-arrow closed black-arrow hover" />
                        }

                    </Col>

                </Row>

            </React.Fragment>

        );

        let footer = (
            <Row id='capsule-options' className={isWebsite ? 'capsule-options-transform' : 'capsule-options bg-white'}>
                {-1 !== window.location.href.indexOf('form=') && this.props.capsuleToShow.ownerId !== this.props.user.id &&
                <React.Fragment>
                    <ModalView isOpen={this.state.shareBackModal}
                        toggle={() => this.openForm()}
                        onContinue={() => this.openForm()}
                        title={'Share Back'}
                        modalType="share-back"
                        user={this.props.user}
                        dispatch={this.props.dispatch}
                        capsules={this.props.capsules}
                    />
                    <ModalView isOpen={this.state.capsuleListModal}
                        toggle={() => this.openCapsuleList()}
                        onContinue={() => this.openCapsuleList()}
                        title={'Share Back'}
                        modalType="shareBackCapsule"
                        user={this.props.user}
                        dispatch={this.props.dispatch}
                        capsules={this.props.capsules}
                    />
                    <Col className="mb-4" xs="12" sm="12" md="12" lg="12">
                        <span className="navigation-button" onClick={() => this.openForm()}>Share back your information </span>
                     or
                        <span className="navigation-button"  onClick={() => this.openCapsuleList()}> one of your capsules</span></Col>
                </React.Fragment>
                }
                <Col  xs="4" sm="4" md="4" lg="4" className={this.props.viewToShow === 'vCard' ? 'selected capsule-option p-2' : 'unselected p-2'} onClick={() => this.props.onMenuChange('vCard')}>
                    <span>
                        <img className='capsule-options-img' src={vCardIcon} />
                        <div className='option-text'>Info</div>
                    </span>
                </Col>

                <Col xs="4" sm="4" md="4" lg="4" className={this.props.viewToShow === 'capsuleList' ? 'selected capsule-footer-option p-2' : 'unselected capsule-footer-option p-2'} onClick={() => this.props.onMenuChange('capsuleList')}>
                    <span>
                        <img className={'capsule-options-img'}src={mediaIcon} />
                        <div className={'option-text'}>Media</div>
                    </span>
                </Col>
            
                <Col xs="4" sm="4" md="4" lg="4" 
                    className={this.props.viewToShow === 'connectView' || this.props.viewToShow === 'chatbotView' || this.props.viewToShow === 'shareView' ? 'p-2 selected' : 'p-2 unselected'}
                    onClick={this.props.isEdit === false ? null : () => this.props.onMenuChange('connectView')}
                >
                    <span>
                        <img className={'capsule-options-img'} src={connectIcon}/>
                        <div className={'option-text hover'}>Connect</div>
                    </span>
                </Col>
            </Row>
        );

        if (isWebsite) {
            return (
                <div className={'capsule-options'}>
                    {hideArrow}
                    {footer}

                </div>
            );
        } else {
            return footer;
        }

    }

    clearBot = () => {
        localStorage.removeItem('fanbotWebChatClientId');
    }

    renderCapsuleHeader = (isClose, isListing) => {
        let closeButton = (
            <div className="closeButton navigation-button" onClick={() => this.props.onMenuChange('')}>Close</div>
        );

        if (isListing && !this.isUsersCapsule()) {
            closeButton = null;
        } else if (isListing && this.isUsersCapsule()) {
            closeButton = null;
        } else if (this.props.viewToShow === 'shareView' && this.state.isConnect || this.props.viewToShow === 'chatbotView') {
            closeButton = <div className="closeButton navigation-button" onClick={() => {this.props.onMenuChange('connectView'); this.clearBot();}}>Close</div>;
        } else if (this.props.viewToShow === 'shareView') {
            closeButton = <div className="closeButton navigation-button" onClick={() => {this.props.onMenuChange('');}}>Close</div>;
        }

        return (
            <Row id="capsule-header" className={this.props.viewToShow === 'chatbotView' ? 'capsule-header linear p-3' : 'capsule-header linear pt-3'}>
                <Col className="" xs="10" sm="10" md="10" lg="10" xl="10">
                    {
                        this.props.getThumbnailPicture(this.props.capsuleToShow, false, 'capsule-pic')
                    }
                    <div><span className="capsule-name">{this.props.capsuleToShow.name}</span></div>

                    {
                        this.props.capsuleToShow.ownerId ?
                            isObjectExists(this.props, 'capsuleToShow.items.contact.organisation.organizationName') && this.props.capsuleToShow.items.contact.organisation.organizationName !== '' && <div><span className="category">{this.props.capsuleToShow.items.contact.organisation.organizationName}</span></div>
                            :
                            this.props.capsuleToShow.category !== '' && <div><span className="category">{this.props.capsuleToShow.category}</span></div>
                    }
                </Col>
                        
                {isClose &&
                    <React.Fragment>
                        <Col xs="2" sm="2" md="2" lg="2" xl="2" className="right">
                            {closeButton}
                        </Col>
                    </React.Fragment>
                }
            </Row>
        );
    }

    getVideoBackground = (profile) => {
        let src, keySystems, isViero, appleTech;

        if (isObjectExists(profile, 'metadata.storageType') && profile.metadata.storageType === 'viero') {
            let videoParameters = getUrlAndLicence(profile.metadata.storageUUID, false, '300,fit', 'VieroToolkitUtilsTypeMotion');
            src = videoParameters.url;
            keySystems = videoParameters.keySystems;
            appleTech = videoParameters.appleTech;
            isViero = true;
        } else {
            src = process.env.REACT_APP_API_BASE_URI + '/cdn/files/' +
            profile.fileId
            + '.' + ((profile.metadata.mimeType.split('/')[1] === 'mpeg') ? 'mov' : profile.metadata.mimeType.split('/')[1])
            + '?token=' + getUserTokenFromStorage();
        }

        return <VideoPlayer
            url={src}
            appleTech={appleTech}
            id={isViero ? profile.metadata.storageUUID : null}
            status={this.props.user.fireBaseStatus || false}
            poster={isViero ? getVideoThumbnail(profile.metadata.storageUUID, 0, '') : null}
            keySystems={keySystems} videoClass={this.props.isFullscreen ? '' : 'video-background'}
            type={isViero ? profile.metadata ? profile.metadata.mimeType : null : profile.metadata.mimeType}
            listenStatus={this.props.listenStatus}
            isViero={isViero}
            showControls={this.state.showControls}
            isMuted={this.props.isMuted}
            parentStateUpdater={this.props.updateParentStateFunc}
        />;
    }

    renderMainCapsule = () => {
        return (
            <React.Fragment>
                {this.props.isVideoBackground === true &&
                    isObjectExists(this.props, 'capsuleToShow.items.contact.image.profile.metadata.mimeType') && -1 === this.props.capsuleToShow.items.contact.image.profile.metadata.mimeType.indexOf('image') &&
                    this.getVideoBackground(this.props.capsuleToShow.items.contact.image.profile)

                }
                {this.props.isFromGroups ? this.renderCapsuleHeader(true, true) : this.renderCapsuleHeader(false)}
           
                {this.renderCapsuleFooter()}
            </React.Fragment>
        );
    }

    renderMediaView = (isListing) => {
        return (
            <React.Fragment>
                {this.props.viewToShow === 'capsuleList' ? this.renderCapsuleHeader(true) : this.renderCapsuleHeader(true, true)}
                <div id="content-container" className="content-container">
                    <MediaView
                        getTransformationClassBasedOnOrientation={this.props.getTransformationClassBasedOnOrientation}
                        user={this.props.user}
                        onShare={() => this.props.onMenuChange('shareView')}
                        capsuleToShow={this.props.capsuleToShow}
                        onClose={() => this.props.onMenuChange('')}
                        isListing={isListing}
                        dispatch={this.props.dispatch}
                        openEditCard={this.props.openEditCard}
                        deleteCapsule={this.props.deleteCapsule}
                        checkIfScrollable={this.checkIfScrollable}
                        listenStatus={this.props.listenStatus}
                        getStatusOfMedia={this.props.getStatusOfMedia}
                        getImageScalingParameter={this.props.getImageScalingParameter}

                    />
                    {this.renderCapsuleFooter()}
                </div>
                
            </React.Fragment>);
    }

    renderShareView = (isShareBack) => {
        return (
            <React.Fragment>
                {this.renderCapsuleHeader(true)}
                <ShareView capsuleToShow={this.props.capsuleToShow}
                    onClose={() => this.props.onMenuChange('')}
                    isPreview={this.props.isPreview}
                    isFromGroups={this.props.isFromGroups}
                    isShareBack={isShareBack}
                />
                {this.renderCapsuleFooter()}
            </React.Fragment>);
    }

    renderVcardView = () => {

        return (
            <React.Fragment>
                {this.props.viewToShow === 'capsuleList' ? this.renderCapsuleHeader(true, true) : this.renderCapsuleHeader(true)}
                <div id="content-container" className="content-container">
                    <VcardView
                        getTransformationClassBasedOnOrientation={this.props.getTransformationClassBasedOnOrientation}
                        user={this.props.user}
                        onShare={() => this.props.onMenuChange('shareView')}
                        capsuleToShow={this.props.capsuleToShow}
                        onClose={() => this.props.onMenuChange('')}
                        openEditCard={this.props.openEditCard}
                        deleteCapsule={this.props.deleteCapsule}
                        onMenuChange={this.props.onMenuChange}
                        checkIfScrollable={this.checkIfScrollable}
                    />
                </div>
                {this.renderCapsuleFooter()}

            </React.Fragment>);
    }

    renderConnectView = () => {

        return (
            <React.Fragment>
                {this.renderCapsuleHeader(true)}
                <div id="content-container" className="content-container">
                    <ConnectView
                        isEdit={this.props.isEdit}
                        user={this.props.user}
                        onShare={() => this.props.onMenuChange('shareView')}
                        hasChat={this.getIfConnect()}
                        capsuleToShow={this.props.capsuleToShow}
                        onClose={() => this.props.onMenuChange('')}
                        openEditCard={this.props.openEditCard}
                        deleteCapsule={this.props.deleteCapsule}
                        onMenuChange={this.props.onMenuChange}
                        checkIfScrollable={this.checkIfScrollable}
                    />
                </div>
                {this.renderCapsuleFooter()}

            </React.Fragment>);
    }

    renderChatbotView = () => {
        return (<React.Fragment>
            {this.renderCapsuleHeader(true)}
            <ChatbotView
                isEdit={this.props.isEdit}
                user={this.props.user}
                onShare={() => this.props.onMenuChange('shareView')}
                capsuleToShow={this.props.capsuleToShow}
                onClose={() => this.props.onMenuChange('')}
                openEditCard={this.props.openEditCard}
                deleteCapsule={this.props.deleteCapsule}
                onMenuChange={this.props.onMenuChange}
                checkIfScrollable={this.checkIfScrollable}
                config={this.props.config}
            />

        </React.Fragment>);
    }

    renderAddCardView = () => {
        return (
            <React.Fragment>
                {this.renderCapsuleHeader(true)}
                <AddCardView
                    cardAdded={this.state.cardAdded}
                    changeCardAdded={this.changeCardAdded}
                    capsuleToShow={this.props.capsuleToShow}
                    onMenuChange={this.props.onMenuChange}
                    setGotGroups={this.props.setGotGroups}

                />

            </React.Fragment>);
    }

    renderCancel = () => {
        return (
            <Col className="add-card-cancel"
                onClick={this.state.cardAdded ?
                    () => {this.props.onMenuChange(''); this.changeCardAdded(false); this.props.setGotGroups(false);} :
                    () => {this.props.onMenuChange(''); this.changeCardAdded(false);}
                }
            >
                { this.state.cardAdded ? 'OK' : 'Cancel' }
            </Col>
        );
    }

    changeCardAdded = (value) => {
        this.setState({cardAdded: value});
    }

    determineContainerClass = () => {
        let classes = isWebsiteSocial(this.props.websiteUrl) === false ? 'h-100' : '';

        if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)) {
            classes += ' pos-rel';
        }

        if (this.props.websiteUrl && isWebsiteSocial(this.props.websiteUrl) !== 'twitter') {
            classes += ' overflow-hidden';
        }

        return classes;
    }

    render() {
        if (this.props.websiteUrl && this.props.viewToShow === '' && this.props.capsuleToShow) {
            return (
                <React.Fragment>
                    {/*this.props.isFromGroups ? this.renderCapsuleHeader(true, true) : this.renderCapsuleHeader(false)*/}
                    <Col className="my-auto fullscreen-button right p-left-0">
                        <span className="navigation-button" onClick={() => this.props.setFullscreen()}>{this.props.isCapsuleFullscreen ? 'Compact' : 'Fullscreen'}</span>
                    </Col>
                
                    <div id={isWebsiteSocial(this.props.websiteUrl) === false ? '' : 'content-container'} className={this.determineContainerClass()}>
                        <SocialView
                            capsules={this.props.capsules}
                            websiteUrl={this.props.websiteUrl}
                            capsuleToShow={this.props.capsuleToShow}
                            dispatch={this.props.dispatch}

                        />
                    </div>
                    {this.renderCapsuleFooter(isWebsiteSocial(this.props.websiteUrl) !== false ? false : true)}
                </React.Fragment>
            );
        } else if (this.props.capsuleToShow) {
            switch (this.props.viewToShow) {
                case 'media':
                    return this.renderMediaView();
                case 'capsuleList':
                    return this.renderMediaView(true);
                case 'vCard':
                    return this.renderVcardView();
                case 'addCard':
                    return this.renderAddCardView();
                case 'shareView':
                    return this.renderShareView();
                case 'shareBackView': 
                    return this.renderShareView(true);
                case 'connectView':
                    return this.renderConnectView();
                case 'chatbotView':
                    return this.renderChatbotView();
                default:
                    return this.renderMainCapsule();

            }
        } else {
            return null;
        }
    }

}

CapsuleView.propTypes = {
    capsuleToShow:                            PropTypes.object,
    onMenuChange:                             PropTypes.func,
    viewToShow:                               PropTypes.string,
    setGotGroups:                             PropTypes.func,
    user:                                     PropTypes.object,
    isVideoBackground:                        PropTypes.bool,
    searchInitiated:                          PropTypes.bool,
    getTransformationClassBasedOnOrientation: PropTypes.func,
    getImageFromImageAndVideos:               PropTypes.func,
    getImageResizerParameters:                PropTypes.func,
    getThumbnailPicture:                      PropTypes.func,
    backgroundLoaded:                         PropTypes.bool,
    openEditCard:                             PropTypes.func,
    deleteCapsule:                            PropTypes.func,
    isFromGroups:                             PropTypes.bool,
    isPreview:                                PropTypes.bool,
    isEdit:                                   PropTypes.bool,
    websiteUrl:                               PropTypes.string,
    dispatch:                                 PropTypes.func,
    capsules:                                 PropTypes.object,
    listenStatus:                             PropTypes.func,
    config:                                   PropTypes.object,
    getStatusOfMedia:                         PropTypes.func,
    isFullscreen:                             PropTypes.bool,
    isMuted:                                  PropTypes.bool,
    updateParentStateFunc:                    PropTypes.func,
    getImageScalingParameter:                 PropTypes.func,
    fullscreenCapsule:                        PropTypes.bool,
    setFullscreen:                            PropTypes.func,
    isCapsuleFullscreen:                      PropTypes.bool
};
