import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
var QRCode = require('qrcode.react');
import { Row, Container, Col } from 'reactstrap';
import { capsuleActions } from '../redux/actions';
import messageIcon from '../../assets/images/MessageActive@3x.png';
import messengerIcon from '../../assets/images/Messenger@3x.png';
import twitterIcon from '../../assets/images/TwitterActive@3x.png';
import './CapsuleView.css';
import { getUrlAndLicence } from '../../utils/VieroUtils';
import { isObjectExists } from '../../utils/Helpers';
import {ModalView} from '../profile/ModalView';

class ShareView extends Component {

    constructor (props) {
        super(props);
        this.state = {
            gotToken:     false,
            isShare:      false,
            copied:       false,
            shareType:    'share',
            groupId:      '',
            shareLink:    '',
            newGroupName: ''
        };
    }

    componentDidMount() {
        if (-1 !== window.location.href.indexOf('share_token') || (-1 === window.location.href.indexOf('share_token') && -1 !== window.location.href.indexOf('capsules'))) {
            this.setState({isShare: true});
        }

        if ((this.props.capsuleToShow.ownerId && this.props.user.id && this.props.capsuleToShow.ownerId === this.props.user.id) || this.props.isPreview) {
            this.props.dispatch(capsuleActions.getShareToken(this.props.capsuleToShow.id));
            this.props.isShareBack === true && this.getReverseShareLink();
        }

    }

    componentDidUpdate(prevProps) {

        let background = 'https://myuniverse.com/favicon.ico';

        if (isObjectExists(this.props, 'capsuleToShow.items.contact.image.profile.metadata.storageUUID') && this.props.capsuleToShow.items.contact.image.profile.metadata.storageUUID !== '') {
            background = getUrlAndLicence(this.props.capsuleToShow.items.contact.image.profile.metadata.storageUUID, false, '200x200', 'VieroToolkitUtilsTypeStillSingle').url;
        }

        let ogParams = '&title=' + this.props.capsuleToShow.name +
        '&description=MyUniverse capsule' +
        '&type=website' +
        '&url=' + process.env.REACT_APP_BASE_URI +
        '&image=' + background;
        if (!this.state.gotToken && this.props.capsules.shareToken && this.state.shareType === 'share') {
            this.setState({shareToken: this.props.capsules.shareToken, gotToken: true});
            window.history.pushState('', '', '/capsules/' + this.props.capsuleToShow.id + '?share_token=' + this.props.capsules.shareToken + ogParams);
        }

        if (!prevProps.capsules.groupCreated && this.props.capsules.groupCreated) {
            this.props.dispatch(capsuleActions.createShareForm(this.props.capsules.newGroup.id));
        }

    }

    componentWillUnmount() {
        if (!this.state.isShare) {
            window.history.pushState('', '', '/');
        }
    }

    copytoClipboard = (url) => {

        var textArea = document.createElement('textarea');
        textArea.value = url;

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            this.setState({copied: successful});
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }

        document.body.removeChild(textArea);
    }

    handleChange = (e) => {
        this.setState({groupId: e.target.value});
        this.props.dispatch(capsuleActions.createShareForm(e.target.value));
    }

    getReverseShareLink = () => {
        this.props.dispatch(capsuleActions.createShareForm(this.state.groupId));
    }

    createGroupAndShareBack = () => {
        if (this.state.newGroupName !== '') {
            this.props.dispatch(capsuleActions.createCapsuleGroup(this.state.newGroupName));
        }
    }

    getShareOptions = () => {
        if (this.props.capsules && this.props.capsules.groups && this.props.capsuleToShow.ownerId === this.props.user.id) {
            let groups = this.props.capsules.groups;

            return (
                <React.Fragment>
                    <Col xs="12" sm="12" md="12" lg="12" className="mt-5">
                    Select group:
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="12" >
                        <select value={this.state.group} disabled={this.state.newGroupName !== ''} onChange={this.handleChange}>
                            <option key={'sharedWithMe'} id={'sharedWithMe'} value={''}>{'Shared with Me'}</option>
                            { Object.keys(groups).map((i) => (
                                <option key={groups[i].id} id={groups[i].id} value={groups[i].id}>{groups[i].name}</option>
                            ))
                            }
                        </select>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="12" className="mt-3">
                    or create one
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="12" className="mt-3">
                        <div>
                            Group name
                        </div>
                        <input name="group-name" id="group-name" onChange={(e) => this.setState({newGroupName: e.target.value})}/>
                        <div className="mt-3"><button onClick={this.createGroupAndShareBack}>Create group and get share link</button></div>
                    </Col>

                    {/*<Col xs="12" sm="12" md="12" lg="12">
                        <span onClick={() => this.getReverseShareLink()} className="navigation-button">Get share link</span>
                    </Col>*/}
                </React.Fragment>

            );
        } else {
            return null;
        }
    }

    renderTopViewLoggedIn = () => {
        let url;
        let background = 'https://myuniverse.com/favicon.ico';

        if (isObjectExists(this.props, 'capsuleToShow.items.contact.image.profile.metadata.storageUUID') && this.props.capsuleToShow.items.contact.image.profile.metadata.storageUUID !== '') {
            background = getUrlAndLicence(this.props.capsuleToShow.items.contact.image.profile.metadata.storageUUID, false, '200x200', 'VieroToolkitUtilsTypeStillSingle').url;
        }

        let ogParams = '&title=' + this.props.capsuleToShow.name +
        '&description=MyUniverse capsule' +
        '&type=website' +
        '&url=' + process.env.REACT_APP_BASE_URI +
        '&image=' + background;

        if (this.props.capsules.formId && this.props.capsules.formId.id && this.props.capsules.formId.id !== '') {
            url = process.env.REACT_APP_BASE_URI + '/capsules/' + this.props.capsuleToShow.id + '?share_token=' + this.state.shareToken + '&form=' + this.props.capsules.formId.id;
            window.history.pushState('', '',
                '/capsules/' + this.props.capsuleToShow.id +
             '?share_token=' + this.state.shareToken +
             '&form=' + this.props.capsules.formId.id + ogParams

            );
        } else if ( -1 !== window.location.href.indexOf('share_token') && this.props.capsuleToShow.id) {
            url = process.env.REACT_APP_BASE_URI + '/capsules/' + this.props.capsuleToShow.id + '?share_token=' + this.state.shareToken + ogParams;
        } else if (-1 === window.location.href.indexOf('share_token') && !this.props.capsuleToShow.id) {
            url = process.env.REACT_APP_BASE_URI + '/capsules/x/e/' + this.props.capsuleToShow.externalSources.ElasticSearch.id;
        } else {
            url = process.env.REACT_APP_BASE_URI + '/capsules/' + this.props.capsuleToShow.id + ogParams;
        }

        return (
            <Container className="text-center">
                <ModalView isOpen={this.state.copied}
                    toggle={() => this.setState({copied: !this.state.copied})}
                    onContinue={() => this.setState({copied: !this.state.copied})}
                    modalType="copy"
                />

                <Row className="align-items-center share-margin">

                    {/* <Col xs="12" sm="12" md="12" lg="12">

                        <select value={this.state.shareType} onChange={this.handleChange}>
                            <option value="share">Simple Share</option>
                            <option value="reverse_share">Reverse share</option>
                            <option value="competition">Competition</option>
                        </select>

                    </Col>*/}

                    <Col xs="12" sm="12" md="12" lg="12">
                        {this.state.gotToken && this.state.shareToken &&
                        <QRCode className="qr-code" includeMargin={true} level="M" value={url} />
                        }

                        <div className="mt-2" onClick={() => this.copytoClipboard(url)}><span className="navigation-button">Click to copy share link</span></div>
                    </Col>
                    {this.props.isShareBack && this.getShareOptions()}

                </Row>

            </Container>
        );
    }

    renderTopView = () => {

        let url;

        if ( -1 === window.location.href.indexOf('share_token') && this.props.capsuleToShow.id) {
            url = process.env.REACT_APP_BASE_URI + '/capsules/' + this.props.capsuleToShow.id;
        } else if (-1 === window.location.href.indexOf('share_token') && !this.props.capsuleToShow.id) {
            url = process.env.REACT_APP_BASE_URI + '/capsules/x/e/' + this.props.capsuleToShow.externalSources.ElasticSearch.id;
        } else {
            url = process.env.REACT_APP_BASE_URI + '/capsules/' + this.props.capsuleToShow.id + '?share_token=' + this.state.shareToken;
        }
        return (
            <Container className="text-center">
                <ModalView isOpen={this.state.copied}
                    toggle={() => this.setState({copied: !this.state.copied})}
                    onContinue={() => this.setState({copied: !this.state.copied})}
                    modalType="copy"
                />

                <Row className="align-items-center share-margin">
                    <Col>
                        <QRCode className="qr-code" includeMargin={true} level="M" value={url} />
                        <div className="mt-2" onClick={() => this.copytoClipboard(url)}><span className="navigation-button">Click to copy share link</span></div>

                    </Col>
                </Row>
            </Container>
        );
    }

    renderBottomView = () => {
        return (
            <React.Fragment>
                <Row className="capsule-view bottom-view">
                    <Col>

                        <Row>
                            <Col>
                                <img className="share-image" src={messageIcon} />
                                <div>iOS</div>
                            </Col>
                            <Col>
                                <img className="share-image"/>
                                <div>E-Mail</div>
                            </Col>
                            <Col>
                                <img className="share-image" src={twitterIcon} />
                                <div>Twitter</div>
                            </Col>
                            <Col className="messenger-column">
                                <img className="share-image messenger" src={messengerIcon} />
                                <div>Messenger</div>
                            </Col>
                        </Row>

                    </Col>

                    <Col lg="12" className="share-cancel" onClick={() => this.props.onMenuChange('')}>
                        <div>Cancel</div>
                    </Col>

                </Row>

            </React.Fragment>

        );
    }

    render() {
        if ((this.state.gotToken && this.state.shareToken) || -1 !== window.location.href.indexOf('share_token')) {
            return this.renderTopViewLoggedIn();
        } else {
            return this.renderTopView();
        }

    }

}

ShareView.propTypes = {
    dispatch:      PropTypes.func,
    render:        PropTypes.string,
    capsules:      PropTypes.object,
    user:          PropTypes.object,
    capsuleToShow: PropTypes.object,
    onMenuChange:  PropTypes.func,
    isPreview:     PropTypes.bool,
    isShareBack:   PropTypes.bool
};

function mapStateToProps(state) {
    const { capsules, user } = state;
    return {
        capsules,
        user
    };
}

const connectedShareViewPage = connect(mapStateToProps)(ShareView);
export { connectedShareViewPage as ShareView };
