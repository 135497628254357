import { sortAlphabetically, mergeSearchResults, removeCapsule } from '../../../utils/Helpers';

export function capsules(state = {}, action) {
    switch (action.type) {
        case 'GET_PUBLIC_CAPSULE_REQUEST':
        case 'SEARCH_CAPSULE_REQUEST':
            var searchResults = state.searchResults ? state.searchResults : [];

            return {
                ...state,
                searchResults:      action.isPagination ? searchResults : [],
                loaded:             action.isPagination ? true : false,
                capsulesByGroup:    state.capsulesByGroup ? state.capsulesByGroup : [],
                loading:            true,
                shareTokenConsumed: false,
                capsule:            [],
                error:              {},
                capsuleType:        action.capsuleType
            };
        case 'GET_CAPSULE_BY_ID_REQUEST':
        case 'GET_USER_CAPSULE_EXPLORE_GROUP_REQUEST':
            return {
                ...state,
                //capsule:                 [],
                loaded:                  false,
                loading:                 true,
                groupLoaded:             false,
                capsuleRemovedFromGroup: false,
                capsuleAddedToGroup:     false,
                groupEdited:             false,
                shareTokenConsumed:      false,
                error:                   {}
            };
        case 'GET_CAPSULE_GROUP_BY_ID_REQUEST':
            return {
                ...state,
                //capsule:                 [],
                loaded:                  false,
                capsulesOfGroup:         [],
                loading:                 true,
                groupLoaded:             false,
                capsuleRemovedFromGroup: false,
                capsuleAddedToGroup:     false,
                groupEdited:             false,
                shareTokenConsumed:      false,
                error:                   {}
            };
        case 'GET_CAPSULE_GROUP_BY_ID_SUCCESS':
        case 'GET_USER_CAPSULE_EXPLORE_GROUP_SUCCESS':

            return {
                ...state,
                capsulesOfGroup:         [{id: action.groupId, content: sortAlphabetically(action.response)}],
                capsule:                 sortAlphabetically(action.response),
                loaded:                  true,
                groupLoaded:             true,
                loading:                 false,
                capsuleRemovedFromGroup: false,
                capsuleAddedToGroup:     false,
                groupEdited:             false,
                groupCreated:            false
            };
        case 'GET_PUBLIC_CAPSULE_SUCCESS':
            if (action.response && action.response.length > 0) {

                let result = [];
                let isMostRecent = action.isMostRecent || action.capsuleType === 'all';

                if (action.isPagination) {
                    result = isMostRecent ? state.capsule.concat(action.response) : sortAlphabetically(state.capsule.concat(action.response));
                } else {
                    result = isMostRecent ? action.response : sortAlphabetically(action.response);
                }

                return {
                    ...state,
                    capsule:            action.capsuleType === state.capsuleType ? result : sortAlphabetically(state.capsule),
                    capsulesOfGroup:    action.capsuleType === state.capsuleType ? [{id: action.capsuleType, content: result}] : [{id: action.capsuleType, content: sortAlphabetically(state.capsule)}],
                    capsulesByGroup:    addCapsulesByGroup(state.capsulesByGroup, action.capsuleType, result),
                    loaded:             true,
                    loading:            false,
                    shareTokenConsumed: false
                };
            } else {
                return {
                    ...state,
                    loaded:             true,
                    loading:            false,
                    shareTokenConsumed: false
                };
            }
        case 'SEARCH_CAPSULE_SUCCESS':
            switch (action.searchType) {
                case 'own':
                    return {
                        ...state,
                        searchResults:      action.isPagination ? state.searchResults.concat(action.response.userCapsules) : action.response.userCapsules,
                        loaded:             true,
                        loading:            false,
                        shareTokenConsumed: false
                    };
                case 'shared':
                    return {
                        ...state,
                        searchResults:      action.isPagination ? state.searchResults.concat(action.response.sharedCapsules) : action.response.sharedCapsules,
                        loaded:             true,
                        loading:            false,
                        shareTokenConsumed: false
                    };
                case 'top':
                    return {
                        ...state,
                        searchResults:      action.isPagination ? concatSearchCategories(state.searchResults, action.response): mergeSearchResults(action.response),
                        loaded:             true,
                        loading:            false,
                        shareTokenConsumed: false
                    };
                default:
                    return {
                        ...state,
                        searchResults:      action.isPagination ? state.searchResults.concat(action.response) : action.response,
                        loaded:             true,
                        loading:            false,
                        shareTokenConsumed: false
                    };

            }

        case 'GET_PUBLIC_CAPSULE_TO_LIST_SUCCESS':
            if (action.response.length > 0) {
                return {
                    ...state,
                    capsule:            action.response,
                    capsulesOfGroup:    [{id: 'all', content: sortAlphabetically(action.response)}],
                    loaded:             true,
                    loading:            false,
                    shareTokenConsumed: false
                };
            } else {
                return {
                    ...state,
                    loaded:             true,
                    loading:            false,
                    shareTokenConsumed: false
                };
            }
        case 'GET_CAPSULE_BY_ID_SUCCESS':
            return {
                ...state,
                capsule: [action.response],
                loaded:  true,
                loading: false
            };

        case 'GET_CAPSULE_GROUPS_REQUEST':
            return {
                ...state,
                groupDeleted:        false,
                capsuleAddedToGroup: false,
                error:               {}
            };
        case 'GET_CAPSULE_GROUPS_SUCCESS':
            return {
                ...state,
                groups:       mergeGroups(state.groups, action.response),
                groupDeleted: false
            };
        case 'GET_PUBLIC_CAPSULE_ERROR':
        case 'GET_CAPSULE_BY_ID_ERROR':
        case 'GET_CAPSULE_GROUPS_ERROR':
        case 'GET_CAPSULE_GROUP_BY_ID_ERROR':
        case 'GET_USER_CAPSULE_EXPLORE_GROUP_ERROR':
            return {
                ...state,
                error:                   action.error,
                loaded:                  false,
                capsuleRemovedFromGroup: false,
                groupDeleted:            false,
                capsuleAddedToGroup:     false,
                groupEdited:             false,
                shareTokenConsumed:      false
            };

        case 'CREATE_CAPSULE_GROUP_REQUEST':
            return {
                ...state,
                newGroup:     action.response,
                groupCreated: false,
                error:        {}
            };
        case 'CREATE_CAPSULE_GROUP_SUCCESS':
            var groups = state.groups;
            groups.push(action.response);
            return {
                ...state,
                groupCreated: true,
                groups:       groups,
                newGroup:     action.response
            };
        case 'CREATE_CAPSULE_GROUP_ERROR':
            return {
                ...state,
                error:        action.error,
                groupCreated: false
            };
        case 'ADD_CAPSULE_TO_GROUP_REQUEST':
            return {
                ...state,
                capsuleAddedToGroup: false,
                error:               {}
            };
        case 'ADD_CAPSULE_TO_GROUP_SUCCESS':
            return {
                ...state,
                capsuleAddedToGroup: true
            };
        case 'ADD_CAPSULE_TO_GROUP_ERROR':
            return {
                ...state,
                error:               action.error,
                capsuleAddedToGroup: false
            };
        case 'CREATE_SHARE_REQUEST':
            return {
                ...state,
                formId: '',
            };
        case 'CREATE_SHARE_SUCCESS':
            return {
                ...state,
                formId: action.response
            };
        case 'CREATE_SHARE_ERROR':
            return {
                ...state,
                error: action.error
            };
        case 'SEND_SHARE_REQUEST':
            return {
                ...state,
                shareSent: false,
            };
        case 'SEND_SHARE_SUCCESS':
            return {
                ...state,
                shareSent: true,
            };
        case 'SEND_SHARE_ERROR':
            return {
                ...state,
                shareSent: false,
                error:     action.error
            };
        case 'SEND_CAPSULE_REQUEST':
            return {
                ...state,
                shareSent: false,
            };
        case 'SEND_CAPSULE_SUCCESS':
            return {
                ...state,
                shareSent: true,
            };
        case 'SEND_CAPSULE_ERROR':
            return {
                ...state,
                shareSent: false,
                error:     action.error
            };
        case 'REMOVE_CAPSULE_FROM_GROUP_REQUEST':
            return {
                ...state,
                capsuleRemovedFromGroup: false,
                error:                   {}
            };
        case 'REMOVE_CAPSULE_FROM_GROUP_SUCCESS':
            return {
                ...state,
                capsuleRemovedFromGroup: true
            };
        case 'REMOVE_CAPSULE_FROM_GROUP_ERROR':
            return {
                ...state,
                error:                   action.error,
                capsuleRemovedFromGroup: false
            };
        case 'CHECK_IF_XFRAME_REQUEST':
            return {
                ...state,
                isXframe:        false,
                isXframeLoading: true,
                error:           {}
            };
        case 'CHECK_IF_XFRAME_SUCCESS':
            return {
                ...state,
                isXframe:        action.response,
                isXframeLoading: false
            };
        case 'CHECK_IF_XFRAME_ERROR':
            return {
                ...state,
                error:           action.error,
                isXframe:        false,
                isXframeLoading: false
            };
        case 'IMPORT_FROM_MB_REQUEST':
            return {
                ...state,
                importedCapsule:  null,
                importingCapsule: true,
                error:            {}
            };
        case 'IMPORT_FROM_MB_SUCCESS':
            for (var i in state.capsule) {
                if (state.capsule[i].externalSources.MusicBrainz.id === action.response.externalSources.MusicBrainz.id) {
                    state.capsule[i] = action.response;
                }
            }
            return {
                ...state,
                importedCapsule:  action.response,
                importingCapsule: false
            };
        case 'IMPORT_FROM_MB_ERROR':
            return {
                ...state,
                error:            action.error,
                importedCapsule:  true,
                importingCapsule: false
            };

        case 'USER_CAPSULES_TO_CAPSULE_LIST_SUCCESS':
            return {
                ...state,
                capsule:     action.response,
                groupEdited: false,
                loaded:      true
            };
        case 'LIST_TO_RESULTS_REQUEST':
            return {
                ...state,
                loaded: false
            };
        case 'LIST_TO_RESULTS_SUCCESS':
            return {
                ...state,
                searchResults: action.response,
                loaded:        true
            };
        case 'SET_USER_CAPSULE_EXPLORE_GROUP_REQUEST':
        case 'UPDATE_CAPSULE_GROUP_REQUEST':
            return {
                ...state,
                groupEdited: false,
                error:       {}
            };
        case 'SET_USER_CAPSULE_EXPLORE_GROUP_SUCCESS':
        case 'UPDATE_CAPSULE_GROUP_SUCCESS':
            return {
                ...state,
                response:        action.response,
                groupNameEdited: false,
                groupEdited:     true
            };
        case 'EDIT_GROUP_NAME_SUCCESS':
            return {
                ...state,
                response:        action.response,
                groups:          updateGroupName(action.response, state.groups),
                groupNameEdited: true,
                groupEdited:     false
            };
        case 'SET_USER_CAPSULE_EXPLORE_GROUP_ERROR':
        case 'UPDATE_CAPSULE_GROUP_ERROR':
            return {
                ...state,
                error:        action.error,
                groupEdited:  false,
                groupCreated: false
            };
        case 'CLEAR_CAPSULES_SUCCESS':
            return {
                ...state,
                capsule: []
            };

        case 'DELETE_GROUP_BY_ID_REQUEST':
            return {
                ...state,
                groupDeleted: false,
                error:        {}
            };
        case 'DELETE_GROUP_BY_ID_SUCCESS':
            return {
                ...state,
                groups:       removeGroup(action.groupId),
                groupDeleted: true

            };
        case 'DELETE_GROUP_BY_ID_ERROR':
            return {
                ...state,
                error:        action.error,
                groupDeleted: false
            };
        case 'DELETE_CAPSULE_FROM_SHARED_REQUEST':
            return {
                ...state,
                sharedDeleted: false,
                error:         {}
            };
        case 'DELETE_CAPSULE_FROM_SHARED_SUCCESS':
            return {
                ...state,
                sharedDeleted: true
            };
        case 'DELETE_CAPSULE_FROM_SHARED_ERROR':
            return {
                ...state,
                error:         action.error,
                sharedDeleted: false
            };

        case 'GET_SHARE_TOKEN_REQUEST':
            return {
                ...state,
                shareToken: '',
                formId:     '',
                error:      {}
            };
        case 'GET_SHARE_TOKEN_SUCCESS':
            return {
                ...state,
                shareToken: action.response.shareToken
            };
        case 'GET_SHARE_TOKEN_ERROR':
            return {
                ...state,
                error:      action.error,
                shareToken: ''
            };
        case 'GET_MAIN_CAPSULE_REQUEST':
            return {
                ...state,
                mainCapsuleInProgress: true,
                mainCapsule:           {}
            };
        case 'GET_MAIN_CAPSULE_SUCCESS':
            return {
                ...state,
                mainCapsuleInProgress: false,
                mainCapsule:           action.response
            };
        case 'GET_MAIN_CAPSULE_ERROR':
            return {
                ...state,
                mainCapsuleInProgress: false,
                error:                 action.error
            };
        case 'CONSUME_SHARE_TOKEN_REQUEST':
            return {
                ...state,
                shareTokenConsumed: false,
                error:              {}
            };
        case 'CONSUME_SHARE_TOKEN_SUCCESS':
            return {
                ...state,
                shareTokenConsumed: true
            };
        case 'CONSUME_SHARE_TOKEN_ERROR':
            return {
                ...state,
                error:              action.error,
                shareTokenConsumed: false
            };
        case 'GET_SHARED_CAPSULES_REQUEST':
            return {
                ...state,
                sharedCapsules: action.isPagination ? state.sharedCapsules : [],
                error:          {},
                loaded:         false
            };
        case 'GET_SHARED_CAPSULES_SUCCESS':
            var sharedCapsules = action.isPagination ? state.sharedCapsules.concat(sortAlphabetically(action.response)) : sortAlphabetically(action.response);
            return {
                ...state,
                sharedCapsules: sharedCapsules,
                loaded:         true
            };
        case 'GET_SHARED_CAPSULES_ERROR':
            return {
                ...state,
                error:          action.error,
                sharedCapsules: []
            };
        case 'GET_FEATURED_CAPSULES_REQUEST':
            return {
                ...state,
                featuredCapsules: state.featuredCapsules || [],
                featuredLoaded:   false,
                error:            {},
                loaded:           false
            };
        case 'GET_FEATURED_CAPSULES_SUCCESS':
        
            return {
                ...state,
                featuredCapsules: action.response,
                featuredLoaded:   true,
                loaded:           true
            };
        case 'GET_FEATURED_CAPSULES_ERROR':
            return {
                ...state,
                error:            action.error,
                featuredCapsules: []
            };
        case 'GET_FAVOURITE_CAPSULES_REQUEST':
            return {
                ...state,
                favourites:       state.favourites || [],
                error:            {},
                favouritesLoaded: false,
                loaded:           false
            };
        case 'GET_FAVOURITE_CAPSULES_SUCCESS':
            
            return {
                ...state,
                favourites:       action.response,
                capsule:          action.response,
                favouritesLoaded: true,
                loaded:           true
            };
        case 'GET_FAVOURITE_CAPSULE_ERROR':
            return {
                ...state,
                error:      action.error,
                favourites: []
            };
        case 'REMOVE_FAVOURITE_CAPSULE_REQUEST':
            return {
                ...state,
                loaded: false
            };
        case 'REMOVE_FAVOURITE_CAPSULE_SUCCESS':
                
            return {
                ...state,
                favourites: removeCapsule(action.id, state.favourites),
                loaded:     true
            };
        case 'REMOVE_FAVOURITE_CAPSULE_ERROR':
            return {
                ...state,
                error:      action.error,
                favourites: []
            };
        case 'ADD_FAVOURITE_CAPSULE_REQUEST':
            return {
                ...state,
                favourites: state.favourites || [],
                error:      {},
                loaded:     false
            };
        case 'ADD_FAVOURITE_CAPSULE_SUCCESS':
                        
            return {
                ...state,
                favourites: action.capsule.concat(state.favourites),
                loaded:     true
            };
        case 'ADD_FAVOURITE_CAPSULE_ERROR':
            return {
                ...state,
                error:            action.error,
                featuredCapsules: []
            };
        case 'ADD_FAVOURITE_CAPSULES_REQUEST':
            return {
                ...state,
                favourites: state.favourites || [],
                error:      {},
                loaded:     false
            };
        case 'ADD_FAVOURITE_CAPSULES_SUCCESS':
                    
            return {
                ...state,
                favourites: action.favorites,
                loaded:     true
            };
        case 'ADD_FAVOURITE_CAPSULES_ERROR':
            return {
                ...state,
                error:      action.error,
                favourites: []
            };
        case 'CLEAR_GROUPS_SUCCESS': {
            return {
                ...state,
                groups: []
            };
        }
        /* case 'GET_MOST_RECENT_COUNT_SUCCESS':
            return {
                ...state,
                mostRecentCount: action.response.length
            };
        case 'GET_MOST_RECENT_COUNT_ERROR':
            return {
                ...state,
                error: action.error
            }; */
        case 'SET_CAPSULE_TYPE_SUCCESS':
            return {
                ...state,
                capsuleType: action.type
            };
        /* case 'GET_MOST_RECENT_COUNT_REQUEST':  */
        case 'GET_EXPLORE_GROUPS_REQUEST':
            return {
                ...state,
                error: {}
            };
        case 'GET_CAPSULES_EXPLORE_GROUPS_ERROR':
        case 'GET_EXPLORE_GROUPS_ERROR':
            return {
                ...state,
                error: action.error,
            };
        case 'GET_EXPLORE_GROUPS_SUCCESS': {
            return {
                ...state,
                exploreGroups: action.response
            };
        }
        case 'GET_CAPSULES_EXPLORE_GROUPS_REQUEST':
            return {
                ...state,
                exploreGroupsCapsules: {},
                error:                 {}
            };
        case 'GET_CAPSULES_EXPLORE_GROUPS_SUCCESS': {
            return {
                ...state,
                exploreGroupCapsules: action.response
            };
        }
        case 'SET_CAPSULES_PUBLIC_REQUEST':
            return {
                ...state,
                publicSet: false,
                error:     {}
            };
        case 'SET_CAPSULES_PUBLIC_ERROR':
            return {
                ...state,
                publicSet: false,
                error:     action.error,
            };
        case 'SET_CAPSULES_PUBLIC_SUCCESS': {
            return {
                ...state,
                publicSet: true
            };
        }
        case 'CLEAR_CAPSULES_OF_GROUP':
        case 'CLEAR_GROUP_CAPSULES_SUCCESS': {
            return {
                ...state,
                capsulesOfGroup: []
            };
        }
        case 'REFRESH_CAPSULE_BY_ID_REQUEST':
            return {
                ...state,
                refreshedCapsule: {},
                refreshing:       true
            };
        case 'REFRESH_CAPSULE_BY_ID_ERROR':
            return {
                ...state,
                refreshedCapsule: {},
                error:            action.error,
            };
        case 'REFRESH_CAPSULES_SUCCESS': {
            state = refreshCapsule(action.capsule, state);
            return {
                ...state
            };
        }
        case 'REFRESH_CAPSULE_BY_ID_SUCCESS': {
            return {
                ...state,
                refreshing:       false,
                refreshedCapsule: action.response,
                capsule:          updateCapsule(action.response.id, state.capsule, action.response),
                searchResults:    updateCapsule(action.response.id, state.searchResults, action.response)

            };
        }
        case 'IMPORT_CONTACTS_REQUEST':
            return {
                ...state,
                importStatus: 'pending'
            };
        case 'IMPORT_CONTACTS_ERROR':
            return {
                ...state,
                importStatus: 'error',
                error:        action.error,
            };
        case 'IMPORT_CONTACTS_SUCCESS': {
            return {
                ...state,
                importStatus: 'done'
            };
        }
        case 'FILTER_VISIBILITY_SUCCESS': {
            return {
                ...state,
                capsules: checkVisibilityOfCapsules(state.capsules)
            };
        }
        case 'PRELOAD_CAPSULE_REQUEST':
            return {
                ...state,
                preloadedCapsule: []
            };
        case 'PRELOAD_CAPSULE_ERROR':
            return {
                ...state,
                error: action.error,
            };
        case 'PRELOAD_CAPSULE_SUCCESS': {
            return {
                ...state,
                preloadedCapsule: action.response
            };
        }
        case 'SET_GROUPS_SUCCESS': {
            return {
                ...state,
                groups:     action.response.groups,
                favourites: action.response.favorites
            };
        }
        case 'ADD_SHARED_GROUPS_SUCCESS': {
            return {
                ...state,
                groups: addToGroups(action.groups, state.groups)
            };
        }
        default:
            return state;
    }
}

function updateCapsule(id, capsules, updatedCapsule) {
    let updatedCapsuleList = capsules;
    for (let i in capsules) {
        if (capsules[i].id === id) {
            updatedCapsuleList[i] = updatedCapsule;
        }
    }
    return updatedCapsuleList;
}

function checkVisibilityOfCapsules(capsules) {
    let userCapsules = [];
    for (let i in capsules) {
        if (capsules[i].public == true) {
            userCapsules.push(capsules[i]);
        }
    }

    return userCapsules;
}

function concatSearchCategories(oldResults, newResults) {
    let result = {userCapsules: [], sharedCapsules: [], publicCapsules: []};

    if (!oldResults.userCapsules && !oldResults.sharedCapsules && !oldResults.publicCapsules) {
        result = oldResults.concat(mergeSearchResults(newResults));
    } else {
        result.userCapsules = oldResults.userCapsules.concat(newResults.userCapsules);
        result.sharedCapsules = oldResults.sharedCapsules.concat(newResults.sharedCapsules);
        result.publicCapsules = oldResults.publicCapsules.concat(newResults.publicCapsules);
    }

    return result;
}

function addToGroups(groups, list) {
    let modifiedList = list || [];

    for (let i in groups) {
        modifiedList.push({id: groups[i].id, shared: true, name: groups[i].name, ownerId: groups[i].ownerId});
    }

    return modifiedList;
}

function removeGroup(groupId, groups) {
    let modifiedGroups = [];
    for (let i in groups) {
        if (groups[i].id !== groupId) {
            modifiedGroups.push(groups[i]);
        }
    }

    return groups;

}

function addCapsulesByGroup(capsulesByGroup, id, content) {
    let list = capsulesByGroup;

    for (let i in list) {
        if (list[i].id === id) {
            list[i].content = content;
            return list;
        }
    }

    list.push({id, content});
    return list;
  
}

function updateGroupName(group, groups) {
    let modifiedGroups = groups;
    for (let i in groups) {
        if (modifiedGroups[i].id === group.id) {
            modifiedGroups[i].name = group.name;
        }
    }

    return modifiedGroups;
}

function mergeGroups(groups, stateGroups) {
    let mergedGroups = stateGroups || [];
    let groupsToAdd = groups || [];

    let isInList;
    for (let i in groupsToAdd) {
        isInList = false;
        for (let j in mergedGroups) {
            if (mergedGroups[j].id === groupsToAdd[i].id) {
                isInList = true;
                break;
            }
        }
        if (!isInList) {
            mergedGroups.push(groupsToAdd[i]);
        }
    }
    return mergedGroups;
}

function refreshCapsule(capsule, state) {
    let modifiedState = state;
    for (let i in modifiedState.capsules) {
        if (capsule.id === modifiedState.capsules[i].id) {
            modifiedState.capsules[i] = capsule; 
        }
    }

    for (let i in modifiedState.favourites) {
        if (capsule.id === modifiedState.favourites[i].id) {
            modifiedState.favourites[i] = capsule; 
        }
    }

    for (let i in modifiedState.featured) {
        if (capsule.id === modifiedState.featured[i].id) {
            modifiedState.featured[i] = capsule; 
        }
    }

    if (capsule.groups.length > 0) {
        for (let j in modifiedState.capsulesByGroup) {
            for (let z in modifiedState.capsulesByGroup[j].content) {
                if (capsule.id === modifiedState.capsulesByGroup[j].content[z].id) {
                    modifiedState.capsulesByGroup[j].content[z] = capsule; 
                }
            }
        }
        
    }
    
    return modifiedState;
}
