/* eslint-disable no-dupe-keys */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container, Collapse } from 'reactstrap';
import { CapsuleEdit } from './EditCapsuleView';
import { MediaEdit } from './EditMediaView';
import { VCardEdit } from './EditVcardView';
import { SocialEdit } from './EditSocialView';
//import { ConnectEdit } from './EditConnectView';
import { ConfirmationView } from './ConfirmationView';
import { GroupEdit } from './EditGroupsView';
import { arrayMove } from 'react-sortable-hoc';
import facebookIcon from '../../assets/images/FacebookIcon@3x.png';
import instagramIcon from '../../assets/images/Instagram@3x.png';
import twitterIcon from '../../assets/images/TwitterActive@3x.png';
import youtubeIcon from '../../assets/images/YoutubeActive@3x.png';
import { userActions, capsuleActions } from '../redux/actions';
import { Explore } from '../explore/Explore';
import { isObjectExists, sortAlphabetically, getUserTokenFromStorage, convertCapsule } from '../../utils/Helpers';
import nameIcon from '../../assets/images/RelatedNameIcon@3x.png';
import otherIcon from '../../assets/images/MegaphoneIcon.png';
import backButton from '../../assets/images/BackArrow@2x.png';
import deleteIcon from '../../assets/images/DeleteCapsule.png';
import {ModalView} from './ModalView';
var moment = require('moment');

export class CreateCard extends React.Component {

    constructor (props) {
        super(props);

        this.defaultAddress = {
            label: 'Home',
            value: {
                street:     '',
                city:       '',
                state:      '',
                postalCode: '',
                country:    ''
            }
        };

        /*
         * this.state.isEdit determines if we're seeing edit/preview, this.props.isEdit determines if we need to create/update onSave
         */

        this.state = {
            navigationSlideIndex:   0,
            isEdit:                 true,
            isReorder:              false,
            isBrowse:               true,
            isConfirmation:         false,
            confirmationType:       'edited',
            itemAdded:              false,
            gotCapsulesAfterCreate: false,
            backroundUploadState:   '',
            isVcardOpen:            true,
            photoCategory:          true,
            groupCategory:          true,
            socialOpen:             true,
            photoVideo:             false,
            audio:                  false,
            documents:              false,
            bio:                    false,
            notes:                  false,
            payments:               false,
            isVcardSectionOpen:     true,
            isCapsuleSectionOpen:   true,
            isRemoving:             false,
            showEditor:             false,
            itemToRemove:           '',
            isExploreGroups:        false,
            isConnectOpen:          true,
            isDelete:               false,
            isCapsuleChanged:       false,
            showUploading:          false,
            mediaToEdit:            null,
            isFirstStep:            true,
            showSavingNote:         false,
            newDocName:             '',
            newAudioName:           '',
            videoCount:             0,
            photoCount:             0,
            maxVideoCount:          0,
            maxPhotoCount:          0,
            tempGroups:             isObjectExists(this.props, 'capsuleToEdit.groups') ? this.props.capsuleToEdit.groups : [],
            tempExploreGroups:      isObjectExists(this.props, 'capsuleToEdit.exploreGroups') ? this.props.capsuleToEdit.exploreGroups : [],
            showSetToPublic:        false,
            newCapsule:             convertCapsule(this.props.capsuleToEdit)
        };
    }

    componentDidMount() {
        this.props.changeFooterTo('hide');
        this.props.dispatch(userActions.getFiles(this.props.user.token));
        this.props.dispatch(capsuleActions.getExploreGroups());
    }

    componentWillUnmount() {
        this.props.changeFooterTo('main');
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.videoCount === this.state.maxVideoCount && this.state.videoCount !== 0) {
            this.setState({maxVideoCount: 0, videoCount: 0});
        }

        if (this.state.photoCount === this.state.maxPhotoCount && this.state.photoCount !== 0) {
            this.setState({maxPhotoCount: 0, photoCount: 0});
        }

        if (prevState.isEdit === true && this.state.isEdit !== true) {
            this.props.changeFooterTo('main');
        }

        if (prevState.isEdit !== true && this.state.isEdit === true) {
            this.props.changeFooterTo('hide');
        }

        if (this.state.newCapsule.social.socialProfiles.length === 0) {
            let newCapsule = {...this.state.newCapsule};
            let socProfiles = newCapsule.social.socialProfiles;
            socProfiles.push({
                label: 'Website',
                value: {
                    service:   'Website',
                    toolbar:   true,
                    urlString: ''
                }
            });
            newCapsule.social.socialProfiles = socProfiles;
            this.setState({newCapsule});
        }

        if (this.state.newCapsule.capsule.id === '' && this.props.capsuleToEdit && this.props.capsuleToEdit.id) {
            let newCapsule = {...this.state.newCapsule};
            newCapsule.capsule.id = this.props.capsuleToEdit.id;
            this.setState({newCapsule});
        }

        if ((prevProps.user.newFileId !== this.props.user.newFileId) && this.props.user.picture && this.props.user.fileUploaded && (this.state.backgroundUploaded !== 'profile' && this.state.backgroundUploaded !== 'thumbnail')) {
            this.props.dispatch(userActions.getFile(this.getId(Object.keys(this.props.user.newFileId), this.props.user.newFileId)));
            this.setState({isCapsuleChanged: true, photoCount: this.state.photoCount+1, typeUploaded: 'photo'});
        }

        if ((prevProps.user.newFileId !== this.props.user.newFileId) && this.props.user.picture && this.props.user.fileUploaded && (this.state.backgroundUploaded === 'profile' || this.state.backgroundUploaded === 'thumbnail')) {
            this.props.dispatch(userActions.getFile(this.getId(Object.keys(this.props.user.newFileId), this.props.user.newFileId)));
            this.setState({isCapsuleChanged: true});
        }

        if ((prevProps.user.newFileId !== this.props.user.newFileId) && this.props.user.document && this.props.user.fileUploaded) {
            this.addToDocuments(this.convertToFileObject(this.props.user.newFileId[0]));
            this.setState({isCapsuleChanged: true});
        }

        if ((prevProps.user.newFileId !== this.props.user.newFileId) && this.props.user.video && this.props.user.fileUploaded) {
            this.props.dispatch(userActions.getFile(this.getId(Object.keys(this.props.user.newFileId), this.props.user.newFileId)));
            this.setState({isCapsuleChanged: true, videoCount: this.state.videoCount+1, typeUploaded: 'video'});
        }

        if (!prevProps.user.gotFile && this.props.user.gotFile === true) {
            this.addToSection(this.props.user.requestedFile);
        }

        if ((prevProps.user.newFileId !== this.props.user.newFileId) && this.props.user.audio && this.props.user.fileUploaded) {
            this.addToAudio(this.convertToFileObject(this.props.user.newFileId[0]), this.state.mediaToEdit);
            this.setState({isCapsuleChanged: true});
        }

        if (prevProps.user.capsuleUpdated === false && this.props.user.capsuleUpdated === true) {
            this.setState({isConfirmation: true, confirmationType: 'edit'});
            let listToSearch = this.props.searchTerm !== '' ? this.props.capsules.searchResults : this.props.capsules.capsule;
            this.props.setCapsulesToShow(listToSearch);
            this.props.changeStartingIndex(this.props.getIndexOfNewCapsule(this.props.capsuleToEdit.id, listToSearch));
            this.props.dispatch(userActions.getCapsules());
        }

        if (prevProps.user.capsuleCreated === false && this.props.user.capsuleCreated === true) {
            
            this.setState({isConfirmation: true, confirmationType: 'create'});
            if (this.props.groupId && this.props.groupId !== '') {
                this.props.dispatch(capsuleActions.addCapsuleToGroup(this.props.groupId, this.props.user.createdCapsule.id));
                let newList = this.props.capsules.capsulesOfGroup;
                newList.push(this.props.user.createdCapsule);
                newList = sortAlphabetically(newList);
                this.props.changeStartingIndex(this.props.getIndexOfNewCapsule(this.props.user.createdCapsule.id, newList));
                this.props.setCapsulesToShow(newList);
                //this.props.setGroupIdToAddTo('');
            } else {
                this.props.changeTypeOfList('user');
                this.props.changeGroupName('Contact Media Capsules');
                this.props.changeStartingIndex(this.props.getIndexOfNewCapsule(this.props.user.createdCapsule.id, this.props.user.userCapsules));
                this.props.setCapsulesToShow(this.props.user.userCapsules);
                
            }
        }

        if (prevProps.user.capsuleGotAfterCreate !== true && this.props.user.capsuleGotAfterCreate === true) {
            this.props.changeStartingIndex(this.props.getIndexOfNewCapsule(this.props.user.createdCapsule.id, this.props.user.userCapsules));
            this.setState({gotCapsulesAfterCreate: true});
        }

        if (prevProps.capsules.capsuleAddedToGroup === false && prevProps.capsules.capsuleAddedToGroup === true) {
            this.props.dispatch(capsuleActions.getCapsulesByGroup(this.state.viewedGroup));
        }

        if (prevProps.isUploading === true && this.props.isUploading === false && this.state.showUploading === true) {
            this.setState({showUploading: false});
            this.onSave();
        }

        if (prevProps.capsules.groupCreated === false && this.props.capsules.groupCreated === true) {
            this.props.dispatch(capsuleActions.getCapsuleGroups());
            let newCapsule = {...this.state.newCapsule};
            newCapsule.capsule.groups.push(this.props.capsules.newGroup.id);
            this.setState({newCapsule});
        }

        if (!prevProps.capsules.groupCreated && this.props.capsules.groupCreated) {
            let tempGroups = [...this.state.tempGroups];

            tempGroups.push(this.props.capsules.newGroup.id);

            this.setState({tempGroups});
        }

        if (this.props.groupId === '' && prevProps.groupId !== '') {
            this.props.dispatch(capsuleActions.getCapsulesByGroup(this.state.viewedGroup));
        }

        if (prevProps.isUploading === true && this.props.isUploading === false && !this.state.showSavingNote && !this.state.showUploading && !localStorage.getItem('hideSaveNote')) {
            this.setState({showSavingNote: true});
        }

        if (!prevState.isGroupsOpen && this.state.isGroupsOpen === true) {
            document.documentElement.style.overflow = 'hidden';
        }

        if (!this.state.isGroupsOpen &&prevState.isGroupsOpen === true) {
            document.documentElement.style.overflow = 'unset';
        }
        
    }

    addToSection = (fileObject) => {

        let objectToAdd = {
            fileId:   fileObject.id,
            metadata: {
                mimeType:    fileObject.mimeType,
                storageType: fileObject.storageType,
                storageUUID: fileObject.storageUUID
            }

        };

        if (fileObject.name) objectToAdd.filename = fileObject.name;
        this.props.dispatch(userActions.getFirebaseStatus(fileObject.storageUUID));
        this.props.listenStatus(fileObject.storageUUID);
        if (this.state.backgroundUploaded === 'profile' || this.state.backgroundUploaded === 'thumbnail') {
            if (objectToAdd.metadata.mimeType && -1 < objectToAdd.metadata.mimeType.indexOf('video/')) {
                this.addToVideos(objectToAdd, this.state.backgroundUploaded);
                this.props.setCheckingProgressOf('capsuleMedia');
            } else {
                this.addToImages(objectToAdd, this.state.backgroundUploaded);
                this.props.setCheckingProgressOf('capsuleMedia');
            }
        } else {
            switch (this.state.typeUploaded) {
                case 'video':
                    this.addToVideos(objectToAdd);
                    this.props.setCheckingProgressOf('mediaVideo');
                    break;
                case 'photo':
                    this.addToImages(objectToAdd);
                    this.props.setCheckingProgressOf('mediaImage');
                    break;
            }
        }
        
    }

    removeUserImages = (type) => {
        let newCapsule = {...this.state.newCapsule};

        newCapsule.social.image[type] = '';

        this.setState({newCapsule});
    }

    getId = (keys, obj) => {
        let id;
        for (let i in keys) {
            if (obj[keys[i]].customIdentifier) {
                id = obj[keys[i]].customIdentifier;
            }
        }
        return id;
    }

    onSave = () => {
        if (this.props.user.uploadInProgress || this.props.isUploading === true) {
            this.setState({showUploading: true});
        } else {
            let capsuleToCompareTo = this.props.user.updatedCapsule ? this.props.user.updatedCapsule : this.props.capsuleToEdit;

            this.clearEmptySocialProfile();
            if (!this.state.showPublicToPrivate && !this.state.messageShowed &&
                capsuleToCompareTo && capsuleToCompareTo.public === true && capsuleToCompareTo.exploreGroups.length !== 0 &&
                this.state.newCapsule.settings.public === false && this.state.newCapsule.capsule.exploreGroups.length !== 0 &&
                 this.props.user.canPublishCapsule && (this.props.capsuleToEdit && !this.props.capsuleToEdit.forceNonPublic)) {
                this.setState({showPublicToPrivate: true, messageShowed: true});
            } else if (!this.state.showNoExploreGroups && !this.state.messageShowed &&
                capsuleToCompareTo && capsuleToCompareTo.public === true && capsuleToCompareTo.exploreGroups.length !== 0 &&
                    this.state.newCapsule.settings.public === true && this.state.newCapsule.capsule.exploreGroups.length === 0 && 
                    this.props.user.canPublishCapsule && (this.props.capsuleToEdit && !this.props.capsuleToEdit.forceNonPublic)) {
                this.setState({showNoExploreGroups: true, messageShowed: true});
            } else if (!this.state.showSetToPublic && !this.state.messageShowed &&
                this.state.newCapsule.settings.public === false && this.state.newCapsule.capsule.exploreGroups.length !== 0 &&
                this.props.user.canPublishCapsule && (this.props.capsuleToEdit && !this.props.capsuleToEdit.forceNonPublic)) {
                this.setState({showSetToPublic: true, messageShowed: true});
            } else {
                if (this.props.isEdit) {
                    if (this.state.newCapsule.vCard.birthDay.length == 0) {
                        let newCapsule = this.state.newCapsule;
                        newCapsule.vCard.birthDay = '';
                        this.setState({newCapsule: newCapsule});
                    }
                    if (this.state.newCapsule.capsule.name === '') {
                        let newCapsule = {...this.state.newCapsule};
                        newCapsule.capsule.name = this.getFirstNameLastName();
                        this.setState({newCapsule: newCapsule});
                    }

                    if (this.state.newCapsule.media.notes.length > 0) {
                        for (let i in this.state.newCapsule.media.notes) {
                            if (this.state.newCapsule.media.notes[i] === '') {
                                this.state.newCapsule.media.notes.splice(i, 1);
                            }
                        }
                    }
                    this.props.dispatch(userActions.updateCapsule(this.getTrimmedObject(this.state.newCapsule), this.props.capsuleToEdit.id));
                } else {
                    this.props.dispatch(userActions.createCapsule(this.getTrimmedObject(this.state.newCapsule)));
                }

                this.setState({isCapsuleChanged: false, messageShowed: false});
            }
        }
    }

    getTrimmedObject = (capsuleObject) => {
        let trimmedObject = {...capsuleObject};

        trimmedObject.capsule.name = trimmedObject.capsule.name.trim();
        trimmedObject.vCard.firstName = trimmedObject.vCard.firstName.trim();
        trimmedObject.vCard.lastName = trimmedObject.vCard.lastName.trim();

        return trimmedObject;
    } 

    incrementUploadCount = (isVideo) => {
        if (isVideo) {
            this.setState({maxVideoCount: this.state.maxVideoCount+1});
        } else {
            this.setState({maxPhotoCount: this.state.maxPhotoCount+1});
        }
        
    }
    
    executeQueue = () => {
        this.props.dispatch(userActions.uploadFile(this.state.videoQueue[0].file, this.props.user.token, this.state.videoQueue[0].type));
    }

    convertToFileObject = (fileObject) => {
        return  {
            filename: fileObject.name,
            fileId:   fileObject.id,
            metadata: {
                mimeType:   fileObject.mimeType,
                hasPreview: false
            }
        };
    }

    setIsExploreGroups = (value) => {
        this.setState({isExploreGroups: value});
    }

    createGroup = (name) => {
        if (name !== '') {
            this.props.dispatch(capsuleActions.createCapsuleGroup(name));
            this.setState({isGroupsOpen: false});
        } 
    }

    onDelete = () => {
        this.props.deleteCapsule(this.props.capsuleToEdit.id); 
    }

    onRemove = (i) => {
        this.setState({isRemoving: true, itemToRemove: i});
    }

    onCancel = () => {
        this.setState({isRemoving: false, itemToRemove: ''});
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        let newCapsule = this.state.newCapsule;
        newCapsule.social.socialProfiles = arrayMove(this.state.newCapsule.social.socialProfiles, oldIndex, newIndex);
        this.setState({
            newCapsule: newCapsule
        });

    };
 
    toggleMenu = (menu) => {
        this.setState({[menu]: !this.state[menu]});
    }

    setMediaToEdit = (i) => {
        this.setState({mediaToEdit: i});
    }

    onNewNameChange = (value, type) => {
        if (type === 'audio') {
            this.setState({newAudioName: value});
        } else {
            this.setState({newDocName: value});
        }
    }

    uploadPicture = (e, type) => {
        const fileToUpload = e.target.files;
        this.backgroundUploaded(type);

        this.props.dispatch(userActions.uploadFile(fileToUpload, this.props.user.token, 'picture'));
    }

    clearEmptySocialProfile = () => {
        let newCapsule = {...this.state.newCapsule};
        let socialProfiles = newCapsule.social.socialProfiles;

        let clearedSocialProfiles = socialProfiles.filter((el) => {
            return el.value.urlString !== '';
        });

        newCapsule.social.socialProfiles = clearedSocialProfiles;
        this.setState({newCapsule});
    }

    addRemoveNote = (i) => {
        let notes = this.state.newCapsule.media.notes;
        let newCapsule = this.state.newCapsule;

        if (!i) {
            notes.push('');
        } else {
            notes.splice(i, 1);
        }

        newCapsule.media.notes = notes;

        this.setState({newCapsule});
    }

    removeFromSocial = (i) => {
        var socialProfile = this.state.newCapsule.social.socialProfiles;
        let newCapsule = this.state.newCapsule;
        socialProfile.splice(i, 1);
        newCapsule.social.socialProfiles = socialProfile;
        this.setState({newCapsule});
    }

    addToSocial = () => {
        let newCapsule = {...this.state.newCapsule};
        var socialProfile = newCapsule.social.socialProfiles;
        socialProfile.push({
            label: 'Website',
            value: {
                service:   'Website',
                toolbar:   true,
                urlString: ''
            }
        });
        newCapsule.social.socialProfiles = socialProfile;
        this.setState({newCapsule, itemAdded: true});

    }

    addField = (fieldName) => {
        let newCapsule = {...this.state.newCapsule};
        var socialProfile = newCapsule.social.socialProfiles;

        if (fieldName === 'urlAddress') {
            socialProfile.push({
                label: 'Home',
                value: {
                    service:   'Website',
                    toolbar:   true,
                    urlString: ''
                }
            });
            
        } else if (fieldName === 'locationAddress') {
            newCapsule.vCard[fieldName].push({
                label: 'Home',
                value: {
                    street:     '',
                    city:       '',
                    state:      '',
                    postalCode: '',
                    country:    ''
                }});
        } else if (fieldName === 'dates') {
            newCapsule.vCard.dates.push({label: 'anniversary', value: ''});
        } else {
            newCapsule.vCard[fieldName].push({label: 'home', value: ''});
        } 

        this.setState({newCapsule, itemAdded: true});
    }

    addToDocuments = (documentId) => {
        let newCapsule = {...this.state.newCapsule};
        let documents = newCapsule.media.documents;

        if (this.state.mediaToEdit) {
            documents[this.state.mediaToEdit] = documentId;
        } else {
            if (this.state.newDocName !== '') {
                documentId.filename = this.state.newDocName;
            }
            documents.push(documentId);
        }
        
        newCapsule.media.documents = documents;
        this.setState({newCapsule, newDocName: ''});
    }

    removeFromDocuments = (i) => {
        let newCapsule = {...this.state.newCapsule};
        let docs = newCapsule.media.documents;

        docs.splice(i, 1);
        newCapsule.media.documents = docs;

        this.setState({newCapsule});
    }

    addToVideos = (video, type) => {
        let newCapsule = this.state.newCapsule;
        let videos = newCapsule.media.videos;
        videos.push(video);
        newCapsule.media.videos = videos;
        if (type) {
            newCapsule.social.image[type]={fileId: video.fileId, metadata: video.metadata, filename: video.filename};
            this.setState({newCapsule, backgroundUploaded: ''});
        } else {
            this.setState({newCapsule});
        }
        
    }

    onNoteConfirm = (hide) => {
        if (hide) {
            localStorage.setItem('hideSaveNote', true);
        }
        this.setState({showSavingNote: false});
    }

    removeFromVideos = (i) => {
        let videos = this.state.newCapsule.media.videos;
        let newCapsule = this.state.newCapsule;

        videos.splice(i, 1);
        newCapsule.media.videos = videos;

        this.setState({newCapsule, isRemoving: false, itemToRemove: ''});
    }

    addToAudio = (audio) => {
        let newCapsule = {...this.state.newCapsule};
        let audios = newCapsule.media.audio;
        if (this.state.mediaToEdit) {
            audios[this.state.mediaToEdit] = audio;
        } else {
            if (this.state.newAudioName !== '') {
                audio.filename = this.state.newAudioName;
            }
            audios.push(audio);
        }
        this.setMediaToEdit(null);
        newCapsule.media.audio = audios;
        this.setState({newCapsule, newAudioName: ''});
    }

    removeFromAudio = (i) => {
        let audio = this.state.newCapsule.media.audio;
        let newCapsule = this.state.newCapsule;

        audio.splice(i, 1);
        newCapsule.media.audio = audio;

        this.setState({newCapsule});
    }

    addToImages = (image, type) => {
        let modifiedCapsule = this.state.newCapsule;
        let images = this.state.newCapsule.media.images || [];
        if (typeof(type) !== 'undefined') {
            if (type === 'profile') {
                modifiedCapsule.social.image[type]={fileId: image.fileId, metadata: image.metadata, url: '', filename: image.filename};
            } else {
                modifiedCapsule.social.image[type]={fileId: image.fileId, metadata: image.metadata, filename: image.filename};
            }
            
            images.push({fileId: image.fileId, metadata: image.metadata, filename: image.filename});
        } else {
            images.push(image);
        }

        modifiedCapsule.media.images = images;
        this.setState({newCapsule: modifiedCapsule, backgroundUploaded: ''});

    }

    removeField = (fieldName, i) => {
        let newCapsule = {...this.state.newCapsule};
        
        newCapsule.vCard[fieldName].splice(i, 1);

        this.setState({newCapsule});
        
    }

    removeFromImages = (image) => {
        let images = this.state.newCapsule.media.images;
        let modifiedCapsule = this.state.newCapsule;
        let imageId;

        if (image.fileId && image.fileId !== '') {
            imageId = image.fileId;
        } else if (image.url && image.url !== '') {
            imageId = image.url;
        }

        for (var i in images) {
            if (images[i].fileId === imageId || images[i].url === imageId) {
                images.splice(i, 1);
            }
        }

        modifiedCapsule.media.images = images;
        this.setState({newCapsule: modifiedCapsule});

    }

    backgroundUploaded = (type) => {
        this.setState({backgroundUploaded: type});
    }

    getPreviewResizerParameters = (elementId) => {
        let element =  document.getElementById(elementId);
        let parameter = '';

        if (element && elementId !== 'preview') {
           
            if (element.clientWidth != 0 && element.clientHeight != 0) {
                parameter = 'w' + element.clientWidth + '-h' + element.clientHeight;
            }
        }
        return parameter + '-cfill';
    }

    getSocialUrlFromData = (social) => {
        const socialProfiles = this.props.capsuleToEdit.items.contact.socialProfiles;
        for (var i in socialProfiles) {
            if (socialProfiles[i].value.service === social) {
                return socialProfiles[i].value.urlString;
            }
        }
        return '';
    }

    getSocialOrderFromData = (defaultOrder) => {
        var socialProfiles = this.props.capsuleToEdit.items.contact.socialProfiles;
        var order = [];
        if (0 < socialProfiles.length) {
            for (var i in socialProfiles) {
                order.push({
                    icon:    this.getIcon(socialProfiles[i].value.service),
                    social:  socialProfiles[i].value.service,
                    toolbar: socialProfiles[i].value.toolbar
                });

            }
        } else {
            order = defaultOrder;
        }
        return order;
    }

    getIcon = (service) => {
        switch (service) {
            case 'facebook':
                return facebookIcon;
            case 'instagram':
                return instagramIcon;
            case 'twitter':
                return twitterIcon;
            case 'youtube':
                return youtubeIcon;
        }
    }

    getIfVideo = (image) => {
        let result = '';

        if (isObjectExists(image, 'metadata.mimeType') && -1 !== image.metadata.mimeType.indexOf('image/')) {
            result = this.getPreviewResizerParameters();
        }

        return result;
    }

    getSrcUrl = (type) => {
        if (type === 'profile' || type === 'thumbnail') {
            if (isObjectExists(this.state, 'newCapsule.social.image.' + type + '.fileId') && this.state.newCapsule.social.image[type].fileId !== '') {
                return {url:  process.env.REACT_APP_API_BASE_URI + '/cdn/files/' + this.state.newCapsule.social.image[type].fileId + '/' +
                this.getIfVideo(this.state.newCapsule.social.image[type]) + '?token=' + getUserTokenFromStorage(), type: 'file'};
            } else if (isObjectExists(this.state, 'newCapsule.social.image.'+ type + '.url') && this.state.newCapsule.social.image[type].url !== '') {
                return {url: this.state.newCapsule.social.image[type].url, type: 'url'};
            } else {
                return '';
            }
        }
       
    }

    changeSocialUrl = (value, newCapsule, i, service) => {
        let modifiedCapsule = newCapsule;
        
        if (-1 < value.indexOf('facebook.com') && modifiedCapsule['social'].socialProfiles[i].value.service !== 'facebook') {
            modifiedCapsule['social'].socialProfiles[i].value.service = 'facebook';
            modifiedCapsule['social'].socialProfiles[i].label = 'facebook';
        } else if (-1 < value.indexOf('twitter.com') && modifiedCapsule['social'].socialProfiles[i].value.service !== 'twitter') {
            modifiedCapsule['social'].socialProfiles[i].value.service = 'twitter';
            modifiedCapsule['social'].socialProfiles[i].label = 'twitter';
        } else if (-1 < value.indexOf('instagram.com') && modifiedCapsule['social'].socialProfiles[i].value.service !== 'instagram') {
            modifiedCapsule['social'].socialProfiles[i].value.service = 'instagram';
            modifiedCapsule['social'].socialProfiles[i].label = 'instagram';
        } else if (-1 < value.indexOf('youtube.com') && modifiedCapsule['social'].socialProfiles[i].value.service !== 'youtube') {
            modifiedCapsule['social'].socialProfiles[i].value.service = 'youtube';
            modifiedCapsule['social'].socialProfiles[i].label = 'youtube';
        } else if (-1 < value.indexOf('spotify.com') && modifiedCapsule['social'].socialProfiles[i].value.service !== 'spotify') {
            modifiedCapsule['social'].socialProfiles[i].value.service = 'spotify';
            modifiedCapsule['social'].socialProfiles[i].label = 'spotify';
        } else {
            if (service === 'url') {
                modifiedCapsule['social'].socialProfiles[i].value.service = 'Home';
                modifiedCapsule['social'].socialProfiles[i].label = 'Home';
            }  else {
                modifiedCapsule['social'].socialProfiles[i].value.service = 'website';
                modifiedCapsule['social'].socialProfiles[i].label = 'website';
            }

        }
        
        modifiedCapsule['social'].socialProfiles[i].value.urlString = value;

        return modifiedCapsule;
    }

    isFileImage = (url) => {
        let result = /^(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)$/.test(url);
        let resultObject = {mimeType: ''};
        if (result && 0 <= url.indexOf('.png')) {
            resultObject.mimeType = 'image/png';
        } else if (result && 0 <= url.indexOf('.gif')) {
            resultObject.mimeType = 'image/gif';
        } else if (result && 0 <= url.indexOf('.jpg')) {
            resultObject.mimeType = 'image/jpeg';
        }
        if (resultObject.mimeType !== '') {
            result = resultObject;
        }
        return result;
    }

    onCapsuleChange = (e, category, i, service) => {
        let newCapsule = {...this.state.newCapsule};

        if (category === 'vCard' && i) {
            switch (service) {
                case 'phoneType':
                    newCapsule[category].phoneNumber[i].label = e.target.value;
                    break;
                case 'phoneNumber':
                    newCapsule[category].phoneNumber[i].value = e.target.value;
                    break;
                case 'emailType':
                    newCapsule[category].emailAddress[i].label = e.target.value;
                    break;
                case 'emailAddress':
                    newCapsule[category].emailAddress[i].value = e.target.value;
                    break;
                case 'urlType':
                    newCapsule.social.socialProfiles[i].label = e.target.value;
                    break;
                case 'urlAddress':
                    newCapsule[category].urlAddress[i].value = e.target.value;
                    break;
                case 'addressType':
                    newCapsule[category].locationAddress[i].label = e.target.value;
                    break;
                case 'dateType':
                    newCapsule[category].dates[i].label = e.target.value;
                    break;
                case 'dates':
                    
                    newCapsule[category].dates[i].value = moment(e).format();
                    break;
                default:
                    newCapsule[category].locationAddress[i].value[e.target.name] = e.target.value;
            }
            
        } else if (category === 'social' && !isNaN(i) && service && service !== 'url') {     
            newCapsule[category].socialProfiles[i].label = e.target.value;
            newCapsule[category].socialProfiles[i].value.urlString = this.getSocialBaseUrl(e.target.value, newCapsule[category].socialProfiles[i].value.urlString);
            newCapsule[category].socialProfiles[i].value.service = e.target.value;
        } else if (category === 'social' && !isNaN(i)) {
            newCapsule[category].socialProfiles[i].label = service === 'url' ? 'Home' : e.target.value;
            newCapsule[category].socialProfiles[i].value.urlString = this.getSocialBaseUrl(e.target.value, newCapsule[category].socialProfiles[i].value.urlString);
            newCapsule[category].socialProfiles[i].value.service = service === 'url' ? 'Home' : e.target.value;
            newCapsule = this.changeSocialUrl(e.target.value, newCapsule, i, service);
        } else if (category === 'profileUrl') {
            if (this.isFileImage(e).mimeType) {
                newCapsule.social.image.profile = {fileId: '', url: e, metadata: {mimeType: this.isFileImage(e).mimeType}};
            } else {
                newCapsule.social.image.profile = {
                    fileId:   '',
                    url:      e,
                    metadata: {
                        mimeType:    'text/html',
                        compactView: true,
                        hasPreview:  false
                    }};
            }
        } else if (category === 'thumbnailUrl') {
            
            newCapsule.social.image.thumbnail = {fileId: '', url: e, source: e};
        } else if (category === 'photos') {
            newCapsule.media.images.push({url: e});  
        } else if (category === 'videos') {
            newCapsule.media.videos.push({url: e});  
        } else if (category === 'audio') {
            newCapsule.media.audio.push({url: e});
        } else if (category === 'documents') {
            if (i) {
                newCapsule.media.documents[i].url = e;
            } else {
                newCapsule.media.documents.push({url: e});
            }
            
        } else if (category === 'mediaName') {
            newCapsule.media[e.target.name][i].filename = e.target.value;   
        } else if (i) {
            newCapsule[category][e.target.name][i] = e.target.value;
        } else {
            newCapsule[category][e.target.name] = e.target.value;
            
        }
        this.setState({newCapsule, isCapsuleChanged: true});
    }

    onNotesChange = (e, i, type) => {
        let newCapsule = this.state.newCapsule;
        newCapsule.media.notes[i][type] = e.target.value;

        this.setState({newCapsule});
    }

    getFirstNameLastName = () => {
        let capsuleName = '';
        if (this.state.newCapsule.vCard.firstName && !this.state.newCapsule.vCard.lastName) {
            capsuleName = this.state.newCapsule.vCard.trim();
        } else if (!this.state.newCapsule.vCard.firstName && this.state.newCapsule.vCard.lastName) {
            capsuleName = this.state.newCapsule.vCard.lastName.trim();       
        } else if (this.state.newCapsule.vCard.firstName && this.state.newCapsule.vCard.lastName) {
            capsuleName = this.state.newCapsule.vCard.firstName.trim() + ' ' + this.state.newCapsule.vCard.lastName.trim();
        }

        return capsuleName;
    }

    getSocialBaseUrl = (socialType) => {
        switch (socialType) {
            case 'facebook':
                return 'https://www.facebook.com/';
            case 'twitter':
                return 'https://www.twitter.com/';
            case 'spotify':
                return 'https://www.spotify.com/';
            case 'youtube':
                return 'https://www.youtube.com/';
            case 'instagram':
                return 'https://www.instagram.com/';
            default: 
                return '';      
        }
    }

    toggleToolbar = (i) => {
        let capsuleObject = this.state.newCapsule;
        let order = capsuleObject.social.socialProfiles;
        order[i].value.toolbar = !order[i].value.toolbar;
        capsuleObject.social.socialProfile = order;
        this.setState({newCapsule: capsuleObject});
    }

    setSocialProfilesToState = () => {
        const order = this.state.newCapsule.social.socialOrder;
        var capsuleObject = this.state.newCapsule;
        var profiles = [];

        for (var i in order) {

            profiles.push(
                {
                    label: order[i].social.toLowerCase(),
                    value: {
                        service:   order[i].social.toLowerCase(),
                        urlString: this.state.newCapsule.social[order[i].social.toLowerCase()],
                        toolbar:   order[i].toolbar
                    }
                }
            );
        }
        capsuleObject.social.socialProfiles = profiles;
        this.setState({newCapsule: capsuleObject});

        return profiles;

    }

    onContinue = () => {
        this.setState({isConfirmation: false});
    }

    deleteCapsule = () => {
        this.props.dispatch(userActions.deleteCapsule(this.props.user.token, this.props.capsuleToEdit.id));
        this.props.changeView('main');
    }

    navigateBack = () => {
        this.clearEmptySocialProfile();
        if (!this.state.isBack && this.state.isCapsuleChanged) {
            this.setState({isBack: true});
        } else {
            this.props.changeView('main'); 
            this.props.isEdit ? this.props.changeGroupView('capsuleList') : this.props.changeGroupView('main');
        }
        
    }

    setCapsuleToPublic = (value) => {
        let newCapsule = {...this.state.newCapsule};
  
        newCapsule.settings.public = value;
        
        this.setState({newCapsule});
        this.onSave();
    }

    cancelExplore = (value) => {
        //let newCapsule = {...this.state.newCapsule};
        //newCapsule.capsule.exploreGroups = [];
        if (value ) {
            let newCapsule = {...this.state.newCapsule};
            newCapsule.settings.public = value;
            this.setState({newCapsule});
        }
        //this.setState({newCapsule, tempExploreGroups: []});
        this.onSave();
    }

    renderHeaderOptions = () => {
        return (
            <React.Fragment>
                <ModalView isOpen={this.state.isBack} 
                    toggle={() => this.setState({isBack: !this.state.isBack})}
                    onContinue={() => this.navigateBack()}
                    onCancel={() => {this.onSave(); this.setState({isBack: !this.state.isBack});}}
                    title="Are you sure you want to go back?"
                    defaultValue={''}
                    modalType="back"
                />
                <ModalView isOpen={this.state.showSetToPublic} 
                    toggle={() => this.setState({showSetToPublic: !this.state.showSetToPublic})}
                    onContinue={this.setCapsuleToPublic}
                    title="Capsule needs to be public"
                    defaultValue={''}
                    modalType="exploreGroup"
                />
                <ModalView isOpen={this.state.showPublicToPrivate} 
                    toggle={() => this.setState({showPublicToPrivate: !this.state.showPublicToPrivate})}
                    onContinue={this.setCapsuleToPublic}
                    title="Private capsules won't display in Public Explore groups"
                    defaultValue={''}
                    modalType="publicToPrivate"
                />
                <ModalView isOpen={this.state.showNoExploreGroups} 
                    toggle={() => this.setState({showNoExploreGroups: !this.state.showNoExploreGroups})}
                    onContinue={this.setCapsuleToPublic}
                    onCancel={this.setCapsuleToPublic}
                    title="Public capsules are publicly searchable"
                    defaultValue={''}
                    modalType="noExploreGroups"
                />
                <ModalView isOpen={this.state.showUploading} 
                    toggle={() => this.setState({showUploading: !this.state.showUploading})}
                    title="File is still uploading"
                    defaultValue={''}
                    modalType="uploadInProgress"
                />
                <ModalView isOpen={this.state.showSavingNote}
                    toggle={() => this.setState({showSavingNote: !this.state.showSavingNote})}
                    onContinue={this.onNoteConfirm}
                    title="Uploading done"
                    modalType="saveNote"
                />
                
                <Container>
                    <Row className={this.state.isEdit ? 'header-options' : 'header-options preview justify-content-center'}>
                        <Col xs="3" sm="3" md="3" lg="3">
                            {this.state.isEdit ?
                                <img  src={backButton}  className="navigation-button back-button"
                                    onClick={() => this.navigateBack()}
                                /> : ''
                            }
                        </Col>
                
                        {this.props.isCreating ? 
                            <Col className="text-center">Create Capsule</Col>
                            :
                            <React.Fragment>
                                <Col xs="3" sm="3" md="3" lg="3"
                                    onClick={() => {this.props.onPreviewChange(false); this.setState({isEdit: true});}} 
                                    className={this.state.isEdit ? 'edit-option active' : 'edit-option'}>
                            Edit
                                </Col>
                                <Col xs="3" sm="3" md="3" lg="3"
                                    onClick={() => {this.props.onPreviewChange(true); this.setState({isEdit: false}); this.clearEmptySocialProfile();}}
                                    className={this.state.isEdit ? 'preview-option' : 'preview-option active'}>
                            Preview
                                </Col>
                            </React.Fragment>
                        }

                        <Col xs="3" sm="3" md="3" lg="3" className="right">
                            {this.state.isEdit  ?
                                !this.props.isCreating ? <span className="save-button navigation-button" onClick={() => this.onSave()}>Save</span> : '' 
                                :
                                ''
                            }
                        </Col>

                    </Row>
                </Container>
            </React.Fragment>
        );
    }

    afterNavigationSlide = (index) => {
        this.setState({navigationSlideIndex: index});
    }

    onAddGroup = (id, isExplore) => {
        let tempGroups = isExplore ? [...this.state.tempExploreGroups] : [...this.state.tempGroups];
        tempGroups.push(id);
        isExplore ? this.setState({tempExploreGroups: tempGroups}) : this.setState({tempGroups});
    }

    openGroups = (isExploreGroups) => {
        isExploreGroups ? this.setState({isGroupsOpen: !this.state.isGroupsOpen, isExploreGroups: true}) : this.setState({isGroupsOpen: !this.state.isGroupsOpen});
    }

    onRemoveGroup = (id, isExplore, isFromEdit) => {

        let tempGroups = isExplore === true ? [...this.state.tempExploreGroups] : [...this.state.tempGroups];
        let index = 0;
        let newCapsule = {...this.state.newCapsule};

        for (let i in tempGroups) {
            if (tempGroups[i] === id) {
                index = i;
            }
        }

        tempGroups.splice(parseInt(index), 1);

        if (isFromEdit && isExplore) {
            newCapsule.capsule.exploreGroups = tempGroups;
            this.setState({newCapsule, tempExploreGroups: tempGroups});
        } else if (isFromEdit) {
            newCapsule.capsule.groups = tempGroups;
            this.setState({newCapsule, tempGroups});
        } else {
            isExplore === true ? this.setState({tempExploreGroups: tempGroups}) : this.setState({tempGroups});
        }
    }

    resetGroups = () => {
        let newCapsule = {...this.state.newCapsule};
        let tempGroups = newCapsule.capsule.groups;
        let tempExploreGroups = newCapsule.capsule.exploreGroups; 

        this.setState({tempExploreGroups, tempGroups, isGroupsOpen: !this.state.isGroupsOpen, isExploreGroups: false});
    }

    onSaveGroups = () => {
        let newCapsule = {...this.state.newCapsule};
        let tempGroups = [...this.state.tempGroups];
        let tempExploreGroups = [...this.state.tempExploreGroups];

        newCapsule.capsule.exploreGroups = tempExploreGroups;
        newCapsule.capsule.groups = tempGroups;
        this.setState({newCapsule, isGroupsOpen: false, isCapsuleChanged: true, isExploreGroups: false});
    }

    setMainCapsule = () => {
        this.props.dispatch(userActions.setMainCapsule(this.props.capsuleToEdit.id));
    }

    renderEditView = () => {
        if (!this.props.isCreating) {
            return (
                <React.Fragment>
                    <ModalView isOpen={this.state.isGroupsOpen} 
                        toggle={() => this.setState({isGroupsOpen: !this.state.isGroupsOpen})}
                        onContinue={this.onSaveGroups}
                        onRemoveGroup={this.onRemoveGroup}
                        onAddGroup={this.onAddGroup}
                        onCancel={this.resetGroups}
                        groups={this.props.capsules.groups}
                        modalType="groups"
                        onCreate={this.createGroup}
                        groupsOfCapsule={this.state.tempGroups}
                        exploreGroupsOfCapsule={this.state.tempExploreGroups}
                        exploreGroups={this.props.capsules.exploreGroups}
                        isExploreGroups={this.state.isExploreGroups}
                    />

                    <CapsuleEdit
                        dispatch={this.props.dispatch}
                        newCapsuleData={this.state.newCapsule.capsule}
                        onCapsuleChange={this.onCapsuleChange}
                        backgroundUploaded={this.backgroundUploaded}
                        user={this.props.user}
                        vCardData={this.state.newCapsule.vCard}
                        thumbnail={this.getSrcUrl('profile')}
                        uploadPicture={this.uploadPicture}
                        metadata={isObjectExists(this.state, 'newCapsule.social.image.profile.metadata') ? this.state.newCapsule.social.image.profile.metadata : null}
                        isPublic={this.state.newCapsule.settings.public}
                        isSectionOpen={this.state.photoCategory}
                        toggleMenu={this.toggleMenu}
                        openGroups={this.openGroups}
                        groups={this.props.capsules.groups}
                        onRemoveGroup={this.onRemoveGroup}
                        groupsOfCapsule={this.state.newCapsule.capsule.groups}
                        exploreGroupsOfCapsule={this.state.tempExploreGroups}
                        exploreGroups={this.props.capsules.exploreGroups}
                        setIsExploreGroups={this.setIsExploreGroups}
                        getStatusOfMedia={this.props.getStatusOfMedia}
                        setCheckingProgressOf={this.props.setCheckingProgressOf}
                        checkingProgressOf={this.props.checkingProgressOf}
                        removeUserImages={this.removeUserImages}
                        listenStatus={this.props.listenStatus}
                        forceNonPublic={this.props.capsuleToEdit ? this.props.capsuleToEdit.forceNonPublic : false}
                        showEditor={this.state.showEditor}
                        isProfile={false}
                        isMain={this.props.capsules.mainCapsule && this.props.capsules.mainCapsule.id === this.props.capsuleToEdit.id}
                        setMainCapsule={this.setMainCapsule}

                    />
                    
                    <Row className="capsule-section vcard-section">
                        <Col xs="10" sm="10" md="10" lg="10">
                            <span className="navigation-button" onClick={() => this.setState({isVcardSectionOpen: !this.state.isVcardSectionOpen})}>vCard Info</span>
                        </Col>
                        <Col xs="2" sm="2" md="2" lg="2" className="right">
                            <img src={backButton} onClick={() => this.setState({isVcardSectionOpen: !this.state.isVcardSectionOpen})} className={this.state.isVcardSectionOpen ? 'hover main-section-button opened' : 'hover main-section-button'}/>
                        </Col>
                    </Row>
                    <Collapse isOpen={this.state.isVcardSectionOpen}>
                        <VCardEdit
                            vCardData={this.state.newCapsule.vCard}
                            changeView={this.changeView}
                            socialData={this.state.newCapsule.social}
                            onCapsuleChange={this.onCapsuleChange}
                            removeFromSocial={this.removeFromSocial}
                            addToSocial={this.addToSocial}
                            user={this.props.user}
                            metadata={isObjectExists(this.state, 'newCapsule.social.image.thumbnail.metadata') ? this.state.newCapsule.social.image.thumbnail.metadata : null}
                            uploadPicture={this.uploadPicture}
                            isSectionOpen={this.state.isVcardOpen}
                            toggleMenu={this.toggleMenu}
                            thumbnail={this.getSrcUrl('thumbnail')}
                            getStatusOfMedia={this.props.getStatusOfMedia}
                            setCheckingProgressOf={this.props.setCheckingProgressOf}
                            checkingProgressOf={this.props.checkingProgressOf}
                            removeUserImages={this.removeUserImages}
                            addField={this.addField}
                            removeField={this.removeField}
                        />
                        <SocialEdit
                            socialData={this.state.newCapsule.social}
                            isReorder={this.state.isReorder}
                            removeFromSocial={this.removeFromSocial}
                            addToSocial={this.addToSocial}
                            changeView={this.changeView}
                            onCapsuleChange={this.onCapsuleChange}
                            onSortEnd={this.onSortEnd}
                            toggleToolbar={this.toggleToolbar}
                            isSectionOpen={this.state.socialOpen}
                            toggleMenu={this.toggleMenu}
                        />
                    </Collapse>
                    <Row className="capsule-section">
                        <Col xs="10" sm="10" md="10" lg="10">
                            <span className="navigation-button" onClick={() => this.setState({isCapsuleSectionOpen: !this.state.isCapsuleSectionOpen})}>Capsule Media</span>
                        </Col>
                        <Col xs="2" sm="2" md="2" lg="2" className="right">
                            <img src={backButton} onClick={() => this.setState({isCapsuleSectionOpen: !this.state.isCapsuleSectionOpen})} className={this.state.isCapsuleSectionOpen ? 'hover main-section-button opened' : 'hover main-section-button'}/>
                        </Col>
                    </Row>
                    <Collapse isOpen={this.state.isCapsuleSectionOpen}>
                        <GroupEdit
                            user={this.props.user}
                            isSectionOpen={this.state.groupCategory}
                            toggleMenu={this.toggleMenu}
                            openGroups={this.openGroups}
                            groups={this.props.capsules.groups}
                            onRemoveGroup={this.onRemoveGroup}
                            groupsOfCapsule={this.state.newCapsule.capsule.groups}
                            exploreGroupsOfCapsule={this.state.tempExploreGroups}
                            exploreGroups={this.props.capsules.exploreGroups}
                            isPublic={this.state.newCapsule.settings.public}
                            onCapsuleChange={this.onCapsuleChange}

                        />
                        <MediaEdit
                            user={this.props.user}
                            capsuleToEdit={this.props.capsuleToEdit}
                            artistData={this.state.newCapsule.artist}
                            isBrowse={this.state.isBrowse}
                            addRemoveNote={this.addRemoveNote}
                            mediaData={this.state.newCapsule.media}
                            changeView={this.changeView}
                            removeFromImages={this.removeFromImages}
                            onCapsuleChange={this.onCapsuleChange}
                            dispatch={this.props.dispatch}
                            removeFromDocuments={this.removeFromDocuments}
                            removeFromVideos={this.removeFromVideos}
                            removeFromAudio={this.removeFromAudio}
                            onRemove={this.onRemove}
                            section={'bio'}
                            isSectionOpen={this.state.bio}
                            toggleMenu={this.toggleMenu}

                        />
                        <MediaEdit
                            user={this.props.user}
                            capsuleToEdit={this.props.capsuleToEdit}
                            artistData={this.state.newCapsule.artist}
                            isBrowse={this.state.isBrowse}
                            addRemoveNote={this.addRemoveNote}
                            mediaData={this.state.newCapsule.media}
                            changeView={this.changeView}
                            removeFromImages={this.removeFromImages}
                            onCapsuleChange={this.onCapsuleChange}
                            dispatch={this.props.dispatch}
                            removeFromDocuments={this.removeFromDocuments}
                            removeFromVideos={this.removeFromVideos}
                            removeFromAudio={this.removeFromAudio}
                            section={'photoVideo'}
                            getPreviewResizerParameters={this.getPreviewResizerParameters}
                            isSectionOpen={this.state.photoVideo}
                            toggleMenu={this.toggleMenu}
                            onRemove={this.onRemove}
                            incrementUploadCount={this.incrementUploadCount}
                            videoCount={this.state.videoCount}
                            photoCount={this.state.photoCount}
                            maxVideoCount={this.state.maxVideoCount}
                            maxPhotoCount={this.state.maxPhotoCount}
                            getStatusOfMedia={this.props.getStatusOfMedia}
                            setCheckingProgressOf={this.props.setCheckingProgressOf}
                            checkingProgressOf={this.props.checkingProgressOf}
                            listenStatus={this.props.listenStatus}
                            getImageScalingParameter={this.props.getImageScalingParameter}

                        />
                        <MediaEdit
                            user={this.props.user}
                            capsuleToEdit={this.props.capsuleToEdit}
                            artistData={this.state.newCapsule.artist}
                            isBrowse={this.state.isBrowse}
                            addRemoveNote={this.addRemoveNote}
                            mediaData={this.state.newCapsule.media}
                            changeView={this.changeView}
                            removeFromImages={this.removeFromImages}
                            onCapsuleChange={this.onCapsuleChange}
                            dispatch={this.props.dispatch}
                            removeFromAudio={this.removeFromAudio}
                            section={'audio'}
                            setMediaToEdit={this.setMediaToEdit}
                            isSectionOpen={this.state.audio}
                            toggleMenu={this.toggleMenu}
                            onNewNameChange={this.onNewNameChange}
                            newAudioName={this.state.newAudioName}
                            getStatusOfMedia={this.props.getStatusOfMedia}                      

                        />
                        <MediaEdit
                            user={this.props.user}
                            capsuleToEdit={this.props.capsuleToEdit}
                            artistData={this.state.newCapsule.artist}
                            isBrowse={this.state.isBrowse}
                            addRemoveNote={this.addRemoveNote}
                            mediaData={this.state.newCapsule.media}
                            changeView={this.changeView}
                            removeFromImages={this.removeFromImages}
                            onCapsuleChange={this.onCapsuleChange}
                            dispatch={this.props.dispatch}
                            removeFromDocuments={this.removeFromDocuments}
                            section={'documents'}
                            isSectionOpen={this.state.documents}
                            toggleMenu={this.toggleMenu}
                            setMediaToEdit={this.setMediaToEdit}
                            onNewNameChange={this.onNewNameChange}
                            newDocName={this.state.newDocName}
                            getStatusOfMedia={this.props.getStatusOfMedia}

                        />
                        <MediaEdit
                            user={this.props.user}
                            capsuleToEdit={this.props.capsuleToEdit}
                            artistData={this.state.newCapsule.artist}
                            isBrowse={this.state.isBrowse}
                            addRemoveNote={this.addRemoveNote}
                            mediaData={this.state.newCapsule.media}
                            changeView={this.changeView}
                            removeFromImages={this.removeFromImages}
                            onCapsuleChange={this.onCapsuleChange}
                            dispatch={this.props.dispatch}
                            removeFromDocuments={this.removeFromDocuments}
                            removeFromVideos={this.removeFromVideos}
                            removeFromAudio={this.removeFromAudio}
                            section={'notes'}
                            isSectionOpen={this.state.notes}
                            toggleMenu={this.toggleMenu}
                            onNotesChange={this.onNotesChange}

                        />
                    </Collapse>
                    {/*<Row className="capsule-section">
                        <Col xs="10" sm="10" md="10" lg="10">
                            <span className="navigation-button" onClick={() => this.setState({isConnectOpen: !this.state.isConnectOpen})}>Connect</span>
                        </Col>
                        <Col xs="2" sm="2" md="2" lg="2" className="right">
                            <img src={backButton} onClick={() => this.setState({isConnectOpen: !this.state.isConnectOpen})} className={this.state.isConnectOpen ? 'hover main-section-button opened' : 'hover main-section-button'}/>
                        </Col>
                    </Row>
                    <Collapse isOpen={this.state.isConnectOpen}>
                        <ConnectEdit
                            user={this.props.user}
                            capsuleToEdit={this.props.capsuleToEdit}
                            paymentData={this.state.newCapsule.payments}
                            onCapsuleChange={this.onCapsuleChange}
                            isSectionOpen={this.state.payments}
                            toggleMenu={this.toggleMenu}
                        />
                    </Collapse> */}
                    {this.props.isEdit &&
                    <Row>
                        <Col className="delete-button" onClick={() => this.setState({isDelete: !this.state.isDelete})}>
                            <img className="trash-icon" src={deleteIcon} />Delete capsule
                        </Col>
                    </Row>
                    }
                    <ModalView isOpen={this.state.isDelete} 
                        toggle={() => this.setState({isDelete: !this.state.isDelete})}
                        onContinue={() => this.onDelete()}
                        title="Are you sure you want to delete this capsule?"
                        modalType="delete"
                    />
                </React.Fragment>
            );
        } else {
            return (
                <Container className="profile-card " >
                    {this.state.isFirstStep ?
                        <div className="container-width m-auto">
                            <Row className="text-center create-title">
                            
                                <Col>Choose the type of Capsule you want to make, then hit continue.</Col>
                                 
                            </Row>
                            <Row ><Col className="create-title mx-auto" xs="2" lg="2" md="2" sm="2">Person</Col><Col xs="10" lg="10" md="10" sm="10"></Col></Row>
                            <Row className="mb-2">
                                <Col xs="2" lg="2" md="2" sm="2">
                                    <img className="vCard-icons" src={nameIcon} />
                                </Col>
                                <Col xs="5" lg="5" md="5" sm="5">
                                    <input 
                                        value={this.state.newCapsule.vCard.firstName} 
                                        onChange={(e) => this.onCapsuleChange(e, 'vCard')} 
                                        name="firstName" 
                                        id="firstName" 
                                        className="capsule-input mb-2"
                                        placeholder='First name'
                                    />
                                </Col>
                                <Col xs="5" lg="5" md="5" sm="5">
                                    <input 
                                        value={this.state.newCapsule.vCard.lastName} 
                                        onChange={(e) => this.onCapsuleChange(e, 'vCard')} 
                                        name="lastName" 
                                        id="lastName" 
                                        className="capsule-input last-name mb-2"
                                        placeholder='Last name'
                                    />
                                </Col>
                            </Row>
                            <Row className="create-title text-center"><Col>Or</Col></Row>
                            <Row className="create-title"><Col xs="4" lg="4" md="4" sm="4" >Anything else</Col><Col xs="8" lg="8" md="8" sm="8"></Col></Row>
                            <Row>
                                <Col xs="2" lg="2" md="2" sm="2">
                                    <img className="vCard-icons" src={otherIcon} />
                                </Col>
                                <Col xs="10" lg="10" md="10" sm="10" className="text-center">
                                    <input 
                                        value={this.state.newCapsule.name} 
                                        onChange={(e) => this.onCapsuleChange(e, 'capsule')} 
                                        name="name" 
                                        id="catName" 
                                        className="capsule-input mb-2"
                                        placeholder='(Brand, Company, Event, Place...)'
                                    />
                                </Col>
                            </Row>
                            <Row className="text-center button-padding">
                       
                                <Col className="text-center">
                                    <button type="button" 
                                        className="btn btn-primary create-button" 
                                        onClick={ this.isCreateReady() ? () => this.continueCreate(true) : null}
                                        disabled={!this.isCreateReady()}
                                    >
                            Continue
                                    </button>
                                </Col>  
                            </Row>
                        </div>
                        :
                        <React.Fragment>
                            <h5 className="text-center pt-2">Add Capsule Image and Name</h5>
                            <CapsuleEdit
                                dispatch={this.props.dispatch}
                                newCapsuleData={this.state.newCapsule.capsule}
                                onCapsuleChange={this.onCapsuleChange}
                                backgroundUploaded={this.backgroundUploaded}
                                user={this.props.user}
                                vCardData={this.state.newCapsule.vCard}
                                thumbnail={this.getSrcUrl('profile')}
                                uploadPicture={this.uploadPicture}
                                metadata={isObjectExists(this.state, 'newCapsule.social.image.profile.metadata') ? this.state.newCapsule.social.image.profile.metadata : null}
                                isPublic={this.state.newCapsule.settings.public}
                                isSectionOpen={this.state.photoCategory}
                                toggleMenu={this.toggleMenu}
                                openGroups={this.openGroups}
                                groups={this.props.capsules.groups}
                                onRemoveGroup={this.onRemoveGroup}
                                groupsOfCapsule={this.state.newCapsule.capsule.groups}
                                exploreGroupsOfCapsule={this.state.tempExploreGroups}
                                exploreGroups={this.props.capsules.exploreGroups}
                                setIsExploreGroups={this.setIsExploreGroups}
                                getStatusOfMedia={this.props.getStatusOfMedia}
                                setCheckingProgressOf={this.props.setCheckingProgressOf}
                                checkingProgressOf={this.props.checkingProgressOf}
                                removeUserImages={this.removeUserImages}
                                listenStatus={this.props.listenStatus}
                                forceNonPublic={this.props.capsuleToEdit ? this.props.capsuleToEdit.forceNonPublic : false}
                                showEditor={this.state.showEditor}
                                isCreate={true}
                                isProfile={false}
                                isMain={this.props.capsules.mainCapsule.id === this.props.capsuleToEdit.id}

                            />
                            <Row className="text-center button-padding">
                       
                                <Col className="text-center">
                                    <button type="button" 
                                        className="btn btn-primary create-button" 
                                        onClick={ this.isCreateReady() ? () => this.continueCreate() : null}
                                        disabled={!this.isCreateReady()}
                                    >
                            Continue
                                    </button>
                                </Col>  
                            </Row>
                        </React.Fragment>
                    }
                </Container>
            );
        }
    }

    continueCreate = (isFirstStep) => {
        if (isFirstStep) { 
            let newCapsule = {...this.state.newCapsule}; 
        
            if (newCapsule.capsule.name === '') {
                newCapsule.capsule.name = newCapsule.vCard.firstName.trim() + ' ' + newCapsule.vCard.lastName.trim();
            }

            if (this.props.groupId !== '') {
                newCapsule.capsule.groups.push(this.props.groupId);
            }

            this.setState({newCapsule, isFirstStep: false});
            
        } else {
            this.props.changeIsCreating(false);
        }
       
    }

    isCreateReady = () => {
        let isReady = false;

        if ((this.state.newCapsule.vCard.firstName && this.state.newCapsule.vCard.firstName !== '') ||
        (this.state.newCapsule.vCard.lastName && this.state.newCapsule.vCard.lastName !== '') ||
        (this.state.newCapsule.capsule.name && this.state.newCapsule.capsule.name !== '')) {
            isReady = true;
        }

        return isReady;
    }

    renderEdit = () => { 
        return this.renderEditView();  
    }

    renderPreview = () => {
        return <Explore
            isPreview={true} 
            isEdit={this.props.isEdit} 
            capsuleToPreview={this.state.newCapsule} 
            isCreating={this.props.isCreating}
            getThumbnailPicture={this.props.getThumbnailPicture}
            getImageFromImageAndVideos={this.props.getImageFromImageAndVideos}
            listenStatus={this.props.listenStatus}
            getStatusOfMedia={this.props.getStatusOfMedia}
            isMuted={this.props.isMuted}
            getImageScalingParameter={this.props.getImageScalingParameter}
            capsuleList={this.props.capsuleList}
        />;
    }

    render() {
        return (
            <React.Fragment>

                {this.renderHeaderOptions()}
                {
                    !this.state.isEdit ?
                        this.renderPreview()
                        :
                        this.renderEdit()
                }
                <ConfirmationView onContinue={() => this.removeFromVideos(this.state.itemToRemove)} 
                    onCancel={this.onCancel} isRemove isOpen={this.state.isRemoving} toggle={() => this.setState({isRemoving: !this.state.isRemoving})}/>
                <ConfirmationView onContinue={this.onContinue} isOpen={this.state.isConfirmation}
                    toggle={() => this.setState({isConfirmation: !this.state.isConfirmation})} confirmationType={this.state.confirmationType} user={this.props.user}/>
            </React.Fragment>
        );
    }

}

CreateCard.propTypes = {
    dispatch:                   PropTypes.func,
    changeView:                 PropTypes.func,
    user:                       PropTypes.object,
    capsuleToEdit:              PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isEdit:                     PropTypes.bool,
    onDataChange:               PropTypes.func,
    onPreviewChange:            PropTypes.func,
    changeGroupView:            PropTypes.func,
    changeStartingIndex:        PropTypes.func,
    groupId:                    PropTypes.string,
    setGroupIdToAddTo:          PropTypes.func,
    getIndexOfNewCapsule:       PropTypes.func,
    changeFooterTo:             PropTypes.func,
    deleteCapsule:              PropTypes.func,
    capsules:                   PropTypes.object,
    isCreating:                 PropTypes.bool,
    changeIsCreating:           PropTypes.func,
    changeGroupName:            PropTypes.func,
    getThumbnailPicture:        PropTypes.func,
    getStatusOfMedia:           PropTypes.func,
    getImageFromImageAndVideos: PropTypes.func,
    setCheckingProgressOf:      PropTypes.func,
    checkingProgressOf:         PropTypes.string,
    isUploading:                PropTypes.bool,
    listenStatus:               PropTypes.func,
    changeMute:                 PropTypes.func,
    isMuted:                    PropTypes.bool,
    isExploreGroup:             PropTypes.bool,
    getImageScalingParameter:   PropTypes.func,
    changeTypeOfList:           PropTypes.func,
    searchTerm:                 PropTypes.string,
    capsuleList:                PropTypes.array,
    setCapsulesToShow:          PropTypes.func
};
