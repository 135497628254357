import { isObjectExists } from '../../../utils/Helpers';
import { assemblePayload } from '../helpers/payloadAssembler';
import {userService} from './userService';

export const capsuleService = {
    getPublicCapsules,
    getCapsuleGroups,
    createCapsuleGroup,
    addCapsuleToGroup,
    getCapsuleById,
    getCapsulesByGroup,
    searchCapsulesByName,
    checkIfXframeOptions,
    importCapsuleFromMB,
    removeCapsuleFromGroup,
    updateCapsuleGroup,
    editGroupCapsules,
    deleteGroup,
    getShareToken,
    consumeShareToken,
    getSharedCapsules,
    getExploreGroups,
    getCapsulesOfExploreGroup,
    getUserCapsulesOfExploreGroup,
    setUserCapsulesForExploreGroup,
    setCapsulesToPublic,
    assembleQuery,
    removeCapsuleFromShared,
    getUserCapsules,
    preloadSingleCapsule,
    getExternalCapsule,
    importContactsFromCsv,
    getFeaturedCapsules,
    getMainCapsule,
    updateMainCapsule,
    getFavourites,
    addToFavourites,
    removeFromFavourites,
    addItemsToFavourites,
    createShareForm,
    shareBack,
    shareBackCapsule
};

function getUserTokenFromStorage() {
    var token ='';
    if (localStorage.getItem('MUUserLogin')) {
        token = JSON.parse(localStorage.getItem('MUUserLogin')).tokenObject.token;
    } else if (localStorage.getItem('MUUser')) {
        token = JSON.parse(localStorage.getItem('MUUser')).tokenObject.token;
    }

    return token;
}

async function getUserCapsules(limit, offset) {
    const requestOptions = {
        method:  'GET',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };
    let offsetValue = offset || 0;
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules?filter={"limit":' + limit  + ', "offset":'+ offsetValue + '}', requestOptions);
    return handleResponse(response, function() {getUserCapsules(limit);});
}

async function getPublicCapsules(limit, offset) {
    let response;
    let offsetValue = offset || 0;
    const requestOptions = {
        method:  'GET',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-Type':  'application/json'
        }
    };
    if (limit && offset) {
        response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/explore?groups[]=all&filter={"limit":' + '100' + ', "offset":'+ offsetValue + '}', requestOptions);
    } else {
        response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/explore?filter={"limit":' + '100' + ', "offset":'+ offsetValue + '}', requestOptions);
    }
    return handleResponse(response, function() {getPublicCapsules(limit, offset);});
}

async function updateMainCapsule(data, capsuleId, setPublic) {
    //let body = JSON.stringify(data);
    let body = assemblePayload(data);

    const requestOptions = {
        method:  'PATCH',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-type':  'application/json'
        },
        body: body
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/' + capsuleId, requestOptions);
    return handleResponse(response, function() {return updateMainCapsule(data, capsuleId, setPublic);});
}

async function shareBack(formId, email, phone, name) {
    const requestOptions = {
        method:  'POST',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-Type':  'application/json'
        },

    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/reverse-share-form/submit?form_id='+ formId + '&email=' + email + '&phone=' + phone + '&name=' + name, requestOptions);
    return handleResponse(response, function() {return shareBack(formId, email, phone, name);});
}

async function shareBackCapsule(formId, capsuleId) {
    const requestOptions = {
        method:  'POST',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-Type':  'application/json'
        },

    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/reverse-share-form/share-capsule?form_id='+ formId + '&capsule_id=' + capsuleId, requestOptions);
    return handleResponse(response, function() {return shareBack(formId, capsuleId);});
}

async function createShareForm(groupId) {
    const requestOptions = {
        method:  'POST',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-Type':  'application/json'
        },

    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/reverse-share-form/?groupId='+ groupId, requestOptions);
    return handleResponse(response, function() {return createShareForm(groupId);});
}

async function searchCapsulesByName(name, group, limit, offset, type) {
    let offsetValue = offset || 0;
    let limitValue = limit || 100;
    const requestOptions = {
        method:  'GET',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-Type':  'application/json'
        }
    };

    let response;

    //const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/searchV2?q=' + name + '&limit=50&offset=0', requestOptions);
    if (group) {
        response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/searchV2?q=' + encodeURIComponent(name) + '&groups[]=' + group + '&filter={"limit":' + limitValue + ', "offset":'+ offsetValue + '}', requestOptions);
    } else if (type && type !== 'top') {
        response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/search_top?q=' + encodeURIComponent(name) + '&type[]=' + type + '&filter={"limit":' + limit + ', "offset":'+ offsetValue + '}', requestOptions);
    } else {
        response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/search_top?q=' + encodeURIComponent(name) +'&filter={"limit":' + limit + ', "offset":'+ offsetValue + '}', requestOptions);
    }
    return handleResponse(response, function() {searchCapsulesByName(name, group, limit, type);});
}

async function getUserCapsulesOfExploreGroup(id) {
    const requestOptions = {
        method:  'GET',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-Type':  'application/json'
        }
    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/explore-groups/' + id + '/capsules', requestOptions);
    return handleResponse(response);
}

async function removeCapsuleFromShared(capsuleId) {
    const requestOptions = {
        method:  'DELETE',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-Type':  'application/json'
        }
    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/' + capsuleId + '/shared_with_me', requestOptions);
    return handleResponse(response);
}

async function setUserCapsulesForExploreGroup(id, data) {

    const requestOptions = {
        method:  'PUT',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-Type':  'application/json'
        },
        body: JSON.stringify(data)
    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/explore-groups/' + id + '/capsules', requestOptions);
    return handleResponse(response);
}

async function setCapsulesToPublic(data) {

    const requestOptions = {
        method:  'PATCH',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-Type':  'application/json'
        },
        body: JSON.stringify(data)
    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/public', requestOptions);
    return handleResponse(response);
}

async function getCapsulesOfExploreGroup(groups, limit, offset) {
    const requestOptions = {
        method:  'GET',
        headers: {
            'Content-Type': 'application/json'
        }

    };

    let limitValue = limit || 100;
    let offsetValue = offset || 0;

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/explore?groups[]=' + groups + '&filter={"limit":' + limitValue + ', "offset":'+ offsetValue + '}', requestOptions);
    return handleResponse(response, function() {getCapsulesOfExploreGroup(groups);});
}

function assembleQuery(data, paramName) {
    let query = '';
    for (let i in data) {
        query += paramName + '[]=' + data[i];
        if (i !== data.length && data.length !== 1) {
            query += '&';
        }

    }
    if (data.length === 0) query = paramName + '[]=""';

    return query;
}

async function getExploreGroups() {

    const requestOptions = {
        method:  'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/explore-groups?child=true&count=true', requestOptions);
    return handleResponse(response, getExploreGroups);
}

async function updateCapsuleGroup(id, data) {
    const requestOptions = {
        method:  'PATCH',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-type':  'application/json'
        },
        body: JSON.stringify(data)
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsule-groups/' + id, requestOptions);
    return handleResponse(response, function() {return updateCapsuleGroup(id, data);});
}

async function editGroupCapsules(id, data) {
    const requestOptions = {
        method:  'PATCH',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-type':  'application/json'
        },
        body: JSON.stringify(data)
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsule-groups/' + id + '/capsules', requestOptions);
    return handleResponse(response, function() {return updateCapsuleGroup(id, data);});
}

async function getCapsuleGroups() {

    const requestOptions = {
        method:  'GET',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }

    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsule-groups', requestOptions);
    return handleResponse(response, getCapsuleGroups);
}

async function getCapsulesByGroup(groupId) {
    const requestOptions = {
        method:  'GET',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-type':  'application/json'
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsule-groups/' + groupId + '/capsules', requestOptions);
    return handleResponse(response, function() {return getCapsulesByGroup(groupId);});
}

async function deleteGroup(groupId) {
    const requestOptions = {
        method:  'DELETE',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-type':  'application/json'
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsule-groups/' + groupId, requestOptions);
    return handleResponse(response, function() {return deleteGroup(groupId);});
}

async function createCapsuleGroup(groupName, capsuleId) {
    var data = {
        'name':       groupName,
        'capsuleIds': capsuleId
    };
    const requestOptions = {
        method:  'POST',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        },
        body: JSON.stringify(data)

    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsule-groups', requestOptions);
    return handleResponse(response, function() {return createCapsuleGroup(groupName, capsuleId);});
}

async function addCapsuleToGroup(groupId, capsuleId) {

    const requestOptions = {
        method:  'PUT',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }

    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsule-groups/' + groupId + '/capsules/' + capsuleId, requestOptions);
    return handleResponse(response, function() {return addCapsuleToGroup(groupId, capsuleId);});
}

async function removeCapsuleFromGroup(groupId, capsuleId) {

    const requestOptions = {
        method:  'DELETE',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }

    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsule-groups/' + groupId + '/capsules/' + capsuleId, requestOptions);
    return handleResponse(response, function() {return addCapsuleToGroup(groupId, capsuleId);});
}

async function getCapsuleById(capsuleId) {

    const requestOptions = {
        method:  'GET',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/' + capsuleId, requestOptions);
    return handleResponse(response);
}

async function getExternalCapsule(type, id) {
    const requestOptions = {
        method: 'GET',
    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/external/' + type + '/' + id, requestOptions);
    return handleResponse(response);
}

async function checkIfXframeOptions(url) {

    const requestOptions = {
        method:  'GET',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/www-util/can-show-in-iframe?url=' + url, requestOptions);
    return handleResponse(response, function() {return checkIfXframeOptions(url);});
}

async function importCapsuleFromMB(id) {

    const requestOptions = {
        method: 'GET',
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/brainz/' + id, requestOptions);
    return handleResponse(response);
}

async function getShareToken(id) {

    const requestOptions = {
        method:  'GET',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/' + id +'/share_token', requestOptions);
    return handleResponse(response, function() {return getShareToken(id);});
}

async function getMainCapsule(token) {

    const requestOptions = {
        method:  'GET',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/main', requestOptions);
    return handleResponse(response, function() {return getMainCapsule(token);});
}

async function consumeShareToken(token) {

    const requestOptions = {
        method:  'GET',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/share/consume_share_token?share_token=' + token, requestOptions);
    return handleResponse(response, function() {return consumeShareToken(token);});
}

async function preloadSingleCapsule(id) {

    const requestOptions = {
        method: 'GET'

    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/preloadSingle/' + id, requestOptions);
    return handleResponse(response, function() {return preloadSingleCapsule(id);});
}

async function getFeaturedCapsules() {

    const requestOptions = {
        method: 'GET'

    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/featured', requestOptions);
    return handleResponse(response, function() {return getFeaturedCapsules();});
}

async function getFavourites() {

    const requestOptions = {
        method:  'GET',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }

    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/favorite', requestOptions);
    return handleResponse(response, function() {return getFavourites();});
}

async function addToFavourites(id) {

    const requestOptions = {
        method:  'POST',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }

    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/favorite/' + id, requestOptions);
    return handleResponse(response, function() {return addToFavourites(id);});
}

async function addItemsToFavourites(items) {

    const requestOptions = {
        method:  'POST',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        },
        body: JSON.stringify(items)
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/favorite/', requestOptions);
    return handleResponse(response, function() {return addItemsToFavourites(items);});
}

async function removeFromFavourites(id) {

    const requestOptions = {
        method:  'DELETE',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/favorite/' + id, requestOptions);
    return handleResponse(response, function() {return removeFromFavourites(id);});
}

async function getSharedCapsules(limit, offset) {
    let offsetValue = offset || 0;
    const requestOptions = {
        method:  'GET',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/shared?filter={"limit":' + limit + ', "offset":'+ offsetValue + '}', requestOptions);
    return handleResponse(response, function() {return getSharedCapsules(limit, offset);});
}

async function importContactsFromCsv(data, groupName) {
    let capsules = [];
    let existingCapsules = [];
    let capsule;
    let phoneNumber = '';
    let userCapsulesEmail = [];

    let userCapsules = await getUserCapsules(0);

    for (let i in userCapsules) {
        if (isObjectExists(userCapsules[i], 'items.contact.emailAddresses') && userCapsules[i].items.contact.emailAddresses.length > 0) {
            userCapsulesEmail.push(userCapsules[i].items.contact.emailAddresses[0].value);
            userCapsulesEmail.push(userCapsules[i].id);
        }

    }

    for (let i in data) {
        if (userCapsulesEmail.indexOf(data[i].email) === -1) {
            if (data[i].phone) {
                phoneNumber = String(data[i].phone);
            } else if (data[i].phone_number) {
                phoneNumber = String(data[i].phone_number);
            }
            capsule = {
                name:          data[i].first_name || data[i].email,
                category:      '',
                exploreGroups: [],
                public:        false,
                groups:        [],

                items: {
                    contact: {
                        name: {
                            givenName:  data[i].first_name ? data[i].first_name.trim() : data[i].email,
                            familyName: data[i].last_name ? data[i].last_name.trim() : data[i].email,
                        },
                        date: {
                            birthday: '',
                            dates:    []
                        },
                        organisation: {
                            organizationName: data[i].company || '',
                        },
                        phoneNumbers:    [{label: 'home', value: phoneNumber}],
                        emailAddresses:  [{label: 'home', value: data[i].email}],
                        notes:           [],
                        postalAddresses: [],
                        socialProfiles:  [],
                        urlAddresses:    []
                    },

                    artist: {
                        bioOverview: ''
                    },
                    documents: {documents: []}

                }};
            capsules.push(capsule);
        } else {
            existingCapsules.push(userCapsulesEmail[userCapsulesEmail.indexOf(data[i].email) + 1]);
        }
    }

    let requestOptions, response, handled;
    let ids = existingCapsules.concat(capsules);

    if (capsules.length > 0) {
        for (let i in capsules) {
            requestOptions = {
                method:  'POST',
                headers: {
                    'Authorization': 'Bearer ' + getUserTokenFromStorage(),
                    'Content-type':  'application/json'
                },
                body: JSON.stringify(capsules[i])
            };
            response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules', requestOptions);

            handled = await handleResponse(response, function() {return importContactsFromCsv(data);});

            ids.push(await handled.id);
        }
    }

    if (ids.length > 0) {
        let groups = await getCapsuleGroups();

        let groupIdToAdd = false;
        for (let i in groups) {
            if (groups[i].name === groupName) {
                groupIdToAdd = groups[i].id;
            }
        }

        if (!groupIdToAdd) {
            let newGroup = await createCapsuleGroup(groupName);
            groupIdToAdd = newGroup.id;
        }

        await editGroupCapsules(groupIdToAdd, {add: ids});
    }
    return true;

}

function handleResponse(response, callback) {
    if (!response.ok) {
        return response.json().then(text => {
            var data;
            try {
                data = JSON.parse(text);
            } catch (e) {
                data = text;
            }
            const error = {
                status:     response.status,
                statusText: response.statusText,
                messages:   data
            };
            if (callback && (error.status == 401 ) && (error.statusText === 'Unauthorized')) {
                return userService.refreshToken(callback);

                //localStorage.removeItem('MUUser');
            }

            return Promise.reject(error);
        });
    } else {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
            return response.json().then(data => {
                return data;
            });
        } else {
            return response.text().then(data => {
                return data;
            });
        }

    }
}
