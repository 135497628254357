import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import cameraIcon from '../../assets/images/CardPhoto@2x.png';
import {ModalView} from './ModalView';
import { getUrlAndLicence} from '../../utils/VieroUtils';
import { isObjectExists } from '../../utils/Helpers';
import VideoPlayer  from '../video/VideoPlayer';
import { PhotoEditorSDK } from '../../utils/PhotoEditorSDK';
//import { userActions } from '../redux/actions';

export class CapsuleEdit extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            name:                    '',
            category:                '',
            profilePicture:          '',
            capsulePictureToPreview: '',
            capsulePicture:          '',
            isEdit:                  true,
            isPhoto:                 true,
            isSectionOpen:           true,
            showNoPublicModal:       false,
            imageToUpload:           {},
            showEditor:              false
        }; 

        this.inputCapsulePicRef = React.createRef();
        this.thumbnailParam = '100x100';
        this.uploadingText = 'Uploading ';
        this.processingText = 'Processing ';
        this.removeUrlText = 'Remove URL';
        this.removeImageText = 'Remove Image';
    }

    componentDidUpdate() {

        if (this.props.thumbnail.type === 'url' && this.state.isPhoto === true) {
            this.setState({isPhoto: false});
        }

        if (this.props.thumbnail.type === 'file' && this.state.isPhoto === false) {
            this.setState({isPhoto: true});
        }

        /* if (this.state.imageToUpload !== '' && prevState.imageToUpload === '') {
            this.setState({showEditor: true});
        }*/
    }

    onChange = (e) => {
        this.props.onCapsuleChange(e, 'capsule');
    }

    onUrlChange = (value) => {
        let url = value;
        if (url === '' || url === 'https://') {
            url = '';
            this.setState({isPhoto: true});
            this.props.removeUserImages('profile');
        } else {
            this.props.onCapsuleChange(url, 'profileUrl');
        }
        
        this.openModal();
    }

    openCapsulePicDialog = () => {
        this.openModal();
        this.inputCapsulePicRef.current.click();
    }

    openModal = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    toggleMenu = (menu) => {
        this.setState({[menu]: !this.state[menu]});
    }

    onUploadImage = (e) => {
        let fileType = e.target.files[0].type;
        if (fileType.indexOf('video') === -1 && fileType.indexOf('heic') === -1 && fileType.indexOf('heif') === -1) {
            let fileAsImage = this.getFileAsImage(e);
            let imageToUpload = {image: fileAsImage, evt: e};
            this.setState({imageToUpload, showEditor: true});
        } else {
            this.props.uploadPicture(e, 'profile');
        }
       
    }

    getFileAsImage = (evt) => {
        var tgt = evt.target,
            files = tgt.files;
        let image = new Image();
        // FileReader support
        if (FileReader && files && files.length) {
            var fr = new FileReader();
          
            fr.onload = function () {
                image.src = fr.result;
            };
            fr.readAsDataURL(files[0]);
            return image;
        }
           
        // Not supported
        else {
        // fallback -- perhaps submit the input to an iframe and temporarily store
        // them on the server until the user's session ends.
        }
    }

    removeProfile = () => {
        if (!this.state.isPhoto) {
            this.setState({isPhoto: true});
        }
        this.props.removeUserImages('profile');
    }

    getVideoObject = (metadata, url, preview) => {
        let src, keySystems, isViero, previewUrl;
        if (metadata.storageType === 'viero') {
            let vieroObject = getUrlAndLicence(metadata.storageUUID, false, '', 'VieroToolkitUtilsTypeMotion');
            previewUrl = vieroObject ? getUrlAndLicence(metadata.storageUUID, true, '', 'VieroToolkitUtilsTypeMotion').url : '';
            src = vieroObject.url;
            keySystems = vieroObject.keySystems;
            isViero = true;
        } else {
            src = url;
        }
        return isViero && preview ? 
            <img className="media-images hover" src={previewUrl} />
            :
            <VideoPlayer url={src} isViero={isViero} keySystems={keySystems} videoClass="profile-video hover" type={isViero ? null : metadata.mimeType} ref={(ref) => this.videoPlayer = ref}/>;
    }

    getVieroUrl = (metadata) => {
        let url;
        if (metadata.storageType === 'viero') {
            url = getUrlAndLicence(metadata.storageUUID, false, this.thumbnailParam, 'VieroToolkitUtilsTypeStillSingle').url;
        }

        return url;
    }

    getPreview = (isPoster) => {
        let preview;

        let status = this.uploadingText;

        if ((isObjectExists(this.props, 'metadata.storageType') && this.props.metadata.storageType === 'viero' && this.props.getStatusOfMedia(this.props.metadata.storageUUID, 'capsuleMedia') === false)
        || this.props.metadata && this.props.user.gettingFireBase === true && this.props.user.fireBaseStatus && this.props.user.fireBaseStatus[this.props.metadata.storageUUID] !== 'ready' && this.props.user.fireBaseStatus[this.props.metadata.storageUUID] !== undefined) {
            status = this.props.user.fireBaseStatus[this.props.metadata.storageUUID] === 'processing' ? this.processingText : this.uploadingText;
            preview = (<div>{status}media...</div>);
        } else if (this.props.metadata && this.props.metadata.mimeType && -1 < this.props.metadata.mimeType.indexOf('video/')) {

            preview = (
                isPoster ?
                    <div onClick={() => this.openModal()}>
                        {this.getVideoObject(this.props.metadata, this.props.thumbnail.url)}
                    </div>
                    :
                    <div onClick={() => this.openModal()}>
                        {this.getVideoObject(this.props.metadata, this.props.thumbnail.url, true)}
                    </div>
            );
        } else {
            preview = (
                <img id="capsule-image" className={this.props.thumbnail !== '' && this.state.isPhoto ? this.props.isProfile ? 'hover profile-img img-fluid' : 'hover radius img-fluid' : 'hover capsule-photo'} onClick={() => this.openModal()} src={
                    this.props.thumbnail !== '' && this.state.isPhoto ?
                        this.props.metadata && this.props.metadata.storageType === 'viero' ? 
                            this.getVieroUrl(this.props.metadata)
                            :
                            this.props.thumbnail.url
                        :
                        cameraIcon} />
            );
        }

        return preview;
    }

    getDefaultUrl = () => {
        return (this.props.thumbnail && (this.props.thumbnail.type === 'url')) ?  this.props.thumbnail.url : 'https://';
    }

    setEditor = (value) => {
        this.setState({showEditor: value});
    }

    render() {

        return (
               
            <Container className="mt-10" >
                <ModalView isOpen={this.state.isOpen}
                    toggle={() => this.openModal()}
                    onUpload={() =>this.openCapsulePicDialog()}
                    onContinue={this.onUrlChange}
                    defaultValue={this.getDefaultUrl()}
                    title="Capsule Background"
                    modalType="media"
                />

                { this.state.showEditor &&
                            <Row><PhotoEditorSDK 
                                imageUrl={this.state.imageToUpload.image} type={'profile'}
                                uploadImage={this.props.uploadPicture}
                                setEditor={() => this.setEditor()}
                            ></PhotoEditorSDK></Row>
                }
                {!this.props.isProfile &&
                <Row className="text-center">
                    <Col className="pb-3">
                        {this.props.isMain || this.props.user.mainSet ?
                            <div>This is your main capsule</div> : 
                            <div onClick={() => this.props.setMainCapsule()} className="hover">Click to make this capsule your main capsule</div>
                        }
                    </Col>
                </Row>
                }
                <Row className="text-center">
                    <Col className="m-auto">
                        <div className="text-center">
                            {this.getPreview()}
                            <input ref={this.inputCapsulePicRef} accept=".jpg, .jpeg, .png, .mov, .mp4, .avi, .heic"  onChange={(e) => this.onUploadImage(e)} type="file" style={{display: 'none'}}/>
                            
                            {this.props.thumbnail && !this.props.isProfile && <div onClick={() => this.removeProfile()} className="navigation-button mb-10">
                                {this.state.isPhoto ? this.removeImageText : this.removeUrlText}
                            </div>}
                            {this.props.thumbnail && this.props.isProfile && <div onClick={() => this.openModal()} className="navigation-button mb-10">
                                Change profile picture
                            </div>}
                        </div>
                    </Col>
                </Row>
                    
                <Row className="input-row">
                    <Col lg="2" md="2" sm="2"></Col>
                    <Col lg="5" md="5" sm="5" className="m-auto">
                        <input
                            value={this.props.newCapsuleData.name}
                            onChange={(e) => this.onChange(e)}
                            name="name"
                            id={this.props.isCreate ? 'capName' : 'name'}
                            className="capsule-input"
                            placeholder='Add Capsule Name'
                        />
                    </Col>
                    <Col lg="2" md="2" sm="2"></Col>
                </Row>
                {!this.state.isPhoto &&
                     <Row className="input-row">
                         <Col lg="2" md="2" sm="2"></Col>
                         <Col lg="8" md="8" sm="8">
                             <span>
                                 <input
                                     value={this.props.thumbnail.url}
                                     name="category"
                                     id="category"
                                     readOnly
                                     className="capsule-input"

                                 />
                             </span>
                         </Col>
                         <Col lg="2" md="2" sm="2"></Col>
                     </Row>
                }

            </Container>
        );
    }
}

CapsuleEdit.propTypes = {
    dispatch:              PropTypes.func,
    user:                  PropTypes.object,
    onCapsuleChange:       PropTypes.func,
    newCapsuleData:        PropTypes.object,
    thumbnail:             PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    uploadPicture:         PropTypes.func,
    metadata:              PropTypes.object,
    toggleMenu:            PropTypes.func,
    isSectionOpen:         PropTypes.bool,
    getStatusOfMedia:      PropTypes.func,
    setCheckingProgressOf: PropTypes.func,
    checkingProgressOf:    PropTypes.string,
    removeUserImages:      PropTypes.func,
    isCreate:              PropTypes.bool,
    showEditor:            PropTypes.bool,
    isProfile:             PropTypes.bool,
    isMain:                PropTypes.bool,
    setMainCapsule:        PropTypes.func
};
