/* eslint-disable no-console */
var basic = require('basic-authorization-header');
import { uploadFiles, getStatusOfUpload} from '../../../utils/VieroUtils';

import { assemblePayload } from '../helpers/payloadAssembler';
import { userActions } from '../actions';
import { store } from '../helpers/store';

export const userService = {
    spawnAnonymousUser,
    loginWithKey,
    getCapsules,
    createCapsule,
    updateCapsule,
    uploadFile,
    getFiles,
    deleteFile,
    addImageOrVideoToCapsule,
    updateImageAndVideo,
    deleteCapsule,
    signUp,
    login,
    verifyEmail,
    getMe,
    logout,
    refreshToken,
    generateVideoPreview,
    resetPassword,
    setPassword,
    configFilter,
    getExploreCount,
    getFile,
    getFirebaseStatus,
    getUserCapsuleCount,
    verifyPin,
    updateEmail,
    updatePhoneNumber,
    getMostRecentCount,
    connectToSendGrid,
    getPublicUser,
    setMainCapsule,
    syncGoogleContacts,
    getGoogleAuthLink,
    getGroupShareToken,
    getSharedGroups
};

function getUserTokenFromStorage() {
    var token ='';
    if (localStorage.getItem('MUUserLogin')) {
        token = JSON.parse(localStorage.getItem('MUUserLogin')).tokenObject.token;
    } else if (localStorage.getItem('MUUser')) {
        token = JSON.parse(localStorage.getItem('MUUser')).tokenObject.token;
    }

    return token;
}

async function signUp(token, email, password, name, title, phone) {

    var header = {
        'Content-Type': 'application/json',
    };

    var body = phone ? JSON.stringify({phone: phone, password: password, name: name, title: title}) : JSON.stringify({email: email, password: password, name: name, title: title});

    if (token) {
        header = {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        };
    }
    const requestOptions = {
        method:  'POST',
        headers: header,
        body:    body
    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/auth/signup', requestOptions);
    var user = await handleResponse(response);
    return user;
}

async function spawnAnonymousUser(deviceName) {
    const requestOptions = {
        method: 'GET',
    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/auth/deviceAuth?device_id=' + deviceName, requestOptions);
    var user = await handleResponse(response);
    if (user.success) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('MUUser', JSON.stringify(user));
    }

    return user;
}

async function getPublicUser(slug) {
    const requestOptions = {
        method: 'GET',
        /* headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        },*/
    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/user/slug?slug=' + slug, requestOptions);

    //const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/dev/user/public?slug=testapp.test&user_id=69918610-e372-4110-ac93-9f045ca9ed6a', requestOptions);

    var user = await handleResponse(response);

    return user;
}

async function verifyPin(uuid, pin) {
    const requestOptions = {
        method:  'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ pin: parseInt(pin), uuid: uuid })
    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/auth/verifyPin', requestOptions);
    var user = await handleResponse(response);
    return user;
}

async function getSharedGroups(id) {
    const requestOptions = {
        method:  'GET',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsule-groups/shared', requestOptions);
    return handleResponse(response, function() {return getSharedGroups(id);});
}

async function getGroupShareToken(id) {
    const requestOptions = {
        method:  'GET',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsule-groups/' + id +'/share_token', requestOptions);
    return handleResponse(response, function() {return getGroupShareToken(id);});
}

async function connectToSendGrid(id, key, listId, group) {
    let groupParam = group === '' ? '' : '&groupId=' + group;
    const requestOptions = {
        method:  'POST',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        },
    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/third-party/sendgrid?id=' + id + '&key=' + key + '&listId=' + listId + groupParam, requestOptions);
    var user = await handleResponse(response);
    return user;
}

async function login(email, password) {
    const requestOptions = {
        method:  'POST',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': basic(email, password)
        },
        body: JSON.stringify({ username: email, password: password })
    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/auth/login', requestOptions);
    var user = await handleResponse(response);
    if (user.success) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('MUUserLogin', JSON.stringify(user));
        localStorage.removeItem('MUUser');
        localStorage.removeItem('fanbotWebChatClientIds');
    }
    return user;
}

async function getMe() {

    const requestOptions = {
        method:  'GET',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/auth/me', requestOptions);

    var user = await handleResponse(response, getMe);
    if (user.success) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('MUUserLogin', JSON.stringify(user));
    }
    if (user.config.explore_category) {
        user.config.explore_category = user.config.explore_category.sort((a, b) => (a.order > b.order) ? 1 : -1);    //laurie
    }
    if (user.config.my_category) {
        user.config.my_category = user.config.my_category.sort((a, b) => (a.order > b.order) ? 1 : -1);    //laurie
    }
    return user;
}

async function refreshToken(callback) {
    const requestOptions = {
        method: 'GET',
    };

    let rToken = '';

    let anonymousObject = JSON.parse(localStorage.getItem('MUUser'));
    let userObject = JSON.parse(localStorage.getItem('MUUserLogin'));

    if (localStorage.getItem('MUUserLogin')) {
        rToken = JSON.parse(localStorage.getItem('MUUserLogin')).tokenObject.refreshToken;
    } else if (localStorage.getItem('MUUser')) {
        rToken = JSON.parse(localStorage.getItem('MUUser')).tokenObject.refreshToken;
    }

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/auth/refresh?refresh_token=' + rToken, requestOptions);
    var user = await handleResponse(response, 'refresh');
    if (user.success) {
        if (localStorage.getItem('MUUserLogin')) {
            userObject.token = user.token;
            userObject.tokenObject = user.tokenObject;
            localStorage.setItem('MUUserLogin', JSON.stringify(userObject));
        } else if (localStorage.getItem('MUUser')) {
            anonymousObject.token = user.token;
            anonymousObject.tokenObject = user.tokenObject;
            localStorage.setItem('MUUser', JSON.stringify(anonymousObject));
        }

    } else {
        if (localStorage.getItem('MUUserLogin')) {
            localStorage.removeItem('MUUserLogin');
        } else if (localStorage.getItem('MUUser')) {
            localStorage.removeItem('MUUser');
        }
    }
    return callback();
}

async function setMainCapsule(id) {
    const requestOptions = {
        method:  'PATCH',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-type':  'application/json'
        },
    }
    ;
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/main/' + id, requestOptions);
    var res = await handleResponse(response, function() {return setMainCapsule(id);});

    return res;
}

async function loginWithKey() {
    const requestOptions = {
        method:  'POST',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': basic(JSON.parse(localStorage.getItem('MUUser')).uuid, JSON.parse(localStorage.getItem('MUUser')).loginKey)
        },
        body: JSON.stringify({ username: JSON.parse(localStorage.getItem('MUUser')).uuid, password: JSON.parse(localStorage.getItem('MUUser')).loginKey})
    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/auth/login', requestOptions);
    var user = await handleResponse(response, loginWithKey);

    return user;
}

async function verifyEmail(verificationToken) {
    const requestOptions = {
        method:  'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/auth/verifyCode/' + verificationToken, requestOptions);
    return handleResponse(response);
}

async function getCapsules() {
    //let limit = afterCreate ? '200' : '100';
    const requestOptions = {
        method:  'GET',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules', requestOptions);
    return handleResponse(response, getCapsules);
}

async function syncGoogleContacts(code) {

    const requestOptions = {
        method:  'POST',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI +
    '/third-party/google?code=' + encodeURIComponent(code) + '&redirect=' + encodeURIComponent(process.env.REACT_APP_BASE_URI), requestOptions);
    return handleResponse(response, function() {return syncGoogleContacts(code);});
}

async function getGoogleAuthLink() {

    const requestOptions = {
        method:  'GET',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI +
    '/third-party/google/authUrl?&redirect=' + encodeURIComponent(process.env.REACT_APP_BASE_URI), requestOptions);
    return handleResponse(response, function() {return getGoogleAuthLink();});
}

async function getFirebaseStatus(id) {

    const response = await getStatusOfUpload(id);
    return response;
}

async function createCapsule(data) {
    const requestOptions = {
        method:  'POST',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-type':  'application/json'
        },
        body: assemblePayload(data)
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules', requestOptions);
    return handleResponse(response, function() {return createCapsule(data);});
}

async function updateCapsule(data, capsuleId, setPublic, skipAssembly) {
    let body;
    if (skipAssembly) {
        body = JSON.stringify(data);
    } else {
        body = setPublic ? JSON.stringify({public: true}) : assemblePayload(data);
    }

    const requestOptions = {
        method:  'PATCH',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-type':  'application/json'
        },
        body: body
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/' + capsuleId, requestOptions);
    return handleResponse(response, function() {return updateCapsule(data, capsuleId, setPublic);});
}

async function uploadFile(file, token, isViero) {
    let response;

    if (isViero) {
        response = await uploadFiles(file);
    } else {
        var formData = new FormData();
        formData.append(encodeURIComponent('file'), file, file.name);
        const requestOptions = {
            method:  'POST',
            headers: {
                'Authorization': 'Bearer ' + getUserTokenFromStorage()
            },
            body: formData
        };
        response = await fetch(process.env.REACT_APP_API_BASE_URI + '/files', requestOptions);
    }

    return handleResponse(response, function() {return uploadFile(file);});
}

async function getFiles() {
    const requestOptions = {
        method:  'GET',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/files', requestOptions);
    return handleResponse(response, getFiles);
}

async function getFile(id) {
    const requestOptions = {
        method:  'GET',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/files/' + id, requestOptions);
    return handleResponse(response, getFile);
}

async function getExploreCount() {
    const requestOptions = {
        method:  'GET',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/explore-groups/count', requestOptions);
    return handleResponse(response, getExploreCount);
}

async function getMostRecentCount() {

    const requestOptions = {
        method:  'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/most_recent/count', requestOptions);
    return handleResponse(response, getMostRecentCount);
}

async function getUserCapsuleCount() {
    const requestOptions = {
        method:  'GET',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/count', requestOptions);
    return handleResponse(response, getUserCapsuleCount);
}

async function deleteFile(id) {
    const requestOptions = {
        method:  'DELETE',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/files/' + id, requestOptions);
    return handleResponse(response, function() {return deleteFile(id);});
}

async function addImageOrVideoToCapsule(capsuleId, fileId) {
    let body;
    if (-1 !== fileId[0].mimeType.indexOf('image')) {
        body = {
            images: [
                {
                    fileId: fileId[0].id
                }
            ]
        };
    } else {
        body = {
            videos: [
                { fileId: fileId[0].id }
            ]
        };
    }
    const requestOptions = {
        method:  'POST',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-type':  'application/json'
        },
        body: JSON.stringify(body)
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/' + capsuleId + '/items/image_and_video', requestOptions);
    return handleResponse(response);
}

async function updateImageAndVideo(userToken, capsuleId, data) {
    const requestOptions = {
        method:  'POST',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
            'Content-type':  'application/json'
        },
        body: data
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/' + capsuleId + '/items/image_and_video', requestOptions);
    return handleResponse(response);
}

async function deleteCapsule(capsuleId) {
    const requestOptions = {
        method:  'DELETE',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/' + capsuleId, requestOptions);
    return handleResponse(response, function() {return deleteCapsule(capsuleId);});

}

async function generateVideoPreview(id) {
    const requestOptions = {
        method:  'POST',
        headers: {
            'Authorization': 'Bearer ' + getUserTokenFromStorage(),
        }
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/files/' + id + '/preview', requestOptions);
    return handleResponse(response);
}

async function resetPassword(email, isPhone) {
    let emailObject = isPhone ? JSON.stringify({phone: email}) : JSON.stringify({email: email});

    const requestOptions = {
        method:  'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: emailObject
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/auth/reset-password/', requestOptions);
    return handleResponse(response);
}

async function setPassword(newPassword, token) {
    let body = JSON.stringify({password: newPassword});
    let userToken = token ? token : getUserTokenFromStorage();
    const requestOptions = {
        method:  'POST',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + userToken
        },
        body: body
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/auth/set-password/', requestOptions);
    return handleResponse(response);
}

async function configFilter(groups, isExplore) {
    let body;

    body = JSON.stringify(groups);

    const requestOptions = {
        method:  'POST',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        },
        body: body
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/user/config', requestOptions);
    return handleResponse(response, function() {return configFilter(groups, isExplore);});
}

async function updateEmail(email) {
    let body = JSON.stringify({email: email});

    const requestOptions = {
        method:  'PUT',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        },
        body: body
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/auth/updateEmail/', requestOptions);
    return handleResponse(response, function() {return updateEmail(email);});
}

async function updatePhoneNumber(phoneNumber) {
    let body = JSON.stringify({phone: phoneNumber});

    const requestOptions = {
        method:  'PUT',
        headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + getUserTokenFromStorage()
        },
        body: body
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/auth/updatePhone/', requestOptions);
    return handleResponse(response, function() {return updatePhoneNumber(phoneNumber);});
}

async function logout() {
    var response = true;
    try {
        if (localStorage.getItem('fanbotWebChatClientIds')) {
            let newObj = JSON.parse(localStorage.getItem('fanbotsByUser')) ? JSON.parse(localStorage.getItem('fanbotsByUser')) : {};
            newObj[localStorage.getItem('userId')] = JSON.parse(localStorage.getItem('fanbotWebChatClientIds'));
            localStorage.setItem('fanbotsByUser', JSON.stringify(newObj));
        }
        localStorage.removeItem('MUUser');
        localStorage.removeItem('MUUserLogin');
        localStorage.removeItem('userId');
        localStorage.removeItem('fanbotWebChatClientIds');

    } catch (e) {
        response = false;
    }

    return response;
}

function isTokenRelated(message) {
    let isRelated = true;
    if (-1 < message.indexOf('The new email address is the same as the current address') || -1 < message.indexOf('The new phone number is the same as the current one')) {
        isRelated = false;
    }

    return isRelated;
}

async function handleResponse(response, callback) {
    if (!response.ok) {
        return response.json().then(text => {
            var data;
            try {
                data = JSON.parse(text);
            } catch (e) {
                data = text;
            }
            const error = {
                status:     response.status,
                statusText: response.statusText,
                messages:   data
            };
            // eslint-disable-next-line no-console
            console.log(error.messages.error.message);
            if (callback === 'refresh' && (error.status == 401) && (error.statusText === 'Unauthorized') &&
            (-1 < error.messages.error.message.indexOf('Token with ID') || -1 < error.messages.error.message.indexOf('User with ID'))) {
                store.dispatch(userActions.logout());
            } else if (callback && (error.status == 401) && (error.statusText === 'Unauthorized') && isTokenRelated(error.messages.error.message)) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                return refreshToken(callback);
                //localStorage.removeItem('MUUser');
            }

            return Promise.reject(error);
        });
    } else {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
            return response.json().then(data => {
                return data;
            });
        } else {
            return response.text().then(data => {
                return data;
            });
        }

    }
}
